import React, { useContext } from 'react'
import 'react-tabs/style/react-tabs.css'
import './dashboard.scss'
import statsIcon from '../../assets/images/stats_tab_icon.svg'
import membersIcon from '../../assets/images/members_tab_icon.svg'
import containersIcon from '../../assets/images/containers_tab_icon.svg'
import itemsIcon from '../../assets/images/items_tab_icon.svg'
import organisationSettingsIcon from '../../assets/images/organisation_settings_tab_icon.svg'
import profileIcon from '../../assets/images/user_profile_icon.svg'
import dropdownIcon from '../../assets/images/dropdown_arrow_icon.svg'
import { useState } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import emoryLogo from '../../assets/images/emory_logo.svg'
import Loader from '../../components/Loader/loader'
import { UserDetailsContext } from '../../contexts/userDetailsContext'
import { useEffect } from 'react'
import UserAccount from '../userAccount/userAccount'
import { UserPermissionsContext } from '../../contexts/userPermissionsContext'
import { useTranslation } from 'react-i18next'

const Dashboard = (props: any) => {
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showAccountPopup, setShowAccountPopup] = useState<boolean>(false)
  const { isLoading, isSuccess, isError, data: userDetails, error, refetch } = useContext(UserDetailsContext)
  const { ...userPermissionsData } = useContext(UserPermissionsContext)
  const userPermissions = userPermissionsData?.data

  const onProfileOptionsClick = () => {}

  const onLogoutClick = () => {
    localStorage.clear()
    navigate('/login', { replace: true })
  }

  const onAccountClick = () => {
    setShowAccountPopup(true)
  }

  const onAccountPageDismiss = () => {
    setShowAccountPopup(false)
  }

  useEffect(() => {
    refetch()
    userPermissionsData.refetch()
  }, [])

  const { t } = useTranslation()
  return (
    <>
      {showAccountPopup && <UserAccount dismissHandler={onAccountPageDismiss} showLoader={setShowLoader} />}
      {showLoader && <Loader />}
      <div className='dashboard-outer-container' id='dashboard-outer-container'>
        <div className='dashboard-tabs-container'>
          <div className='dashboard-side-panel-merchant-logo-container'>
            {userDetails && userDetails.wide_brand_logo && (
              <img src={userDetails.wide_brand_logo} className='dashboard-side-panel-merchant-logo' />
            )}
          </div>
          <div>
            <NavLink
              to=''
              end
              className={({ isActive }) =>
                isActive ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab' : 'display_flex side-panel-tabs-btn-container'
              }
            >
              {({ isActive }) => (
                <>
                  <img src={statsIcon} className={isActive ? 'tab-icon selected-tab-icon' : 'tab-icon'} />
                  <p className={isActive ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
                    {t('stats')}
                  </p>
                </>
              )}
            </NavLink>

            {userPermissions &&
              (userPermissions.data.manage_organization ||
                userPermissions.data.manage_organization_access_flags ||
                userPermissions.data.manage_organization_item_type ||
                userPermissions.data.manage_organization_container) && (
                <NavLink
                  to='org'
                  className={({ isActive }) =>
                    isActive
                      ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab'
                      : 'display_flex side-panel-tabs-btn-container'
                  }
                >
                  {({ isActive }) => (
                    <>
                      <img src={organisationSettingsIcon} className={isActive ? 'tab-icon selected-tab-icon' : 'tab-icon'} />
                      <p className={isActive ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
                        {t('organisationSettings')}
                      </p>
                    </>
                  )}
                </NavLink>
              )}

            {userPermissions && userPermissions.data.manage_member && (
              <NavLink
                to='members'
                className={({ isActive }) =>
                  isActive ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab' : 'display_flex side-panel-tabs-btn-container'
                }
              >
                {({ isActive }) => (
                  <>
                    <img src={membersIcon} className={isActive ? 'tab-icon selected-tab-icon' : 'tab-icon'} />
                    <p className={isActive ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
                      {t('users')}
                    </p>
                  </>
                )}
              </NavLink>
            )}

            {userPermissions && userPermissions.data.manage_container && (
              <NavLink
                to='containers'
                className={({ isActive }) =>
                  isActive ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab' : 'display_flex side-panel-tabs-btn-container'
                }
              >
                {({ isActive }) => (
                  <>
                    <img src={containersIcon} className={isActive ? 'non-black-tab-icon selected-non-black-tab-icon' : 'non-black-tab-icon'} />
                    <p className={isActive ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
                      {t('containers')}
                    </p>
                  </>
                )}
              </NavLink>
            )}

            <NavLink
              to='items'
              className={({ isActive }) =>
                isActive ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab' : 'display_flex side-panel-tabs-btn-container'
              }
            >
              {({ isActive }) => (
                <>
                  <img src={itemsIcon} className={isActive ? 'non-black-tab-icon selected-non-black-tab-icon' : 'non-black-tab-icon'} />
                  <p className={isActive ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
                    {t('items')}
                  </p>
                </>
              )}
            </NavLink>
          </div>

          <div className='powered-by-emory-container'>
            <p className='powered-by-text'>Powered by</p>
            <img src={emoryLogo} />
          </div>
        </div>

        <div className='dashboard-contents-outer-container'>
          <div className='logged-in-member-name-top-container'>
            <Popup
              className='profile-options-dropdown'
              trigger={
                <div className='display_flex member-name-icon-container'>
                  <img src={profileIcon} className='member-profile-icon' />
                  <p className='logged-in-member-name-text'>{userPermissions?.data.user_name}</p>
                  <img src={dropdownIcon} className='dropdown-icon' onClick={onProfileOptionsClick} />
                </div>
              }
              position='bottom right'
            >
              <div className='profile-dropdown-options-container'>
                <div className='profile-options-item' onClick={onAccountClick}>
                  Account
                </div>
                <div className='profile-options-item' onClick={onLogoutClick}>
                  Logout
                </div>
              </div>
            </Popup>
          </div>

          <div className='dashboard-contents-bottom-container'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
