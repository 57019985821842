import React, { FormEvent, useContext, useState } from 'react'
import QueryString from 'qs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import Button from '../../../../components/Button/button'
import Divider from '../../../../components/divider/Divider'
import DropdownSelect from '../../../../components/dropdownSelect/dropdownSelect'
import Flex from '../../../../components/flex/Flex'
import ImageField from '../../../../components/ImageField/ImageField'
import InputField from '../../../../components/InputField/inputField'
import Loader from '../../../../components/Loader/loader'
import ToggleInput from '../../../../components/ToggleInput/ToggleInput'
import Text from '../../../../components/typography/Text'
import Tooltip from '../../../../components/tooltip'
import { createItemType, getDataTypes, getDefaultInspectionFields, getOrgItemTypeData, updateItemTypeV2 } from '../../../../api/apiCalls'
import { renderBreadCrumbs, useBreadCrumbs } from '../../../../contexts/breadcrumbs'
import { styled } from '../../../../stitches.config'
import { DataTable, TableBody, TableHead, Td, Th, Tr } from '../../../../components/dataTable/DataTable'

import trashIcon from '../../../../assets/images/trash_icon.svg'
import { UserDetailsContext } from '../../../../contexts/userDetailsContext'
import withTitle from '../../../../hoc/withTitle'
import { t } from 'i18next'
import { localeMap, TableRow } from '../container/EditContainer'

const Title = styled(Text, {
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '21px',
  marginTop: '2rem',
  flex: 1,
  mb: 16,
})

const info = (
  <svg height={15} width={15} className='iv-aspect-square' fill='currentColor' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.25 1.77748C4.6996 1.77748 1.82143 4.65565 1.82143 8.20605C1.82143 11.7565 4.6996 14.6346 8.25 14.6346C11.8004 14.6346 14.6786 11.7565 14.6786 8.20605C14.6786 4.65565 11.8004 1.77748 8.25 1.77748ZM0.75 8.20605C0.75 4.06392 4.10786 0.706055 8.25 0.706055C12.3921 0.706055 15.75 4.06392 15.75 8.20605C15.75 12.3482 12.3921 15.7061 8.25 15.7061C4.10786 15.7061 0.75 12.3482 0.75 8.20605Z'
      fill='currentColor'
      fillOpacity='0.4'
    />
    <path
      d='M8.78571 6.2138V11.1486H9.32143C9.6173 11.1486 9.85714 11.3884 9.85714 11.6843C9.85714 11.9801 9.6173 12.22 9.32143 12.22H7.17857C6.8827 12.22 6.64286 11.9801 6.64286 11.6843C6.64286 11.3884 6.8827 11.1486 7.17857 11.1486H7.71429V7.28523H7.17857C6.8827 7.28523 6.64286 7.04538 6.64286 6.74952C6.64286 6.45365 6.8827 6.2138 7.17857 6.2138H8.78571Z'
      fill='currentColor'
      fillOpacity='0.4'
    />
    <path
      d='M9.05357 4.83019C9.05357 5.27399 8.6938 5.63376 8.25 5.63376C7.8062 5.63376 7.44643 5.27399 7.44643 4.83019C7.44643 4.38639 7.8062 4.02662 8.25 4.02662C8.6938 4.02662 9.05357 4.38639 9.05357 4.83019Z'
      fill='currentColor'
      fillOpacity='0.4'
    />
  </svg>
)

type LayoutVector = {
  is_active: 0 | 1
  id?: string
  image?: string
  name?: string
}

type ItemTypeField = {
  is_active: 0 | 1
  data_type_id: number
  id?: string
  field_name: string
  field_name_i18n?: any
}

function EditItemType() {
  const { id } = useParams()
  const navigate = useNavigate()
  const isEditing = !!id

  const { data: userDetails } = useContext(UserDetailsContext)
  const supported_locales = JSON.parse(userDetails?.supported_locales || '[]')
  useBreadCrumbs(t('itemType'), null)

  const queryKey = ['itemType', id]
  const { data, isLoading, refetch } = useQuery(queryKey, () => getOrgItemTypeData(id), {
    enabled: !!id,
  })

  const queryClient = useQueryClient()

  const { data: defaultData } = useQuery(['defaultInspectionFields'], () => getDefaultInspectionFields())
  const itemType = data?.item_type

  const [newLayoutVectors, setNewLayoutVectors] = useState<LayoutVector[]>([])
  const [newInspectionFields, setNewInspectionFields] = useState<ItemTypeField[]>([])
  const [newStandardFields, setNewStandardFields] = useState<ItemTypeField[]>([])

  const breadcrumbs = useBreadCrumbs(isEditing ? itemType?.name : t('new'), null)

  const updateMutation = useMutation(
    (data: any) => {
      if (isEditing) {
        return updateItemTypeV2('', +id, data)
      } else {
        return createItemType('', data)
      }
    },
    {
      onSuccess: async (response, variables) => {
        if (response.data.message === 'success') {
          showSuccessToast()
          await refetch()
          setNewInspectionFields([])
          setNewStandardFields([])
          setNewLayoutVectors([])
          /* queryClient.setQueryData(queryKey, (data: any) => ({
            ...data,
            item_type: {
              ...data.item_type,
              standard_fields: variables?.standard_fields?.map((s: any) => ({
                ...s,
                field_name_i18n: JSON.stringify(s.field_name_i18n || {}),
              })),
              inspection_fields: variables?.inspection_fields?.map((i: any) => ({
                ...i,
                field_name_i18n: JSON.stringify(i.field_name_i18n || {}),
              })),
            },
          })) */
          if (!isEditing) navigate(`/dashboard/item-types/${response.data.itemTypeId}`)
        } else {
          showFailureToast()
        }
      },
      onError: () => showFailureToast(),
    }
  )

  const showSuccessToast = () => {
    toast.success('Successfully completed operation!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error('An unknown error occurred. Please retry in sometime.', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const { data: fieldTypesData } = useQuery('dataTypesList', getDataTypes)

  const defaultFieldTypes = (!isEditing && defaultData?.inspection_fields.map((i: any) => ({ field_name: i.name, is_active: 1, ...i }))) || []

  const fieldTypeOptions = fieldTypesData?.data_types.map((item: any) => ({ value: item.id, label: item.name }))

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    const target = event.target as HTMLFormElement
    const formEntries = Object.fromEntries(new FormData(target).entries())
    const formEntriesString = QueryString.stringify(formEntries)
    const formValues = QueryString.parse(formEntriesString, {
      allowDots: true,
    })
    const formValues2: any = formValues
    for( const p in formValues) {
      if((typeof formValues2[p])==='object') {
        formValues2[p] = Object.values(formValues2[p])
      }
    }
    updateMutation.mutate(formValues2)
  }

  return (
    <div className='organisation-settings-outer-container'>
      <ToastContainer
        position='bottom-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme='colored'
      />
      {isLoading && <Loader />}
      {renderBreadCrumbs(breadcrumbs)}
      <Divider />
      <Flex as='form' onSubmit={handleSubmit} direction='column' css={{ py: 32, px: 54 }}>
        <Flex css={{ gap: 20 }}>
          <ImageField
            clearable
            key={`default_image-${itemType?.id}`}
            defaultValue={itemType?.default_image}
            css={{ width: 160, height: 160 }}
            onDelete
            name='default_image'
            label={
              <Flex as='span' align='center' css={{ gap: 4 }}>
                {t('defaultIcon', { defaultValue: 'Default Icon' })}
                <Tooltip title={t('175x175JpegPngSupported')}>{info}</Tooltip>
              </Flex>
            }
          />
          <Flex direction='column' css={{ flex: 1, gap: 10 }}>
            <InputField
              label={t('itemTypeName')}
              defaultValue={itemType?.name}
              key={`name-${itemType?.id}`}
              placeholder='Enter Item Type Name'
              name='name'
              type='text'
            />
            <ToggleInput name='status' key={`status-${itemType?.id}`} defaultChecked={itemType?.status === 1} label={t('status')} />
          </Flex>
        </Flex>
        <Flex align='baseline'>
          <Title>{t('uploadItemTypeLayoutVectors').toLocaleUpperCase()}</Title>

          <Button
            type='button'
            btnStyle='textOnlyBtn'
            textColor='var(--colors-text)'
            width='auto'
            padding='0.5rem 0'
            onClick={() => {
              setNewLayoutVectors(newLayoutVectors.concat({ is_active: 1 }))
            }}
          >
            + {t('addNewView').toLocaleUpperCase()}
          </Button>
        </Flex>
        <Flex css={{ gap: 16, overflow: 'auto' }}>
          {[...(itemType?.layout_vectors || []), ...newLayoutVectors].map((item: LayoutVector, index: number) => {
            return (
              <ImageField
                prepend={
                  <>
                    <input type='hidden' name={`layout_vectors[${index}].id`} value={item.id} />
                    <input type='hidden' name={`layout_vectors[${index}].sequence`} value={index + 1} />
                  </>
                }
                append={
                  <InputField placeholder='name' style={{ padding: 8 }} defaultValue={item.name} name={`layout_vectors[${index}].name`} />
                }
                key={`index-${index}-${item.id}`}
                css={{ width: 240, height: 180, px: 24, py: 36, flex: 1 }}
                name={`layout_vectors[${index}].image`}
                defaultValue={item.image}
                isActiveDefaultValue={item.is_active}
              />
            )
          })}
        </Flex>

        <Flex align='baseline'>
          <Title>{t('itemFieldsForStandardDetails').toLocaleUpperCase()}</Title>
          <Button
            type='button'
            btnStyle='textOnlyBtn'
            textColor='var(--colors-text)'
            width='auto'
            padding='0.5rem 0'
            onClick={() =>
              setNewStandardFields(newStandardFields.concat({ field_name: '', is_active: 1, data_type_id: fieldTypeOptions[0].value }))
            }
          >
            + {t('addNewField')?.toLocaleUpperCase()}
          </Button>
        </Flex>
        <DataTable>
          <TableHead>
            <Tr>
              <Th>{t('defaultText')}*</Th>
              {supported_locales.map((locale: keyof typeof localeMap) => (
                <Th key={locale}>{localeMap[locale]}</Th>
              ))}
              <Th>{t('fieldType')}*</Th>
              <Th></Th>
            </Tr>
          </TableHead>
          <TableBody>
            {[...(itemType?.standard_fields || []), ...newStandardFields].map((item: any, index: number) => {
              return (
                <TableRow
                  supportedLocales={supported_locales}
                  isActiveDefaultValue={item.is_active}
                  item={item}
                  fieldTypeOptions={fieldTypeOptions}
                  prefix='standard_fields'
                  index={index}
                  key={`index-${index}-${item.id}`}
                />
              )
            })}
          </TableBody>
        </DataTable>

        <Flex align='baseline'>
          <Title>{t('itemFieldsForInspectionDetails').toLocaleUpperCase()}</Title>
          <Button
            type='button'
            btnStyle='textOnlyBtn'
            textColor='var(--colors-text)'
            width='auto'
            padding='0.5rem 0'
            onClick={() =>
              setNewInspectionFields(newInspectionFields.concat({ field_name: '', is_active: 1, data_type_id: fieldTypeOptions[0].value }))
            }
          >
            + {t('addNewField')?.toLocaleUpperCase()}
          </Button>
        </Flex>
        <DataTable>
          <TableHead>
            <Tr>
              <Th>{t('inspectionField')}*</Th>
              {supported_locales.map((locale: keyof typeof localeMap) => (
                <Th key={locale}>{localeMap[locale]}</Th>
              ))}
              <Th>{t('fieldType')}*</Th>
              <Th></Th>
            </Tr>
          </TableHead>
          <TableBody>
            {[...(itemType?.inspection_fields || []), ...newInspectionFields, ...defaultFieldTypes].map((item: any, index: number) => {
              return (
                <TableRow
                  supportedLocales={supported_locales}
                  isActiveDefaultValue={item.is_active}
                  item={item}
                  fieldTypeOptions={fieldTypeOptions}
                  prefix='inspection_fields'
                  index={index}
                  key={`index-${index}-${item.id}`}
                />
              )
            })}
          </TableBody>
        </DataTable>
        <div className='company-details-bottom-btn-container z-10'>
          <div className='display_flex company-details-bottom-btn-inner-container'>
            <Button
              type='button'
              backgroundColor='#EEEEEE'
              textColor='var(--colors-text)'
              padding='0.75rem 2rem'
              fontSize='12px'
              height='fit-content'
              width='auto'
              onClick={() => navigate('/dashboard/org/item-types')}
              disabled={updateMutation.isLoading}
            >
              {t('discard')}
            </Button>

            <Button
              type='submit'
              padding='0.75rem 2rem'
              fontSize='12px'
              height='fit-content'
              width='auto'
              leftMargin='1.25rem'
              disabled={updateMutation.isLoading}
            >
              {t('saveChanges')}
            </Button>
          </div>
        </div>
      </Flex>
    </div>
  )
}

export default withTitle(EditItemType, `${t('itemType')} | ${t('appName')}`)
