const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const API_CONSTANTS = {
  SIGN_IN_ENDPOINT: BASE_URL + 'user/authenticate',
  SUPERADMIN_SIGN_IN_ENDPOINT: BASE_URL + 'user/super-admin/authenticate',
  TRACKING_CONTAINER: BASE_URL + 'tracking',
  GET_USER_DETAILS_ENDPOINT: BASE_URL + 'organization/',
  GET_ORG_DETAILS_ENDPOINT: BASE_URL + 'organization/details',
  UPDATE_COMPANY_DETAILS_ENDPOINT: BASE_URL + 'organization/company-details/',
  UPDATE_PRIMARY_CONTACT_ENDPOINT: BASE_URL + 'organization/primary-contact-details/',
  UPDATE_ACCESS_FLAGS_ENDPOINT: BASE_URL + 'organization/access-flags/',
  ORGANIZATION_ITEM_TYPE_DATA_ENDPOINT: BASE_URL + 'organization/item-type/',
  GET_DATA_TYPES_ENDPOINT: BASE_URL + 'data_type',
  GET_ALL_ITEMS_LIST: BASE_URL + 'item/organization',
  GET_ITEM_DETAIL: BASE_URL + 'item/details/',
  GET_ALL_CONTAINERS_LIST: BASE_URL + 'container/organization',
  GET_ASSIGNEE_LIST: BASE_URL + 'user/item-assignee-list',
  ASSIGN_ITEM_ENDPOINT: BASE_URL + 'item/assign-item',
  EDIT_CONTAINER: BASE_URL + 'container/v2',
  EDIT_ITEM_ENDPOINT: BASE_URL + 'item/edit/v2',
  ADD_NEW_ITEM: BASE_URL + 'item',
  GET_ITEM_TYPES: BASE_URL + 'organization/item-types',
  GET_ITEM_STATUS_LIST: BASE_URL + 'dropdown/item-status',
  GET_CONTAINER_STATUS_LIST: BASE_URL + 'dropdown/container-status',
  GET_CONTAINER_ASSIGNEE_LIST: BASE_URL + 'dropdown/container-assignee',
  GET_CONTAINER_YARD_LOCATION_LIST: BASE_URL + 'dropdown/container-yard-location',
  GET_CONTAINER_FILTER_OPTIONS: BASE_URL + 'dropdown/container-filter-options',
  GET_ASSIGNEES_LIST: BASE_URL + 'dropdown/assignees',
  GET_MEMBERS_ENDPOINT: BASE_URL + 'user/organization',
  GET_MARKINGS_ENDPOINT: BASE_URL + 'organization/markings',
  CREATE_UPDATE_MARKINGS_ENDPOINT: BASE_URL + 'organization/markings',
  GET_MEMBER_DETAILS: BASE_URL + 'user/details/',
  GET_CONTAINER_LIST: BASE_URL + 'container',
  GET_DEFAULT_INSPECTION_FIELDS: BASE_URL + 'organization/item-types/default-fields',
  UPDATE_CONTAINER_SETTINGS_ENDPOINT: BASE_URL + 'organization/container',
  UPDATE_ITEM_SETTINGS_ENDPOINT: BASE_URL + 'organization/item',
  GET_USER_ROLES: BASE_URL + 'user/user-roles',
  UPDATE_MEMBER_DETAILS: BASE_URL + 'user/update/',
  CREATE_MEMBER_ENDPOINT: BASE_URL + 'user/signup',
  GET_CONTAINER_DETAILS: BASE_URL + 'container/details/',
  ASSIGN_CONTAINER_ENDPOINT: BASE_URL + 'container/assign-container',
  GET_CONTAINER_ITEMS: BASE_URL + 'item/container/',
  SEND_PASSWORD_RESET_LINK: BASE_URL + 'user/forgot-password',
  UPDATE_PASSWORD_ENDPOINT: BASE_URL + 'user/update-password/',
  SUSPEND_MEMBER_ENDPOINT: BASE_URL + 'user/suspend/',
  DELETE_MEMBER_ENDPOINT: BASE_URL + 'user/delete_user/',
  CREATE_ITEM_CHECK_REPORT_ENDPOINT: BASE_URL + 'item/generate-check-report/',
  CREATE_CONTAINER_CHECK_REPORT_ENDPOINT: BASE_URL + 'container/generate-check-report/',
  GET_CHECK_REPORT_V2: BASE_URL + 'check-report/',
  STATS_DATA_ENDPOINT: BASE_URL + 'stats/org',
  SA_STATS_DATA_ENDPOINT: BASE_URL + 'stats/sa',
  SUPERADMIN_GET_ALL_ORGANISATIONS: BASE_URL + 'organization/all',
  SUPERADMIN_GET_MEMBERS: BASE_URL + 'user/org-admin',
  LOGGED_IN_USER_DETAILS: BASE_URL + 'user',
  LOGGED_IN_USER_PERMISSIONS: BASE_URL + 'user/logged-user-permissions',
  // BATCH_UPLOAD_ITEMS_ENDPOINT: BASE_URL+'bulk-upload/item',
  BATCH_UPLOAD_ITEMS_ENDPOINT: BASE_URL + 'bulk-upload/container_item',
  BATCH_UPLOAD_CONTAINERS_ENDPOINT: BASE_URL + 'bulk-upload/container',
  BATCH_UPLOAD_USERS_ENDPOINT: BASE_URL + 'bulk-upload/user',
  EDIT_ITEM_TYPE_ENDPOINT: BASE_URL + 'item/item-type',
  GET_STAGE_LIST: BASE_URL + 'dropdown?type=stage',
  CREATE_NEW_STAGE: BASE_URL + 'dropdown',
  GET_SIGNED_URL: BASE_URL + 'file/get-signed-url',
  ARCHIVE_CONTAINER: BASE_URL + 'container/archive/',
  UNARCHIVE_CONTAINER: BASE_URL + 'container/unarchive/',
  ARCHIVE_ITEM: BASE_URL + 'item/archive/',
  UNARCHIVE_ITEM: BASE_URL + 'item/unarchive/',
  SIGNUP_ENDPOINT: BASE_URL + 'organization/signup',
  GET_ALL_ITEM_LOCATIONS: BASE_URL + 'dropdown/item-all-locations',
}

export const APP_CONSTANTS = {
  ACCEPTED_IMAGE_FORMATS: ['image/png', 'image/jpeg', 'image/jpg'],
  ACCEPTED_IMAGE_EXTENSIONS: '.png, .jpeg, .jpg',
}
