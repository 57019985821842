import React, { ChangeEvent, useContext, useState } from 'react'
import '../../items/EditItem/editItem.scss'
import arrowRight from '../../../assets/images/arrow_right_icon.svg'
import Button from '../../../components/Button/button'
import assignedToIcon from '../../../assets/images/assigned_to_person_icon.svg'
import uploadIcon from '../../../assets/images/upload_icon.svg'
import deleteIcon from '../../../assets/images/delete_icon_red.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { useEffect } from 'react'
import {
  archiveContainer,
  createContainerCheckReport,
  getContainerDetails,
  getMarkings,
  unarchiveContainer,
  updateContainerDetails,
  uploadFile,
} from '../../../api/apiCalls'
import AssignItemsPopup from '../../../components/AssignItemsPopup/assignItemsPopup'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import MediaSlider from '../../../components/MediaSlider/mediaSlider'
import { numberInputValidation } from '../../../helpers/validations'
import 'react-calendar/dist/Calendar.css'
import { UserPermissionsContext } from '../../../contexts/userPermissionsContext'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import InputField from '../../../components/InputField/inputField'
import { FaFileAlt, FaFileCsv, FaPlayCircle } from 'react-icons/fa'
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import variables from '../../../themes.module.scss'
import { BsFilePdf, BsFiletypeJson } from 'react-icons/bs'
import Loader from '../../../components/Loader/loader'
import { Link, useParams } from 'react-router-dom'
import ItemCard from '../../../components/itemCard'
import { IItem } from '../../../interfaces/items'
import Flex from '../../../components/flex/Flex'
import { useMutation, useQuery } from 'react-query'
import getThumbnail from '../../../utils/getThumbnail'
import { APP_CONSTANTS } from '../../../configs/constants'
import useImageCompressorAndUploader from '../../../hooks/useImageCompressorAndUploader'
import i18n, { t } from 'i18next'
import {formatDate} from "../../../utils/helper";
import ShareCheckReport from "../../containers_items_common/detailPageShareInspectionReport";
import {useAssigneeList} from "../../../contexts/assigneeListContext";
import DropdownSelect from '../../../components/dropdownSelect/dropdownSelect'

const EditContainerDetails = (props: any) => {
  const { compressAndUploadImage } = useImageCompressorAndUploader()
  const { ...userPermissionsData } = useContext(UserPermissionsContext)
  const userPermissions = userPermissionsData?.data
  const params = useParams()
  const { assigneesList } = useAssigneeList();

  const [loading, showLoader] = useState(false)
  const containerId = params?.id
  const [container, setContainer] = useState<any>()
  const [photosIdList, setPhotosIdList] = useState<any>([])
  const [documentsIdList, setDocumentsIdList] = useState<any>([])
  const [videosIdList, setVideosIdList] = useState<any>([])
  const [deletedDocumentsList, setDeletedDocumentsList] = useState<any>([])
  const [deletedPhotosList, setDeletedPhotosList] = useState<any>([])
  const [deletedVideosList, setDeletedVideosList] = useState<any>([])
  const [newDocumentsList, setNewDocumentsList] = useState<any>([])
  const [newPhotosList, setNewPhotosList] = useState<any>([])
  const [newVideosList, setNewVideosList] = useState<any>([])
  const [allowEdit, setAllowEdit] = useState(false)
  const [updatedStandardFields, setUpdatedStandardFields] = useState<any>([])
  const [updatedStandardFieldIds, setUpdatedStandardFieldIds] = useState<any>([])
  const [updatedInspectionFields, setUpdatedInspectionFields] = useState<any>([])
  const [updatedInspectionFieldIds, setUpdatedInspectionFieldIds] = useState<any>([])
  const [comments, setComments] = useState<string>('')
  const [showMediaSlider, setShowMediaSlider] = useState<boolean>(false)
  const [startingInd, setStartingInd] = useState<any>(0)
  const [mediaData, setMediaData] = useState<any>([])
  const [mediaType, setMediaType] = useState<any>('')
  const [isVideo, setIsVideo] = useState(false)
  const [docHoverId, setDocHoverId] = useState<any>(null)
  const [imgLoading, setImgLoading] = useState<boolean>(false)
  const [vidLoading, setVidLoading] = useState<boolean>(false)
  const [docLoading, setDocLoading] = useState<boolean>(false)
  const [tempBoolVal, setTempBoolVal] = useState<any>(null)
  const [booleanTypes, setBooleanTypes] = useState<any>([
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ])

  const override = css`
    display: block;
    margin: 0 auto;
  `

  const getBooleanVal = (val: any) => {
    if (val === true || val === 'true' || val === '1') {
      return 'Yes'
    } else if (val === false || val === 'false' || val === '0') {
      return 'No'
    } else {
      return ''
    }
  }

  const onEditClick = () => {
    setAllowEdit(true)
  }

  const onAddDocumentClick = (event: ChangeEvent<HTMLInputElement>) => {
    const orgId = userPermissions && userPermissions.data.org_uuid
    const documentsListCopy = [...container.documents]
    const tempArr = [...newDocumentsList]
    const documentsIdListCopy = [...documentsIdList]
    const numberOfExistingFiles = documentsListCopy.length
    event.target.files &&
      Array.from(event.target.files)
        .slice(0, 5 - numberOfExistingFiles)
        .forEach(async (file: any) => {
          if (file) {
            setDocLoading(true)
            const res: any = await uploadFile(file)
            const docURL = res.file_path || ''

            let tempId = 1
            let flag = true
            while (flag) {
              if (!documentsIdListCopy.includes(tempId)) {
                const newItem = { id: tempId, url: docURL, type: 'DOCUMENT' }
                documentsListCopy.push(newItem)
                tempArr.push(docURL)
                documentsIdListCopy.push(tempId)
                flag = false
              } else {
                tempId += 1
              }
            }
            setContainer({ ...container, documents: documentsListCopy })
            setNewDocumentsList(tempArr)
            setDocumentsIdList(documentsIdListCopy)
            setDocLoading(false)
            userPermissionsData.refetch()
          }
        })
    setNewDocumentsList(tempArr)
    setContainer({ ...container, documents: documentsListCopy })
    setDocumentsIdList(documentsIdListCopy)
  }

  const onAddImgClick = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setImgLoading(true)
      const tempArr = [...newPhotosList]
      const photosListCopy = [...container.photos]
      const newPhotosIdList = [...photosIdList]
      let tasks: Promise<any>[] = []
      for (const file of event.target.files) {
        tasks = tasks.concat(compressAndUploadImage(file))
      }
      const resArr: any[] = await Promise.all(tasks)
      for (const res of resArr) {
        const imgUrl = res.file_path || ''
        let tempId = 1
        let flag = true
        while (flag) {
          if (!newPhotosIdList.includes(tempId)) {
            const newItem = { id: tempId, url: imgUrl, type: 'PHOTO' }
            photosListCopy.push(newItem)
            tempArr.push({url:imgUrl, name:res.file_name})
            newPhotosIdList.push(tempId)
            flag = false
          } else {
            tempId += 1
          }
        }
      }
      setNewPhotosList(tempArr)
      setContainer({ ...container, photos: photosListCopy })
      setPhotosIdList(newPhotosIdList)
      setImgLoading(false)
    }
  }

  const onAddVideoClick = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setVidLoading(true)
      const tempArr = [...newVideosList]
      const uploadedVideosCopy: any = [...container.videos]
      const newVideosIdList = [...videosIdList]
      for (const file of event.target.files) {
        const res: any = await uploadFile(file)
        const videoURL = res.file_path || ''
        let tempId = 1
        let flag = true
        while (flag) {
          if (!newVideosIdList.includes(tempId)) {
            const newItem = { id: tempId, url: videoURL, type: 'VIDEO' }
            uploadedVideosCopy.push(newItem)
            tempArr.push(videoURL)
            newVideosIdList.push(tempId)
            flag = false
          } else {
            tempId += 1
          }
        }
      }
      setNewVideosList(tempArr)
      setContainer({ ...container, videos: uploadedVideosCopy })
      setVideosIdList(newVideosIdList)
      setVidLoading(false)
    }
  }

  const onDeleteUploadedDocuments = (id: any, url: any) => {
    const tempArr = [...deletedDocumentsList]
    tempArr.push(id)
    setDeletedDocumentsList(tempArr)

    const documentsListCopy: any = [...container.documents]
    container?.documents.find((item: any, ind: any) => {
      if (item.id === id) {
        URL.revokeObjectURL(item.url)
        documentsListCopy.splice(ind, 1)
        setContainer({ ...container, documents: documentsListCopy })
      }
    })

    const documentsIdListCopy: any = [...documentsIdList]
    documentsIdList.find((item: any, ind: any) => {
      if (item.id === id) {
        documentsIdListCopy.splice(ind, 1)
        setDocumentsIdList(documentsIdListCopy)
      }
    })

    const newDocumentsListCopy: any = newDocumentsList
    if (newDocumentsListCopy.includes(url)) {
      newDocumentsListCopy.find((item: any, ind: any) => {
        if (item === url) {
          newDocumentsListCopy.splice(ind, 1)
          setNewDocumentsList(newDocumentsListCopy)
        }
      })
    }
  }

  const onDeleteUploadedPhoto = (id: any, url: any) => {
    const tempArr = [...deletedPhotosList]
    tempArr.push(id)
    setDeletedPhotosList(tempArr)

    const photosListCopy: any = [...container.photos]
    container?.photos.find((item: any, ind: any) => {
      if (item.id === id) {
        URL.revokeObjectURL(item.url)
        photosListCopy.splice(ind, 1)
        setContainer({ ...container, photos: photosListCopy })
      }
    })

    const photosIdListCopy: any = [...photosIdList]
    photosIdList.find((item: any, ind: any) => {
      if (item.id === id) {
        photosIdListCopy.splice(ind, 1)
        setPhotosIdList(photosIdListCopy)
      }
    })

    const newPhotosListCopy: any = newPhotosList
    if (newPhotosListCopy.includes(url)) {
      newPhotosListCopy.find((item: any, ind: any) => {
        if (item === url) {
          newPhotosListCopy.splice(ind, 1)
          setNewPhotosList(newPhotosListCopy)
        }
      })
    }
  }

  const onDeleteUploadedVideo = (id: any, url: any) => {
    const tempArr = [...deletedVideosList]
    tempArr.push(id)
    setDeletedVideosList(tempArr)

    const videosListCopy: any = [...container.videos]
    container?.videos.find((item: any, ind: any) => {
      if (item.id === id) {
        URL.revokeObjectURL(item.url)
        videosListCopy.splice(ind, 1)
        setContainer({ ...container, videos: videosListCopy })
      }
    })

    const videosIdListCopy: any = [...videosIdList]
    videosIdList.find((item: any, ind: any) => {
      if (item.id === id) {
        videosIdListCopy.splice(ind, 1)
        setVideosIdList(videosIdListCopy)
      }
    })

    const newVideosListCopy: any = newVideosList
    if (newVideosListCopy.includes(url)) {
      newVideosListCopy.find((item: any, ind: any) => {
        if (item === url) {
          newVideosListCopy.splice(ind, 1)
          setNewVideosList(newVideosListCopy)
        }
      })
    }
  }

  const onStandardFieldChange = (id: any, dataType: any, value: any) => {
    if (dataType.toLowerCase() === 'number') {
      if (numberInputValidation(value)) {
        const standardItemsListCopy: any = [...container.standard_fields]
        container?.standard_fields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value
            standardItemsListCopy[ind] = tempElement
            setContainer({
              ...container,
              standard_fields: standardItemsListCopy,
            })
          }
        })
        if (updatedStandardFieldIds.includes(id)) {
          const updatedStandardFieldsCopy: any = [...updatedStandardFields]
          updatedStandardFields.find((item: any, ind: any) => {
            if (item.id === id) {
              const tempElement = { ...item }
              tempElement.value = value
              updatedStandardFieldsCopy[ind] = tempElement
              setUpdatedStandardFields(updatedStandardFieldsCopy)
            }
          })
        } else {
          const tempArr = [...updatedStandardFields]
          tempArr.push({
            id: id,
            value: value,
          })
          setUpdatedStandardFields(tempArr)
          const tempIdArr = [...updatedStandardFieldIds]
          tempIdArr.push(id)
          setUpdatedStandardFieldIds(tempIdArr)
        }
      }
    } else if (dataType.toLowerCase() === 'date') {
      const standardItemsListCopy: any = [...container.standard_fields]
      container?.standard_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          value.setHours(12)
          tempElement.value = value.getTime()
          standardItemsListCopy[ind] = tempElement
          setContainer({ ...container, standard_fields: standardItemsListCopy })
        }
      })
      if (updatedStandardFieldIds.includes(id)) {
        const updatedStandardFieldsCopy: any = [...updatedStandardFields]
        updatedStandardFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            value.setHours(12)
            tempElement.value = value.getTime()
            updatedStandardFieldsCopy[ind] = tempElement
            setUpdatedStandardFields(updatedStandardFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedStandardFields]
        value.setHours(12)
        tempArr.push({
          id: id,
          value: value.getTime(),
        })
        setUpdatedStandardFields(tempArr)
        const tempIdArr = [...updatedStandardFieldIds]
        tempIdArr.push(id)
        setUpdatedStandardFieldIds(tempIdArr)
      }
    } else if (dataType.toLowerCase() === 'boolean') {
      setTempBoolVal(value)
      const standardItemsListCopy: any = [...container.standard_fields]
      container?.standard_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = value === 'Yes' ? true : value === 'No' ? false : null
          standardItemsListCopy[ind] = tempElement
          setContainer({ ...container, standard_fields: standardItemsListCopy })
        }
      })
      if (updatedStandardFieldIds.includes(id)) {
        const updatedStandardFieldsCopy: any = [...updatedStandardFields]
        updatedStandardFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value === 'Yes' ? true : value === 'No' ? false : null
            updatedStandardFieldsCopy[ind] = tempElement
            setUpdatedStandardFields(updatedStandardFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedStandardFields]
        tempArr.push({
          id: id,
          value: value === 'Yes' ? true : value === 'No' ? false : null,
        })
        setUpdatedStandardFields(tempArr)
        const tempIdArr = [...updatedStandardFieldIds]
        tempIdArr.push(id)
        setUpdatedStandardFieldIds(tempIdArr)
      }
    } else {
      const standardItemsListCopy: any = [...container.standard_fields]
      container?.standard_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = value
          standardItemsListCopy[ind] = tempElement
          setContainer({ ...container, standard_fields: standardItemsListCopy })
        }
      })
      if (updatedStandardFieldIds.includes(id)) {
        const updatedStandardFieldsCopy: any = [...updatedStandardFields]
        updatedStandardFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value
            updatedStandardFieldsCopy[ind] = tempElement
            setUpdatedStandardFields(updatedStandardFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedStandardFields]
        tempArr.push({
          id: id,
          value: value,
        })
        setUpdatedStandardFields(tempArr)
        const tempIdArr = [...updatedStandardFieldIds]
        tempIdArr.push(id)
        setUpdatedStandardFieldIds(tempIdArr)
      }
    }
  }

  const onInspectionFieldChange = (id: any, dataType: any, value: any) => {
    if (dataType.toLowerCase() === 'number') {
      if (numberInputValidation(value)) {
        const inspectionItemsListCopy: any = [...container.inspection_fields]
        container?.inspection_fields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value
            inspectionItemsListCopy[ind] = tempElement
            setContainer({ ...container, inspection_fields: inspectionItemsListCopy })
          }
        })
        if (updatedInspectionFieldIds.includes(id)) {
          const updatedInspectionFieldsCopy: any = [...updatedInspectionFields]
          updatedInspectionFields.find((item: any, ind: any) => {
            if (item.id === id) {
              const tempElement = { ...item }
              tempElement.value = value
              updatedInspectionFieldsCopy[ind] = tempElement
              setUpdatedInspectionFields(updatedInspectionFieldsCopy)
            }
          })
        } else {
          const tempArr = [...updatedInspectionFields]
          tempArr.push({
            id: id,
            value: value,
          })
          setUpdatedInspectionFields(tempArr)
          const tempIdArr = [...updatedInspectionFieldIds]
          tempIdArr.push(id)
          setUpdatedInspectionFieldIds(tempIdArr)
        }
      }
    } else if (dataType.toLowerCase() === 'date') {
      const inspectionItemsListCopy: any = [...container.inspection_fields]
      container?.inspection_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          value.setHours(12)
          tempElement.value = value.getTime()
          inspectionItemsListCopy[ind] = tempElement
          setContainer({ ...container, inspection_fields: inspectionItemsListCopy })
        }
      })
      if (updatedInspectionFieldIds.includes(id)) {
        const updatedInspectionFieldsCopy: any = [...updatedInspectionFields]
        updatedInspectionFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            value.setHours(12)
            tempElement.value = value.getTime()
            updatedInspectionFieldsCopy[ind] = tempElement
            setUpdatedInspectionFields(updatedInspectionFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedInspectionFields]
        value.setHours(12)
        tempArr.push({
          id: id,
          value: value.getTime(),
        })
        setUpdatedInspectionFields(tempArr)
        const tempIdArr = [...updatedInspectionFieldIds]
        tempIdArr.push(id)
        setUpdatedInspectionFieldIds(tempIdArr)
      }
    } else if (dataType.toLowerCase() === 'boolean') {
      setTempBoolVal(value)
      const inspectionItemsListCopy: any = [...container.inspection_fields]
      container?.inspection_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = value === 'Yes' ? true : value === 'No' ? false : null
          inspectionItemsListCopy[ind] = tempElement
          setContainer({ ...container, inspection_fields: inspectionItemsListCopy })
        }
      })
      if (updatedInspectionFieldIds.includes(id)) {
        const updatedInspectionFieldsCopy: any = [...updatedInspectionFields]
        updatedInspectionFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value === 'Yes' ? true : value === 'No' ? false : null
            updatedInspectionFieldsCopy[ind] = tempElement
            setUpdatedInspectionFields(updatedInspectionFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedInspectionFields]
        tempArr.push({
          id: id,
          value: value === 'Yes' ? true : value === 'No' ? false : null,
        })
        setUpdatedInspectionFields(tempArr)
        const tempIdArr = [...updatedInspectionFieldIds]
        tempIdArr.push(id)
        setUpdatedInspectionFieldIds(tempIdArr)
      }
    } else {
      const inspectionItemsListCopy: any = [...container.inspection_fields]
      container?.inspection_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = value
          inspectionItemsListCopy[ind] = tempElement
          setContainer({ ...container, inspection_fields: inspectionItemsListCopy })
        }
      })
      if (updatedInspectionFieldIds.includes(id)) {
        const updatedInspectionFieldsCopy: any = [...updatedInspectionFields]
        updatedInspectionFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value
            updatedInspectionFieldsCopy[ind] = tempElement
            setUpdatedInspectionFields(updatedInspectionFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedInspectionFields]
        tempArr.push({
          id: id,
          value: value,
        })
        setUpdatedInspectionFields(tempArr)
        const tempIdArr = [...updatedInspectionFieldIds]
        tempIdArr.push(id)
        setUpdatedInspectionFieldIds(tempIdArr)
      }
    }
  }

  const onItemDetailsChange = (key: any, dataType: any, value: any) => {
    if (dataType.toLowerCase() === 'file') {
      onItemImageChangeHandler(key, value)
    } else if (dataType.toLowerCase() === 'number') {
      if (numberInputValidation(value)) {
        const itemDetailsCopy: any = [...container.basic_fields]
        container?.basic_fields.find((item: any, ind: any) => {
          if (item.key === key) {
            const tempElement = { ...item }
            tempElement.value = value
            itemDetailsCopy[ind] = tempElement
            setContainer({ ...container, basic_fields: itemDetailsCopy })
          }
        })
      }
    } else if (dataType.toLowerCase() === 'string' || dataType.toLowerCase() === 'text') {
      const itemDetailsCopy: any = [...container.basic_fields]
      container?.basic_fields.find((item: any, ind: any) => {
        if (item.key === key) {
          const tempElement = { ...item }
          tempElement.value = value
          itemDetailsCopy[ind] = tempElement
          setContainer({ ...container, basic_fields: itemDetailsCopy })
        }
      })
    } else if (dataType.toLowerCase() === 'date') {
      const itemDetailsCopy: any = [...container.basic_fields]
      container?.basic_fields.find((item: any, ind: any) => {
        if (item.key === key) {
          const tempElement = { ...item }
          value.setHours(12)
          tempElement.value = value.getTime()
          itemDetailsCopy[ind] = tempElement
          setContainer({ ...container, basic_fields: itemDetailsCopy })
        }
      })
    }
  }

  const onItemImageChangeHandler = async (key: any, event: any) => {
    if (event.target.files[0]) {
      const res: any = await compressAndUploadImage(event.target.files[0])
      const imgUrl = res.file_path || ''
      const itemDetailsCopy: any = [...container.basic_fields]
      container?.basic_fields.find((item: any, ind: any) => {
        if (item.key === key) {
          const tempElement = { ...item }
          tempElement.value = imgUrl
          itemDetailsCopy[ind] = tempElement
          setContainer({ ...container, basic_fields: itemDetailsCopy })
        }
      })
    }
  }

  const onCreateCheckReport = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const token = localStorage.getItem('accessToken')
    showLoader(true)
    const data = {
      requestId: Date.now()+'_'+userPermissions.data.user_id+'_container_'+containerId
    }
    createContainerCheckReport(containerId, token, data)
      .then((response: any) => {
        showLoader(false)
        getContainerDetailsOnLoad()
        if (response.status === 201) {
          showSuccessToast()
        } else {
          showFailureToast()
        }
      })
      .catch((error: any) => {
        showLoader(false)
        console.log(error)
        alert('Something went wrong, please try again later...')
      })
  }

  const onSaveClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const token = localStorage.getItem('accessToken')
    const containerDetails: any = {}
    container?.basic_fields.forEach((item: any) => {
      let keyToUse = item.key
      if(keyToUse === 'container_id') { keyToUse = 'external_container_id'}
      containerDetails[keyToUse] = item.value
    })
    containerDetails['id'] = containerId
    containerDetails['inspection_comments'] = comments
    const data = {
      deleted_photos: deletedPhotosList,
      deleted_videos: deletedVideosList,
      deleted_docs: deletedDocumentsList,
      docs: newDocumentsList,
      photos: newPhotosList,
      videos: newVideosList,
      markings: [],
      standard: updatedStandardFields,
      inspection: updatedInspectionFields,
      container_details: containerDetails,
    }

    setNewDocumentsList([])
    setNewPhotosList([])
    setNewVideosList([])
    showLoader(true)
    updateContainerDetails(data, token)
      .then(response => {
        showLoader(false)
        setAllowEdit(false)
        if (response.status === 200 || response.status === 204) {
          // getContainerDetailsOnLoad()
          showSuccessToast()
        } else {
          showFailureToast()
        }
      })
      .catch(error => {
        showLoader(false)
        console.log(error)
        alert('Something went wrong, please try again later!')
      })
  }

  const onDiscardChanges = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setNewDocumentsList([])
    setNewPhotosList([])
    setNewVideosList([])
    setAllowEdit(false)
    getContainerDetailsOnLoad()
  }

  const getContainerDetailsOnLoad = () => {
    showLoader(true)
    getContainerDetails(containerId)
      .then(response => {
        showLoader(false)
        setContainer(response.container)
        setComments(response.container?.inspection_comments)
      })
      .catch(error => {
        showLoader(false)
        console.log(error)
        alert('Something went wrong, please try again later.')
      })
  }

  useEffect(() => {
    userPermissionsData.refetch()
    getContainerDetailsOnLoad()
  }, [])

  const onMediaClick = (ind: any, type: string) => {
    if (type === 'video') {
      const index = container?.photos.length + ind
      setStartingInd(index)
      setIsVideo(true)
    } else {
      setStartingInd(ind)
      setIsVideo(false)
    }
    setMediaData(container?.photos.concat(container?.videos))
    setMediaType('photosVideos')
    setShowMediaSlider(true)
  }

  const onMarkingClick = (ind: any) => {
    setStartingInd(ind)
    setMediaData(container.markings)
    setMediaType('markings')
    setShowMediaSlider(true)
  }

  const onDateInputFocus = (key: any) => {
  }

  const showSuccessToast = () => {
    toast.success(allowEdit ? 'Container details saved!' : 'Container check report created!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error(
      allowEdit ? 'An error occurred while saving container details!' : 'An error occurred while creating check report!',
      {
        position: 'bottom-left',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      }
    )
  }

  const onBooleanDropdownFocus = (val: any, id: any, fieldType: any) => {
    setTempBoolVal(val)
    if (fieldType === 'inspection') {
      const inspectionItemsListCopy: any = [...container.inspection_fields]
      container?.inspection_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = ''
          inspectionItemsListCopy[ind] = tempElement
          setContainer({ ...container, inspection_fields: inspectionItemsListCopy })
        }
      })
    } else if (fieldType === 'standard') {
      const standardItemsListCopy: any = [...container.standard_fields]
      container?.standard_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = ''
          standardItemsListCopy[ind] = tempElement
          setContainer({ ...container, standard_fields: standardItemsListCopy })
        }
      })
    }
  }

  const onBooleanDropdownBlur = (id: any, fieldType: any) => {
    if (fieldType === 'inspection') {
      const inspectionItemsListCopy: any = [...container.inspection_fields]
      container?.inspection_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = tempBoolVal === 'Yes' ? true : tempBoolVal === 'No' ? false : null
          inspectionItemsListCopy[ind] = tempElement
          setContainer({ ...container, inspection_fields: inspectionItemsListCopy })
        }
      })
    } else if (fieldType === 'standard') {
      const standardItemsListCopy: any = [...container.standard_fields]
      container?.standard_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = tempBoolVal === 'Yes' ? true : tempBoolVal === 'No' ? false : null
          standardItemsListCopy[ind] = tempElement
          setContainer({ ...container, standard_fields: standardItemsListCopy })
        }
      })
    }
  }

  const onDocHover = (id: any) => {
    setDocHoverId(id)
  }

  useEffect(() => {
    if (allowEdit) {
      window.scrollTo({
        top: 1,
        behavior: 'smooth',
      })
    }
  }, [allowEdit])

  const toggleArchivedMutation = useMutation(
    () => {
      if (!containerId) return Promise.reject({})

      if (container.is_archived) {
        return unarchiveContainer(containerId)
      } else {
        return archiveContainer(containerId)
      }
    },
    {
      onSuccess: ({ data }) => {
        if (data.message === 'success') {
          setContainer({ ...container, is_archived: !container.is_archived })
          getContainerDetails(containerId).then(response => {
            setContainer(response.container)
          })
        }
      },
    }
  )

  const toggleArchived = async () => {
    toggleArchivedMutation.mutate()
  }

  const { data: { markings } = {} } = useQuery(['markingsList'], () => getMarkings())

  const updateAssignee = (response: any) => {
    assigneesList?.map((assignee: any) => {
      if (assignee.id === JSON.parse(response.config.data).user_id) {
        setContainer({...container, assignee_id: assignee.id, assignee_name: assignee.full_name });
        return;
      }
    });
  }

  if (!container && !loading) return null

  return (
    <>
      {(loading || toggleArchivedMutation.isLoading) && <Loader />}
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />

      {showMediaSlider && (
        <MediaSlider
          dismissHandler={() => setShowMediaSlider(false)}
          mediaData={mediaData}
          startingInd={startingInd}
          mediaType={mediaType}
          isVideo={isVideo}
        />
      )}

      <div className='sub-tab-content-container no_bottom_padding'>
        <div className='display_flex add-member-header-container'>
          <Link className='selected-tab-page-title cursor_pointer' to='..'>
            Containers
          </Link>
          <img src={arrowRight} className="arrow-right-icon" />
          <p className="sub-tab-selected-title">Container Details</p>
        </div>
        <p className="tabs-page-title-bottom-border" />

        <div className="display_flex add-new-item-outermost-container">
          <div className="pr-4 add-new-item-left-col-container">
            <div className="display_flex add-new-item-left-col-title-container">
              <p className="add-new-item-left-col-title">CONTAINER DETAILS</p>

              <Flex>
                {!allowEdit && (
                  <div className={'edit-data-btn'}>
                    <Button
                      btnStyle="textOnlyBtn"
                      textColor={container?.is_archived ? 'var(--colors-rich_moss)' : 'var(--colors-emory_orange)'}
                      textDecoration={'underline'}
                      width="auto"
                      padding="0.5rem 0"
                      onClick={toggleArchived}
                      disabled={toggleArchivedMutation.isLoading}
                    >
                      {!container?.is_archived ? 'Archive' : 'Unarchive'}
                    </Button>
                  </div>
                )}
                {!container?.is_archived && (
                  <div className={!allowEdit ? 'edit-data-btn' : 'edit-data-btn reduced_opacity_4'}>
                    <Button
                      btnStyle="textOnlyBtn"
                      textColor="#000000"
                      width="auto"
                      textDecoration={!allowEdit && 'underline'}
                      padding="0.5rem 0"
                      onClick={onEditClick}
                      className={allowEdit && 'cursor_default'}
                    >
                      {!allowEdit ? t('edit') : t('inEditMode')}
                    </Button>
                  </div>
                )}
              </Flex>
            </div>

            {container?.basic_fields &&
              container?.basic_fields.length > 0 &&
              container?.basic_fields.map((item: any) => {
                return (
                  <>
                    {item.key !== 'assignee_signature' && (
                      <div className='display_flex add-new-item-field-container' id={item.key} key={item.key}>
                        <p className='add-new-item-field-name'>{item.field_name}</p>
                        {item.key === 'image' ? (
                          <div
                            className={
                              !allowEdit
                                ? 'add-logo-field-container item-details-image-outer-container'
                                : 'add-logo-field-container item-details-image-outer-container-edit-mode'
                            }
                          >
                            <input
                              type='file'
                              className='logo-upload-input disabled:cursor-not-allowed'
                              disabled={!allowEdit}
                              accept={APP_CONSTANTS.ACCEPTED_IMAGE_EXTENSIONS}
                              onChange={e => onItemDetailsChange(item.key, item.data_type_key, e)}
                            />

                            <img src={item.value} className='wide-brand-logo-img cursor_pointer' alt='' />
                          </div>
                        ) : allowEdit && item.data_type_key && item.data_type_key === 'date' ? (
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode='select'
                            className='edit-details-date-picker-input width_adjustment'
                            dateFormat='dd MMM yyyy'
                            selected={item.value && item.data_type_key && item.data_type_key === 'date' && new Date(+item.value)}
                            onChange={(val: any) => onItemDetailsChange(item.key, item.data_type_key, val)}
                            placeholderText='Select Date'
                          />
                        ) : item.key === 'description' ? (
                          <textarea
                            disabled={!allowEdit}
                            value={
                              item.value
                                ? item.data_type_key && item.data_type_key === 'date'
                                  ? formatDate(item.value)
                                  : item.value
                                : ''
                            }
                            className={!allowEdit ? 'add-new-item-field-value' : 'add-new-item-field-value show-input-bg width_adjustment'}
                            onChange={e => onItemDetailsChange(item.key, item.data_type_key, e.target.value)}
                            onFocus={
                              allowEdit && item.data_type_key && item.data_type_key === 'date'
                                ? () => onDateInputFocus(item.key)
                                : undefined
                            }
                          ></textarea>
                        ) : (
                          <input
                            disabled={!allowEdit}
                            value={
                              item.value
                                ? item.data_type_key && item.data_type_key === 'date'
                                  ? formatDate(item.value)
                                  : item.value
                                : ''
                            }
                            className={!allowEdit ? 'add-new-item-field-value' : 'add-new-item-field-value show-input-bg width_adjustment'}
                            onChange={e => onItemDetailsChange(item.key, item.data_type_key, e.target.value)}
                            onFocus={
                              allowEdit && item.data_type_key && item.data_type_key === 'date'
                                ? () => onDateInputFocus(item.key)
                                : undefined
                            }
                          />
                        )}
                      </div>
                    )}
                  </>
                )
              })}

            {container?.standard_fields &&
              container?.standard_fields.length > 0 &&
              container?.standard_fields.map((item: any) => {
                return (
                  <>
                    <div className='display_flex add-new-item-field-container' id={item.id} key={item.id}>
                      <p className='add-new-item-field-name'>{item.field_name}</p>
                      {allowEdit ? (
                        item.data_type_key === 'boolean' ? (
                          <div className='row-left-field-container width_280 margin_adjustment'>
                            <DropdownSelect
                              placeholder=''
                              options={[{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]}
                              inputClassName="add-new-item-field-value upload-item-layout-front-view-text show-input-bg"
                              onChange={(val: any) => onStandardFieldChange(item.id, item.data_type_key, val)}
                              value={getBooleanVal(item.value)}
                              defaultValue={getBooleanVal(item.value)}
                              index={item.id}
                            />
                          </div>
                        ) : item.data_type_key && item.data_type_key === 'date' ? (
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode='select'
                            className='edit-details-date-picker-input width_adjustment'
                            dateFormat='dd MMM yyyy'
                            selected={item.value && item.data_type_key && item.data_type_key === 'date' && new Date(+item.value)}
                            onChange={(val: any) => onStandardFieldChange(item.id, item.data_type_key, val)}
                            placeholderText='Select Date'
                          />
                        ) : (
                          <input
                            disabled={!allowEdit}
                            value={
                              item.value
                                ? item.data_type_key && item.data_type_key === 'date'
                                  ? formatDate(item.value)
                                  : item.value
                                : ''
                            }
                            className={!allowEdit ? 'add-new-item-field-value' : 'add-new-item-field-value show-input-bg width_adjustment'}
                            onChange={e => onStandardFieldChange(item.id, item.data_type_key, e.target.value)}
                            onFocus={
                              allowEdit && item.data_type_key && item.data_type_key === 'date'
                                ? () => onDateInputFocus(item.id)
                                : undefined
                            }
                          />
                        )
                      ) : (
                        <p className='add-new-item-field-value add-new-item-disabled-field-value'>
                          {item.value && item.data_type_key && item.data_type_key === 'date'
                              ? formatDate(item.value)
                              : item.data_type_key === 'boolean'
                              ? getBooleanVal(item.value)
                              : item.value ? item.value
                            : ''}
                        </p>
                      )}
                    </div>
                  </>
                )
              })}
            {container?.items && container.items.length > 0 ? (
              <div className='flex flex-col items-start justify-start w-full gap-2'>
                <p className='text-base font-bold uppercase text-rich_moss'>Items</p>
                {container.items.map((item: IItem) => (
                  <ItemCard
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    mark={item.mark}
                    status={item.item_status}
                    image={item.image || item.default_image}
                  />
                ))}
              </div>
            ) : (
              <p className='mt-6 text-base font-bold text-calm_platinum'>No items available!</p>
            )}
          </div>

          {!container?.is_archived && (
            <div className='add-new-item-right-col-container'>
              <div className='add-new-item-right-col-content-container'>
                {container?.check_reports && container.check_reports.length > 0 &&
                  (<ShareCheckReport object={container} objectType={'container'} />)}

                {container?.container_status && container.container_status.toLowerCase() === 'submitted for review' && (
                  <div className='inspection-pending-msg-container'>
                    <p className='inspection-pending-msg-title'>INSPECTION PENDING</p>
                    <p className='inspection-pending-msg'>
                      Check Report will be generated after the assigned field agent completes an inspection or submits it for a review.
                    </p>
                  </div>
                )}

                <p className='add-new-item-left-col-title'>ASSIGNED TO</p>
                <div className='display_flex assigned-to-container'>
                  <p className='assigned-to-agent-title'>Assign To Field Agent</p>
                  <div className='display_flex'>
                    {container && container.assignee_name && <img src={assignedToIcon} className='assigned-to-person-icon' />}
                    <p className='assigned-to-person-name'>{container && container.assignee_name}</p>
                  </div>
                    <AssignItemsPopup
                      idList={[containerId]}
                      onAssign={updateAssignee}
                      showLoader={showLoader}
                      assignContainer={true}
                    >
                      <p className='reassign-btn-text'>
                        {container && container.assignee_name ? 'Re-assign' : 'Assign'}
                      </p>
                    </AssignItemsPopup>
                </div>

                <div className='display_flex add-new-item-left-col-title-container'>
                  <p className='add-new-item-left-col-title'>REVIEW INSPECTION DETAILS</p>
                  <div className={!allowEdit ? 'edit-data-btn' : 'edit-data-btn reduced_opacity_4'}>
                    <Button
                      btnStyle='textOnlyBtn'
                      textColor='#000000'
                      width='auto'
                      textDecoration={!allowEdit && 'underline'}
                      padding='0.5rem 0'
                      onClick={onEditClick}
                      className={allowEdit && 'cursor_default'}
                    >
                      {!allowEdit ? t('edit') : t('inEditMode')}
                    </Button>
                  </div>
                </div>

                {container?.inspection_fields &&
                  container?.inspection_fields.length > 0 &&
                  container?.inspection_fields.map((item: any) => {
                    return (
                      <>
                        <div className='display_flex add-new-item-field-container' id={item.key} key={item.key}>
                          <p className='add-new-item-field-name'>{item.field_name}</p>
                          {allowEdit ? (
                            item.data_type_key === 'boolean' ? (
                              <div className='row-left-field-container width_280 margin_adjustment'>
                                <DropdownSelect
                                  placeholder=''
                                  options={[{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]}
                                  inputClassName="add-new-item-field-value upload-item-layout-front-view-text show-input-bg"
                                  onChange={(val: any) => onInspectionFieldChange(item.id, item.data_type_key, val)}
                                  value={getBooleanVal(item.value)}
                                  defaultValue={getBooleanVal(item.value)}
                                  index={item.id}
                                />
                              </div>
                            ) : item.data_type_key && item.data_type_key === 'date' ? (
                              <DatePicker
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode='select'
                                className='edit-details-date-picker-input width_adjustment'
                                dateFormat='dd MMM yyyy'
                                selected={
                                  item.value && item.data_type_key && item.data_type_key === 'date' && new Date(+item.value)
                                }
                                onChange={(val: any) => onInspectionFieldChange(item.id, item.data_type_key, val)}
                                placeholderText='Select Date'
                              />
                            ) : (
                              <input
                                disabled={!allowEdit}
                                value={
                                  item.value
                                    ? item.data_type_key && item.data_type_key === 'date'
                                      ? formatDate(item.value)
                                      : item.value
                                    : ''
                                }
                                className={!allowEdit ? 'add-new-item-field-value' : 'add-new-item-field-value show-input-bg width_adjustment'}
                                onChange={e => onInspectionFieldChange(item.id, item.data_type_key, e.target.value)}
                                onFocus={
                                  allowEdit && item.data_type_key && item.data_type_key === 'date'
                                    ? () => onDateInputFocus(item.id)
                                    : undefined
                                }
                              />
                            )
                          ) : (
                            <p className='add-new-item-field-value add-new-item-disabled-field-value'>
                              {item.value && item.data_type_key && item.data_type_key === 'date'
                                  ? formatDate(item.value)
                                  : item.data_type_key === 'boolean'
                                  ? getBooleanVal(item.value)
                                  : item.value ? item.value
                                : ''}
                            </p>
                          )}
                        </div>
                      </>
                    )
                  })}

                {/*  ---- MARKINGS ----- */}
                {!container?.is_archived && container?.markings && container.markings.length > 0 && (
                  <>
                    <div className='display_flex add-new-item-left-col-title-container'>
                      <p className='add-new-item-left-col-title'>MARKINGS</p>
                      <div className={!allowEdit ? 'edit-data-btn' : 'edit-data-btn reduced_opacity_4'}>
                        <Button
                          btnStyle='textOnlyBtn'
                          textColor='#000000'
                          width='auto'
                          textDecoration={!allowEdit && 'underline'}
                          padding='0.5rem 0'
                          onClick={onEditClick}
                          className={allowEdit && 'cursor_default'}
                        >
                          {!allowEdit ? t('edit') : t('inEditMode')}
                        </Button>
                      </div>
                    </div>
                    <Flex justify='center' align='center' wrap='wrap' css={{ gap: 16, py: 16 }}>
                      {markings?.map((marking: any) => (
                        <div key={marking.id} className='display_flex legend-item-container'>
                          <div className='marking-legend-color-container' style={{ backgroundColor: `#${marking.color}` }}></div>
                          <p className='marking-legend-text'>
                            {JSON.parse(marking.text_i18n)[i18n.language] || marking.default_text}
                          </p>
                        </div>
                      ))}
                    </Flex>
                  </>
                )}
                <Swiper spaceBetween={10} slidesPerView={1} modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }}>
                  {container?.markings &&
                    container.markings.length > 0 &&
                    container.markings.map((marking: any, ind: any) => {
                      return (
                        <SwiperSlide key={marking.id}>
                          <div className='item-markimgs-img-container'>
                            <p className='markings-views-text'>{marking.name}</p>
                            <object
                              data={marking.marked_image ? marking.marked_image : marking.image}
                              className='add-new-item-markings-img cursor_pointer'
                              onClick={() => onMarkingClick(ind)}
                              type='image/png'
                            >
                              <img className='add-new-item-markings-img cursor_pointer' loading='lazy' src={marking.marked_image ?
                                marking.marked_image : marking.image} alt='' crossOrigin='anonymous' />
                            </object>
                          </div>
                        </SwiperSlide>
                      )
                    })}
                </Swiper>

                {/* ---- custom markings -----  */}
                {container?.custom_markings && container?.custom_markings.length > 0 && (
                  <>
                    <p className='add-new-item-left-col-title'>{t('customMarkings')}</p>
                    <ul className='add-new-item-field-value add-new-item-disabled-field-value'>
                      {container?.custom_markings?.map((marking: string) => (
                        <li>{marking}</li>
                      ))}
                    </ul>
                  </>
                )}

                <p className='add-new-item-left-col-title'>UPLOADED DOCUMENTS (max 5)</p>
                <div className='display_flex uploaded-data-container'>
                  {container?.documents &&
                    container?.documents.length > 0 &&
                    container?.documents.map((item: any, index: number) => {
                      const fileExtension = item.url.split('.').pop().toLowerCase();
                      const isImage = ['jpg', 'jpeg', 'png'].includes(fileExtension);
                      const isVideo = ['mp4', 'mov', 'MOV'].includes(fileExtension);
                      return (
                        <div className='add-new-item-upload-photos-single-photo-container' key={item.id}>
                          <div
                            className='add-logo-field-container add-new-item-type-img-upload-field-container'
                            onMouseOver={() => onDocHover(item.id)}
                            onPointerLeave={() => setDocHoverId(null)}
                          >
                            {item.url && (
                              <>
                                <div className='item-index'>{index + 1}</div>
                                <input type='file' className='logo-upload-input' disabled={true} title={item.url} />
                                <div className='uploaded-data-img-container' style={{ position: 'inherit' }}>
                                {isImage ? (
                                    <object
                                      className='item-details-img-style cursor_pointer position_relative'
                                      onClick={() => onMediaClick(index, 'photo')}
                                      data={getThumbnail(item.url)}
                                      type='image/png'
                                    >
                                      <img className='item-details-img-style' loading='lazy' src={item.url} alt='' crossOrigin='anonymous' />
                                    </object>
                                  ) : isVideo ? (
                                    <div className='uploaded-data-img-container uploaded-video-container' style={{ position: 'relative' }}>
                                      <video
                                        width='220'
                                        height='240'
                                        className='cursor_pointer position_relative'
                                        src={item.url}
                                        onClick={() => onMediaClick(index, 'video')}
                                      />
                                      {/* Overlay for video */}
                                      <div
                                        className='video-overlay'
                                        style={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          width: '100%',
                                          height: '100%',
                                          backgroundColor: 'rgba(0, 0, 0, 0.4)', // semi-transparent dark overlay
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          pointerEvents: 'none', // so it doesn't block clicks
                                        }}
                                      >
                                        <FaPlayCircle
                                          style={{
                                            fontSize: '3rem',
                                            color: 'white',
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : item.url && item.url.slice(-4).toLowerCase() === '.csv' ? (
                                    <FaFileCsv style={{ fontSize: '3.5rem' }} />
                                  ) : item.url.slice(-5).toLowerCase() === '.json' ? (
                                    <BsFiletypeJson style={{ fontSize: '3.5rem' }} />
                                  ) : item.url.slice(-4).toLowerCase() === '.pdf' ? (
                                    <BsFilePdf className='h-14 w-14 text-strong_graphite' />
                                  ) : 
                                    <FaFileAlt style={{ fontSize: '3.5rem' }} />
                                  }
                                </div>

                                {docHoverId === item.id && (
                                  <div className='open-doc-link-container'>
                                    <p
                                      style={{
                                        fontSize: '12px',
                                        margin: 'auto',
                                        overflowWrap: 'break-word',
                                      }}
                                    >
                                      <a href={item.url} target='_blank' style={{ color: 'white' }} rel='noreferrer'>
                                        Open in new tab
                                      </a>
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <p
                            style={{
                              position: 'inherit',
                              fontSize: '12px',
                              margin: 'auto',
                              overflowWrap: 'break-word',
                              textAlign: 'center',
                            }}
                          >
                            ...{(item.file_display_name || item.url).substr((item.file_display_name || item.url).length - 10)}
                          </p>
                          {allowEdit && (
                            <div className='uploaded-data-delete-icon-container'>
                              <img
                                src={deleteIcon}
                                className='uploaded-data-delete-icon'
                                onClick={() => onDeleteUploadedDocuments(item.id, item.url)}
                                crossOrigin='anonymous'
                                alt=''
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  {allowEdit && container?.documents.length <= 4 && (
                    <div className='add-new-item-upload-photos-single-photo-container'>
                      <div className='add-logo-field-container add-new-item-type-img-upload-field-container'>
                        <input
                          type='file'
                          className='logo-upload-input'
                          onChange={e => onAddDocumentClick(e)}
                          disabled={!allowEdit}
                          accept='.csv, .pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain'
                          multiple
                          onClick={(e: any) => {
                            e.target.value = null
                          }}
                        />
                        <div className='upload-item-img-camera-icon-container'>
                          {docLoading ? (
                            <ClipLoader color={variables.digitalMint} loading={docLoading} css={override} size={18} />
                          ) : (
                            <img src={uploadIcon} className='' />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!allowEdit && (!container?.documents || container?.documents.length === 0) && (
                    <p className='no-docs-txt'>No Documents found. Please click on Edit to add Documents</p>
                  )}
                </div>

                <p className='add-new-item-left-col-title'>UPLOADED PHOTOS</p>
                <div className='display_flex uploaded-data-container'>
                  {container?.photos &&
                    container?.photos.length > 0 &&
                    container?.photos.map((item: any, ind: any) => {
                      return (
                        <div className='add-new-item-upload-photos-single-photo-container' key={item.id}>
                          <div className='add-logo-field-container add-new-item-type-img-upload-field-container'>
                            {item.url && (
                              <>
                                <div className='item-index'>{ind + 1}</div>
                                <input
                                  type='file'
                                  className='logo-upload-input'
                                  disabled={true}
                                  title={item.url}
                                  accept={APP_CONSTANTS.ACCEPTED_IMAGE_EXTENSIONS}
                                />
                                <div className='uploaded-data-img-container'>
                                  <object
                                    className='item-details-img-style cursor_pointer position_relative'
                                    onClick={() => onMediaClick(ind, 'photo')}
                                    data={getThumbnail(item.url)}
                                    type='image/png'
                                  >
                                    <img className='item-details-img-style' loading='lazy' src={item.url} alt='' crossOrigin='anonymous' />
                                  </object>
                                </div>
                              </>
                            )}
                          </div>
                          {allowEdit && (
                            <div className='uploaded-data-delete-icon-container'>
                              <img
                                src={deleteIcon}
                                className='uploaded-data-delete-icon'
                                onClick={() => onDeleteUploadedPhoto(item.id, item.url)}
                                crossOrigin='anonymous'
                                alt=''
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  {allowEdit && (
                    <div className='add-new-item-upload-photos-single-photo-container'>
                      <div className='add-logo-field-container add-new-item-type-img-upload-field-container'>
                        <input
                          type='file'
                          className='logo-upload-input'
                          onChange={onAddImgClick}
                          disabled={!allowEdit}
                          accept={APP_CONSTANTS.ACCEPTED_IMAGE_EXTENSIONS}
                          onClick={(e: any) => {
                            e.target.value = null
                          }}
                          multiple
                        />
                        <div className='upload-item-img-camera-icon-container'>
                          {imgLoading ? (
                            <ClipLoader color={variables.digitalMint} loading={imgLoading} css={override} size={18} />
                          ) : (
                            <img src={uploadIcon} className='' crossOrigin='anonymous' alt='' />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!allowEdit && (!container?.photos || container?.photos.length === 0) && (
                    <p className='no-docs-txt'>No Photos found. Please click on Edit to add Photos</p>
                  )}
                </div>

                <p className='add-new-item-left-col-title'>UPLOADED VIDEO</p>
                <div className='display_flex uploaded-data-container'>
                  {container?.videos &&
                    container?.videos.length > 0 &&
                    container?.videos.map((item: any, ind: any) => {
                      return (
                        <div className='add-new-item-upload-photos-single-photo-container' key={item.id}>
                          <div className='add-logo-field-container add-new-item-type-img-upload-field-container'>
                            {item.url && (
                              <>
                                <div className='item-index'>{ind + 1}</div>
                                <input type='file' className='logo-upload-input' disabled={true} title={item.url} />
                                <div className='uploaded-data-img-container uploaded-video-container'>
                                  <video
                                    width='220'
                                    height='240'
                                    className='cursor_pointer'
                                    src={item.url}
                                    onClick={() => onMediaClick(ind, 'video')}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          {allowEdit && (
                            <div className='uploaded-data-delete-icon-container'>
                              <img
                                src={deleteIcon}
                                className='uploaded-data-delete-icon'
                                onClick={() => onDeleteUploadedVideo(item.id, item.url)}
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  {allowEdit && (
                    <div className='add-new-item-upload-photos-single-photo-container'>
                      <div className='add-logo-field-container add-new-item-type-img-upload-field-container'>
                        <input
                          type='file'
                          className='logo-upload-input'
                          onChange={onAddVideoClick}
                          disabled={!allowEdit}
                          multiple
                          accept='video/*'
                          onClick={(e: any) => {
                            e.target.value = null
                          }}
                        />
                        <div className='upload-item-img-camera-icon-container'>
                          {vidLoading ? (
                            <ClipLoader color={variables.digitalMint} loading={vidLoading} css={override} size={18} />
                          ) : (
                            <img src={uploadIcon} className='' />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!allowEdit && (!container?.videos || container?.videos.length === 0) && (
                    <p className='no-docs-txt'>No Videos found. Please click on Edit to add Videos</p>
                  )}
                </div>

                <p className='add-new-item-left-col-title'>COMMENTS</p>
                <p className='add-new-item-comments-text'>
                  {allowEdit ? (
                    <InputField
                      value={comments ? comments : ''}
                      error={false}
                      onChange={(e: any) => setComments(e)}
                      textarea={true}
                      disabled={!allowEdit}
                      className='member-address-textarea'
                    />
                  ) : (
                    <InputField
                      value={comments ? comments : ''}
                      autoSize={true}
                      disabled
                      textarea={true}
                      className='member-address-textarea'
                    />
                  )}
                </p>
              </div>
            </div>
          )}
        </div>

        {!container?.is_archived && (
          <div className='display_flex company-details-bottom-btn-inner-container add-new-item-check-report-bottom-btn-container company-details-bottom-btn-container'>
            {allowEdit && (
              <Button
                backgroundColor='#EEEEEE'
                textColor='#333333'
                padding='0.75rem 2rem'
                fontSize='12px'
                height='fit-content'
                width='auto'
                onClick={onDiscardChanges}
              >
                {allowEdit ? 'Cancel' : 'Discard Submission'}
              </Button>
            )}
            <Button
              padding='0.75rem 2rem'
              fontSize='12px'
              height='fit-content'
              width='auto'
              leftMargin='1.25rem'
              onClick={allowEdit ? onSaveClick : onCreateCheckReport}
            >
              {allowEdit
                ? 'Save Changes'
                : container?.check_reports && container.check_reports.length > 0
                ? 'Create New Version of this Check Report'
                : 'Create Check Report'}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default EditContainerDetails
