import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { getMembers, sendResetLink } from '../../../api/apiCalls'
import BatchUploadPopup from '../../../components/BatchUploadPopup/batchUploadPopup'
import { Button } from '../../../components/Button'
import { DataTable, TableBody, TableFooter, TableHead, Td, Th, Toolbar, Tr, useDataTable } from '../../../components/dataTable/DataTable'
import Divider from '../../../components/divider/Divider'
import Flex from '../../../components/flex/Flex'
import Loader from '../../../components/Loader/loader'
import { SearchBar, SearchContainer } from '../../../components/searchbar/SearchBar'
import { renderBreadCrumbs, useBreadCrumbs } from '../../../contexts/breadcrumbs'
import useUserFromLocalStorage from '../../../hooks/useUserFromLocalStorage'

const getMembersList = (search = '') => {
  return getMembers('', search)
}

function MemberList() {
  const { t } = useTranslation()
  const breadcrumbs = useBreadCrumbs(t('users'))
  const { hasUserManagementRights } = useUserFromLocalStorage()
  const { search, offset, itemsPerPage, sortBy, order, setUrlParam } = useDataTable()

  const { isLoading, data, refetch } = useQuery(['membersList', search], () => getMembersList(search.toLowerCase()), {
    enabled: localStorage.getItem('loggedIn') === 'true' ? true : false,
  })

  const members = useMemo(() => {
    if (sortBy) {
      return (
        data
          ?.slice()
          .sort((a: any, b: any) => (order === 'asc' ? -1 : 1) * (a[sortBy] < b[sortBy] ? 1 : -1))
          .slice(offset, offset + itemsPerPage) || []
      )
    }

    return data?.slice(offset, offset + itemsPerPage) || []
  }, [data, offset, itemsPerPage, sortBy, order])

  const handlePageChange = useCallback(
    (selectedItem: { selected: number }) => {
      const newOffset = (selectedItem.selected * itemsPerPage) % data?.length
      setUrlParam('offset', newOffset)
    },
    [itemsPerPage, data?.length, setUrlParam]
  )

  const handleItemsPerPageChange = useCallback(e => setUrlParam('show', e.value), [setUrlParam])

  const onResetPasswordClick = (email: any, status: any) => {
    if (status === 1) {
      const data = {
        email: email,
      }
      const token = localStorage.getItem('accessToken')
      sendResetLink(data, token)
        .then(response => {
          console.log(response)
          if (response.status !== 200) {
            alert(response.data.errors.message)
          } else {
            alert('Password reset link sent to your email!')
          }
        })
        .catch(error => {
          alert('Something went wrong, please try again later')
          console.log(error)
        })
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      {renderBreadCrumbs(breadcrumbs)}
      <Divider />
      <Toolbar>
        <SearchContainer>
          <SearchBar
            defaultValue={search}
            onChange={e => setUrlParam('search', e.target.value?.toLocaleLowerCase())}
            placeholder={t('searchUsers')!}
            type='search'
          />
        </SearchContainer>
        <Link to='new' className='outline-none'>
          <Button>+ {t('addNewUser')}</Button>
        </Link>
        <BatchUploadPopup onSuccess={refetch} type='members'>
          <Button intent='secondary'>{t('batchUploadUsers')}</Button>
        </BatchUploadPopup>
      </Toolbar>
      <Flex css={{ overflow: 'auto' }}>
        <DataTable>
          <TableHead>
            <Tr>
              <Th>{t('name')}</Th>
              <Th>{t('email')}</Th>
              <Th>{t('accessType')}</Th>
              <Th>{t('status')}</Th>
              <Th>{t('actions')}</Th>
              <Th></Th>
            </Tr>
          </TableHead>
          <TableBody>
            {members.map((item: any) => {
              return (
                <Tr css={{ height: 60 }} key={item.id}>
                  <Td>{item.full_name}</Td>
                  <Td>{item.email}</Td>
                  <Td>{item.user_role_name}</Td>
                  <Td>
                    <span className={item.status === 1 ? 'item-type-active-status-text' : 'item-type-suspentend-status-text'}>
                      {item.status === 1 ? t('active').toLocaleUpperCase() : t('suspended').toLocaleUpperCase()}
                    </span>
                  </Td>
                  <Td className={item.status === 1 ? 'item-type-active-Resend-text' : 'item-type-suspentend-resend-text'}>
                    <span className={item.status === 1 ? 'cursor_pointer' : ''} onClick={() => onResetPasswordClick(item.email, item.status)}>
                      {t('sendPasswordResetLink')}
                    </span>
                  </Td>
                  <Td>
                    {hasUserManagementRights(item?.user_role_key) ? (
                      <Link to={`${item.id}`}>
                        <span className='item-type-action-text'>{t('editUserInfo')}</span>
                      </Link>
                    ) : null}
                  </Td>
                </Tr>
              )
            })}
          </TableBody>
        </DataTable>
      </Flex>
      <TableFooter
        offset={offset}
        itemsPerPage={itemsPerPage}
        data={data}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  )
}

export default MemberList
