import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { updateAccessFlags } from '../../../../api/apiCalls'
import { UserDetailsContext } from '../../../../contexts/userDetailsContext'
import './accessFlags.scss'
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import variables from '../../../../themes.module.scss'
import { UserPermissionsContext } from '../../../../contexts/userPermissionsContext'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Loader/loader'
import { useBreadCrumbs } from '../../../../contexts/breadcrumbs'

const AccessFlags = () => {
  const { isLoading, isSuccess, isError, data: userDetails, error, refetch } = useContext(UserDetailsContext)
  const [loader, setLoader] = useState(false)
  const [itemId, setItemId] = useState<any>(null)
  const { ...userPermissionsData } = useContext(UserPermissionsContext)

  const { t } = useTranslation()
  useBreadCrumbs(t('accessFlags'), null)

  const handler = (item: any) => {
    const data = {
      status: item.value === 1 ? 0 : 1,
    }
    setItemId(item.id)
    const token = localStorage.getItem('accessToken')
    // props.showLoader(true)
    setLoader(true)
    updateAccessFlags(data, item.id, token)
      .then(response => {
        // props.showLoader(false)
        setLoader(false)
        if (response.status === 200) {
          refetch()
          userPermissionsData.refetch()
        }
      })
      .catch(error => {
        // props.showLoader(false)
        setLoader(false)
        console.log(error)
        alert('Something went wrong, please try again later!')
      })
  }

  useEffect(() => {
    refetch()
    // if(userDetails){
    //     console.log(userDetails)
    // }
  }, [])

  const override = css`
    display: block;
    margin: 0 auto;
  `

  return (
    <div className='sub-tab-content-container sub-tab-content-container-access-flags'>
      {isLoading && <Loader />}
      {userDetails &&
        userDetails.access_flags &&
        userDetails.access_flags.map((item: any, ind: any) => {
          // console.log(item)
          return ind === 0 ? (
            <div className='acc-flag-container' key={item.id}>
              <p className='acc-flag-data-title'>{item.title}</p>
              <div className='acc-flag-icon-container'>
                <div
                  className={item.value === 1 ? 'acc-flag-icon-border-active' : 'acc-flag-icon-border-inactive'}
                  onClick={() => {
                    handler(item)
                  }}
                >
                  <div className={item.value === 1 ? 'acc-flag-icon-dot-active' : 'acc-flag-icon-dot-inactive'}></div>
                </div>
                <p className={item.value === 1 ? 'acc-flag-icon-status-text-active' : 'acc-flag-icon-status-text-inactive'}>
                  {item.value === 1 ? t('active').toLocaleUpperCase() : t('inactive').toLocaleUpperCase()}
                </p>
                {item.id === itemId && (
                  <div className='access-flags-loader-container'>
                    <ClipLoader color={variables.digitalMint} loading={loader} css={override} size={18} />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className='acc-flag-container' key={item.id}>
              <p className='acc-flag-data-title'>{item.title}</p>
              <div className='acc-flag-icon-container'>
                <div
                  className={
                    item.value === 1
                      ? 'acc-flag-icon-border-active inactive_border cursor_default'
                      : 'acc-flag-icon-border-inactive cursor_default'
                  }
                >
                  <div
                    className={
                      item.value === 1 ? 'acc-flag-icon-dot-active inactive_color cursor_default' : 'acc-flag-icon-dot-inactive cursor_default'
                    }
                  ></div>
                </div>
                <p className='acc-flag-icon-status-text-inactive'>
                  {item.value === 1 ? t('active').toLocaleUpperCase() : t('inactive').toLocaleUpperCase()}
                </p>
                {item.id === itemId && (
                  <div className='access-flags-loader-container'>
                    <ClipLoader color={variables.digitalMint} loading={loader} css={override} size={18} />
                  </div>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default AccessFlags
