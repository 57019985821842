import React, { useContext } from 'react'
import { useState } from 'react'
import InputField from '../../../../components/InputField/inputField'
import { getEmailErrorMsg, phoneNumberValidation } from '../../../../helpers/validations'
import './companyDetails.scss'
import DropdownSelect from '../../../../components/dropdownSelect/dropdownSelect'
import Button from '../../../../components/Button/button'
import variables from '../../../../themes.module.scss'
import { OrgDetailsContext } from '../../../../contexts/orgDetailsContext'
import { useEffect } from 'react'
import { updateCompanyDetails, uploadFile } from '../../../../api/apiCalls'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import { UserPermissionsContext } from '../../../../contexts/userPermissionsContext'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Loader/loader'
import { useBreadCrumbs } from '../../../../contexts/breadcrumbs'

const companyTypeOptions = [
  { value: 'Private', label: 'Private' },
  { value: 'Business', label: 'Business' },
]

const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'German' },
  { value: 'es', label: 'Spanish' },
]

const paymentChannelOptions = [
  { value: 'card', label: 'card' },
  { value: 'netbanking', label: 'netbanking' },
]

const override = css`
  display: block;
  margin: 0 auto;
`

const CompanyDetails = () => {
  const [companyName, setCompanyName] = useState<string>('')
  const [companyEmail, setCompanyEmail] = useState<string>('')
  const [companyAddress, setCompanyAddress] = useState<string>('')
  const [mainPhoneNumber, setMainPhoneNumber] = useState<string>('')
  const [companyType, setCompanyType] = useState('')
  const [companyWebsite, setCompanyWebsite] = useState<string>('')
  const [companyVATid, setCompanyVATid] = useState<string>('')
  const [companyTAXid, setCompanyTAXid] = useState<string>('')
  const [preferredLanguage, setPreferredLanguage] = useState<string>('')
  const [supportedLocales, setSupportedLocales] = useState<string[]>([])
  const [companyRegistrationNum, setCompanyRegistrationNum] = useState<string>('')
  const [paymentChannel, setPaymentChannel] = useState<string>('')
  const [companyEmailError, setCompanyEmailError] = useState<string>('')
  const [wideBrandLogo, setWideBrandLogo] = useState<any>(null)
  const [squareBrandLogo, setSquareBrandLogo] = useState<any>(null)
  const [imgLoading, setImgLoading] = useState<boolean>(false)
  const [imgUploadingId, setImgUploadingId] = useState<any>('')
  const { isLoading, isSuccess, isError, data: orgDetails, error, refetch } = useContext(OrgDetailsContext)
  const { ...userPermissionsData } = useContext(UserPermissionsContext)
  const userPermissions = userPermissionsData?.data

  const onCompanyEmailChangeHandler = (value: string) => {
    setCompanyEmail(value)
    setCompanyEmailError(getEmailErrorMsg(value))
  }

  const onWideBrandLogoSelect = async (event: any) => {
    setImgUploadingId('wide-logo')
    if (event.target.files[0]) {
      // console.log(event.target.files[0])
      setImgLoading(true)
      // let orgId = userPermissions && userPermissions.data.organization_id
      const res: any = await uploadFile(event.target.files[0])
      const imgUrl = res.file_path || ''
      setWideBrandLogo(imgUrl)
      setImgLoading(false)
      setImgUploadingId('')
    }
  }

  const onSquareBrandLogoSelect = async (event: any) => {
    setImgUploadingId('square-logo')
    if (event.target.files[0]) {
      setImgLoading(true)
      // let orgId = userPermissions && userPermissions.data.organization_id
      const res: any = await uploadFile(event.target.files[0])
      const imgUrl = res.file_path || ''
      setSquareBrandLogo(imgUrl)
      setImgLoading(false)
      setImgUploadingId('')
    }
  }

  const onMainPhoneNumberChangeHandler = (value: any) => {
    if (phoneNumberValidation(value)) {
      setMainPhoneNumber(value)
    }
  }

  const onCompanyWebsiteChangeHandler = (value: any) => {
    setCompanyWebsite(value)
  }

  const onCompanyVATidChangeHandler = (value: any) => {
    setCompanyVATid(value)
  }

  const onCompanyTAXidChangeHandler = (value: any) => {
    setCompanyTAXid(value)
  }

  const onCompanyRegistrationNumChangeHandler = (value: any) => {
    setCompanyRegistrationNum(value)
  }

  const onCancelClick = () => {
    refetch()
    if (orgDetails) {
      setCompanyName(orgDetails.name)
      setCompanyEmail(orgDetails.email)
      setWideBrandLogo(orgDetails.wide_brand_logo)
      setCompanyAddress(orgDetails.address)
      setSquareBrandLogo(orgDetails.square_brand_logo)
      setMainPhoneNumber(orgDetails.main_phone)
      setCompanyType(orgDetails.type)
      setCompanyWebsite(orgDetails.website)
      setCompanyVATid(orgDetails.vat_id)
      setCompanyTAXid(orgDetails.tax_id)
      setCompanyRegistrationNum(orgDetails.registration_name)
      setPreferredLanguage(orgDetails.preferred_language)
      setSupportedLocales(JSON.parse(orgDetails.supported_locales) || [])
      setPaymentChannel(orgDetails.preferred_payment_channel)
    }
  }
  // console.log(supportedLocales)
  const onSaveChangesClick = () => {
    // let orgId = localStorage.getItem('orgId')
    const orgId = userPermissions && userPermissions.data.organization_id
    // let orgId = userPermissions && userPermissions.data.org_uuid;
    const data = {
      name: companyName,
      email: companyEmail,
      wide_brand_logo: wideBrandLogo,
      square_brand_logo: squareBrandLogo,
      address: companyAddress,
      main_phone: mainPhoneNumber,
      type: companyType,
      website: companyWebsite,
      vat_id: companyVATid,
      tax_id: companyTAXid,
      registration_name: companyRegistrationNum,
      preferred_language: preferredLanguage,
      preferred_payment_channel: paymentChannel,
      supported_locales: supportedLocales,
    }
    const token = localStorage.getItem('accessToken')
    updateCompanyDetails(data, orgId, token)
      .then(response => {
        refetch()
        if (response.status === 200) {
          showSuccessToast()
        } else {
          showFailureToast()
        }
        // console.log(response)
      })
      .catch(error => {
        console.log(error)
        alert('Something went wrong, please try again later!')
      })
  }

  const showSuccessToast = () => {
    toast.success('Company details saved!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error('An error occurred while saving company details!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  useEffect(() => {
    if (orgDetails) {
      setCompanyName(orgDetails.name)
      setCompanyEmail(orgDetails.email)
      setWideBrandLogo(orgDetails.wide_brand_logo)
      setCompanyAddress(orgDetails.address)
      setSquareBrandLogo(orgDetails.square_brand_logo)
      setMainPhoneNumber(orgDetails.main_phone)
      setCompanyType(orgDetails.type)
      setCompanyWebsite(orgDetails.website)
      setCompanyVATid(orgDetails.vat_id)
      setCompanyTAXid(orgDetails.tax_id)
      setCompanyRegistrationNum(orgDetails.registration_name)
      setPreferredLanguage(orgDetails.preferred_language)
      setSupportedLocales(JSON.parse(orgDetails.supported_locales) || [])
      setPaymentChannel(orgDetails.preferred_payment_channel)
    }
  }, [orgDetails])

  const { t } = useTranslation()

  useBreadCrumbs(t('companyDetails'), null)

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer
        position='bottom-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme='colored'
      />

      <div className='sub-tab-content-container company-details-tab-outer-container'>
        <div className='display_flex flex-row-input-fields-container'>
          <div className='add-new-item-left-col-container'>
            <div className='row-left-field-container'>
              <InputField
                label={t('companyName')}
                value={companyName}
                placeholder='Enter Customer Name'
                onChange={(val: string) => setCompanyName(val)}
                type='text'
                error={false}
              />
            </div>

            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <p className='input-field-label'>{t('companyWideBrandLogo')}</p>
              <div className='display_flex org-settings-img-upload-container'>
                <div className={'add-logo-field-container'}>
                  <input type='file' className='logo-upload-input' onChange={onWideBrandLogoSelect} accept='image/*' />
                  {imgUploadingId === 'wide-logo' ? (
                    <ClipLoader color={variables.digitalMint} loading={imgLoading} css={override} size={18} />
                  ) : wideBrandLogo ? (
                    <img src={wideBrandLogo} className='wide-brand-logo-img' />
                  ) : (
                    '+ ADD LOGO'
                  )}
                </div>
                <p className='img-upload-req-text'>460 x 200 | JPEG, PNG supported</p>
              </div>
            </div>

            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <p className='input-field-label'>{t('companySquareBrandLogo')}</p>
              <div className='display_flex org-settings-img-upload-container'>
                <div className={'add-logo-field-container'}>
                  <input type='file' className='logo-upload-input' onChange={onSquareBrandLogoSelect} accept='image/*' />
                  {imgUploadingId === 'square-logo' ? (
                    <ClipLoader color={variables.digitalMint} loading={imgLoading} css={override} size={18} />
                  ) : squareBrandLogo ? (
                    <img src={squareBrandLogo} className='square-brand-logo-img' />
                  ) : (
                    '+'
                  )}
                </div>
                <p className='img-upload-req-text'>250 x 250 | JPEG, PNG supported</p>
              </div>
            </div>

            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <DropdownSelect
                label={t('companyType')!}
                placeholder='Select Company Type'
                options={companyTypeOptions}
                onChange={(val: any) => setCompanyType(val)}
                id='company-type-dropdown'
                value={companyType}
                index={0}
              />
            </div>

            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('companyVATID')}
                value={companyVATid}
                placeholder='Enter Company VAT ID'
                onChange={onCompanyVATidChangeHandler}
                type='text'
                error={false}
              />
            </div>

            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('companyRegistrationNumber')}
                value={companyRegistrationNum}
                placeholder='Company Registration Number'
                onChange={onCompanyRegistrationNumChangeHandler}
                type='text'
                error={false}
              />
            </div>

            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <DropdownSelect
                label={t('preferredPaymentChannel')!}
                placeholder='Select Preferred Payment Channel'
                options={paymentChannelOptions}
                onChange={(val: any) => setPaymentChannel(val)}
                value={paymentChannel}
                id='preferred-payment-channel'
                index={1}
                className='preferred-payment-channel-dropdown'
              />
            </div>
          </div>

          <div className='add-new-item-right-col-container org-settings-company-details-right-col'>
            <div className='row-right-field-container'>
              <InputField
                label={t('companyEmail')}
                value={companyEmail}
                placeholder='Enter Primary Contact Email'
                onChange={onCompanyEmailChangeHandler}
                type='email'
                error={companyEmailError}
              />
            </div>

            <div className='row-right-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('companyAddress')}
                value={companyAddress}
                error={false}
                placeholder='Enter Company Address'
                onChange={(val: string) => setCompanyAddress(val)}
                textarea={true}
                className='member-address-textarea'
                // className="user-profile-input-field user-profile-textarea-style"
              />
            </div>

            <div className='row-right-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('companyPhoneNumber')}
                value={mainPhoneNumber}
                placeholder='Enter Main Phone Number'
                onChange={onMainPhoneNumberChangeHandler}
                type='text'
                error={false}
              />
            </div>

            <div className='row-right-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('companyWebsite')}
                value={companyWebsite}
                placeholder='Enter Company Website'
                onChange={onCompanyWebsiteChangeHandler}
                type='text'
                error={false}
              />
            </div>

            <div className='row-right-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('companyTaxID')}
                value={companyTAXid}
                placeholder='Enter Company Tax ID'
                onChange={onCompanyTAXidChangeHandler}
                type='text'
                error={false}
              />
            </div>

            <div className='row-right-field-container row-input-fields-container-margin-top'>
              <DropdownSelect
                label={t('preferredLanguage')!}
                placeholder='Select Preferred Language'
                options={languageOptions}
                onChange={(val: any) => setPreferredLanguage(val)}
                id='preferred-language-dropdown'
                value={preferredLanguage}
                index={2}
              />
            </div>

            <div className='row-right-field-container row-input-fields-container-margin-top'>
              <DropdownSelect
                label={t('supportedLocales', { defaultValue: 'Supported Locales' })!}
                placeholder='Select Supported Locales'
                options={languageOptions}
                onChange={(val: any) => {
                  setSupportedLocales(val)
                }}
                id='preferred-language-dropdown'
                value={supportedLocales}
                multiple
              />
            </div>
          </div>
        </div>

        <div className='company-details-bottom-btn-container'>
          <div className='display_flex company-details-bottom-btn-inner-container'>
            <Button
              backgroundColor={variables.clearSilver}
              textColor='#333333'
              padding='0.75rem 2rem'
              fontSize='12px'
              height='fit-content'
              width='auto'
              onClick={onCancelClick}
            >
              {t('discard')}
            </Button>

            <Button padding='0.75rem 2rem' fontSize='12px' height='fit-content' width='auto' leftMargin='1.25rem' onClick={onSaveChangesClick}>
              {t('saveChanges')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyDetails
