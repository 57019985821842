import * as Dialog from '@radix-ui/react-dialog';
import React, { useContext, useEffect, useState } from 'react';
import { assignContainerToUser, assignItemsToUser } from '../../api/apiCalls';
import './assignItemsPopup.scss';
import searchIcon from '../../assets/images/input_search_icon.svg';
import assigneeIcon from '../../assets/images/assignee_name_icon.svg';
import Loader from '../Loader/loader';
import { UserPermissionsContext } from '../../contexts/userPermissionsContext';
import { useAssigneeList } from '../../contexts/assigneeListContext';

const AssignItemsPopup = (props: any) => {
  const { ...userPermissionsData } = useContext(UserPermissionsContext);
  const { assigneesList, isLoading: isAssigneeListLoading } = useAssigneeList();
  const userPermissions = userPermissionsData?.data;
  const isAllowed: boolean = userPermissions && userPermissions.data.assign_objects;
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (props.open) {
      setSearchString('');
    }
  }, [props.open]);

  const onAssignClick = (id: any) => {
    const token = localStorage.getItem('accessToken');
    const data = props.assignContainer
      ? { user_id: id, container_ids: props.idList }
      : { user_id: id, item_ids: props.idList };

    const assignFunction = props.assignContainer ? assignContainerToUser : assignItemsToUser;

    assignFunction(data, token)
      .then(response => {
        props.onAssign(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const filteredAssignees = assigneesList.filter((assignee: any) =>
    assignee.full_name.toLowerCase().includes(searchString.toLowerCase())
  );

  return (
    <>
      {isAllowed && (
        <Dialog.Root>
          <Dialog.Trigger asChild>{props.children}</Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay
              style={{
                height: '100%',
                background: 'rgba(174,174,174,0.1)',
                zIndex: 99999,
                backdropFilter: 'blur(5px)',
                WebkitBackdropFilter: 'blur(5px)',
                position: 'fixed',
                width: '100%',
                top: '0',
                left: '0',
              }}
            />
            <Dialog.Content
              style={{
                fontFamily: 'Nunito',
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 999999999,
              }}
            >
              <div>
                {isAssigneeListLoading && <Loader />}
                <div className='assignee-list-card-outer-container assignee-list-card-outer-container-no-top-padding'>
                  <div className='assignee-list-card-sticky-top-container'>
                    <div className='display_flex assign-title-close-icon-container'>
                      <p className='assign-items-title'>ASSIGN ITEMS</p>
                      <Dialog.Close asChild>
                        <p className='cross-icon cursor_pointer'>x</p>
                      </Dialog.Close>
                    </div>

                    <div className='assignee-name-search-container'>
                      <img src={searchIcon} className='assignee-search-input-icon' />
                      <input
                        className='assignee-name-search-input'
                        placeholder='Search field agent to assign to'
                        value={searchString}
                        onChange={e => setSearchString(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    {filteredAssignees.map((assignee: any) => (
                      <div className='display_flex assignee-names-list-outer-container' key={assignee.id}>
                        <div className='display_flex assignee-names-individual-container'>
                          <img src={assigneeIcon} className='assignee-icon' />
                          <p className='assigne-list-name'>{assignee.full_name}</p>
                        </div>
                        <Dialog.Close asChild>
                          <p className='reassign-btn-text' onClick={() => onAssignClick(assignee.id)}>
                            Assign
                          </p>
                        </Dialog.Close>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      )}
    </>
  );
};

export default AssignItemsPopup;
