import React, { useState, useEffect } from "react";
import withTitle from "../../hoc/withTitle";
import Button from '../../components/Button/button'
import { trackContainer } from '../../api/apiCalls'
import InputField from '../../components/InputField/inputField'
import './tracking.scss'
import { MapContainer, TileLayer, Marker, useMap, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import L, {Icon} from 'leaflet'
import emoryLogo from '../../assets/images/emory_logo.svg'
import {formatDate} from "../../utils/helper";

const Tracking = (props: any) => {
  const [coordinate, setCoordinate] = useState<[number, number] | null>(null);
  const [trackId, setTrackId] = useState('')
  const [zoom, setZoom] = useState(5);
  const [address, setAddress] = useState<string | null>(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [updatedAt, setUpdatedAt] = useState<string | null>(null)

  const onTrackButtonClick = () => {
    if (!trackId.trim()) {
      setErrorMessage('Tracking ID is required.');
      return;
    }

    const data = {
      trackingId: trackId
    }
    trackContainer(data)
      .then(({ data }) => {
        const { coordinate, status, updatedAt } = data;
        if (status == 200) {
          setCoordinate([coordinate.latitude, coordinate.longitude])
          setZoom(5);
          setAddress(null);
          setShowSidebar(false);
          setErrorMessage('');
          setUpdatedAt(formatDate(updatedAt, true))
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch((error) => {
        alert(error)
      })
  }

  const fetchAddress = async (lat: number, lon: number) => {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=18&addressdetails=1`);
    const data = await response.json();
    if (data && data.display_name) {
      setAddress(data.display_name);
      setShowSidebar(true);
    } else {
      setAddress('Address not found');
    }
  };

  const MapUpdater = ({ coordinate, zoom }: { coordinate: [number, number] | null, zoom: number }) => {
    const map = useMap();
    useEffect(() => {
      if (coordinate) {
        map.setView(coordinate, zoom);
      }
    }, [coordinate, zoom, map]);

    return null;
  };

  return (
    <div className="tracking-page">
      <nav className="navbar">
        <div className="logo-container">
          <img src={emoryLogo} className='check-report-emory-logo' alt='' />
        </div>
        <h3 className="search-header">Track your shipment</h3>
      </nav>

      <div className="main-content">

        <div className="search-input">
          <InputField
            value={trackId}
            placeholder="Enter Shipment VIN"
            onChange={(val: string) => setTrackId(val)}
            type="text"
          />
          <Button
            padding="0.75rem 2rem"
            fontSize="12px"
            height="fit-content"
            width="auto"
            leftMargin="1.25rem"
            onClick={onTrackButtonClick}
          >
            Track
          </Button>
        </div>
        {errorMessage && (
          <p className="error-message">{errorMessage}</p>
        )}
        <div className="map-container">
          <MapContainer center={[52.237049, 21.017532]} zoom={zoom} style={{ height: 700 }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {coordinate && (
              <Marker
                position={coordinate}
                icon={
                  new Icon({
                    iconUrl: markerIconPng,
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                  })
                }
                eventHandlers={{
                  click: () => {
                    fetchAddress(coordinate[0], coordinate[1]);
                  }
                }}
              >
              </Marker>
            )}
            <MapUpdater coordinate={coordinate} zoom={zoom} />
          </MapContainer>
        </div>
      </div>

      {showSidebar && (
        <div className="sidebar">
          <button className="close-sidebar" onClick={() => setShowSidebar(false)}>X</button>
          <h2>Location Information</h2>
          <p><strong>Tracking Id: </strong> {trackId ? trackId : 'Not available'}</p>
          <p><strong>Updated At: </strong> {updatedAt ? updatedAt : 'Not available' }</p>
          <p><strong>Address:</strong> {address || 'Fetching address...'}</p>
        </div>
      )}

    </div>
  );
};
export default withTitle(Tracking, 'Track Shipments | Emory Pro')
