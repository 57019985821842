import React, { ChangeEvent, useContext, useState } from 'react'
import './editItem.scss'
import arrowRight from '../../../assets/images/arrow_right_icon.svg'
import Button from '../../../components/Button/button'
import assignedToIcon from '../../../assets/images/assigned_to_person_icon.svg'
import uploadIcon from '../../../assets/images/upload_icon.svg'
import deleteIcon from '../../../assets/images/delete_icon_red.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { useEffect } from 'react'
import { archiveItem, createItemCheckReport, editItemData, getItemDetail, getMarkings, unarchiveItem, uploadFile, getItemTypes, updateItemType } from '../../../api/apiCalls'
import AssignItemsPopup from '../../../components/AssignItemsPopup/assignItemsPopup';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import MediaSlider from '../../../components/MediaSlider/mediaSlider'
import { numberInputValidation } from '../../../helpers/validations'
import 'react-calendar/dist/Calendar.css'
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import variables from '../../../themes.module.scss'
import { UserPermissionsContext } from '../../../contexts/userPermissionsContext'
import { ContainerListContext } from '../../../contexts/containerListContext'
import {AssigneeListContext, useAssigneeList} from '../../../contexts/assigneeListContext';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import InputField from '../../../components/InputField/inputField'
import editIcon from '../../../assets/images/edit_icon.svg'
import EditItemTypePopup from '../../../components/EditItemTypePopup/editItemTypePopup'
import { StageListContext } from '../../../contexts/stageListContext'
import { FaFileAlt, FaFileCsv, FaPlayCircle } from 'react-icons/fa'
import { BsFilePdf, BsFiletypeJson } from 'react-icons/bs'
import Loader from '../../../components/Loader/loader'
import { Link, useParams } from 'react-router-dom'
import ItemCard from '../../../components/itemCard'
import { useMutation, useQuery } from 'react-query'
import Flex from '../../../components/flex/Flex'
import getThumbnail from '../../../utils/getThumbnail'
import useImageCompressorAndUploader from '../../../hooks/useImageCompressorAndUploader'
import { APP_CONSTANTS } from '../../../configs/constants'
import i18n, { t } from 'i18next'
import {formatDate} from "../../../utils/helper";
import ShareCheckReport from "../../containers_items_common/detailPageShareInspectionReport";
import Select from 'react-select';
import DropdownSelect from '../../../components/dropdownSelect/dropdownSelect'

const EditItem = () => {
  const { compressAndUploadImage } = useImageCompressorAndUploader()
  const [loading, showLoader] = useState(false)
  const [data, setData] = useState<any>()
  const [photosIdList, setPhotosIdList] = useState<any>([])
  const [documentsIdList, setDocumentsIdList] = useState<any>([])
  const [videosIdList, setVideosIdList] = useState<any>([])

  const [deletedDocumentsList, setDeletedDocumentsList] = useState<any>([])
  const [deletedPhotosList, setDeletedPhotosList] = useState<any>([])
  const [deletedVideosList, setDeletedVideosList] = useState<any>([])
  const [newDocumentsList, setNewDocumentsList] = useState<any>([])
  const [newPhotosList, setNewPhotosList] = useState<any>([])
  const [newVideosList, setNewVideosList] = useState<any>([])

  const [allowEdit, setAllowEdit] = useState(false)
  const [updatedStandardFields, setUpdatedStandardFields] = useState<any>([])
  const [updatedStandardFieldIds, setUpdatedStandardFieldIds] = useState<any>([])
  const [updatedInspectionFields, setUpdatedInspectionFields] = useState<any>([])
  const [updatedInspectionFieldIds, setUpdatedInspectionFieldIds] = useState<any>([])

  const [comments, setComments] = useState<string>('')
  const [showMediaSlider, setShowMediaSlider] = useState<boolean>(false)
  const [startingInd, setStartingInd] = useState<any>(0)
  const [mediaData, setMediaData] = useState<any>([])
  const [mediaType, setMediaType] = useState<any>('')
  const [selectedDateInput, setSelectedDateInput] = useState<any>(null)
  const [checkReportAccordionOpen, setCheckReportAccordionOpen] = useState(false)
  const [imgLoading, setImgLoading] = useState<boolean>(false)
  const [vidLoading, setVidLoading] = useState<boolean>(false)
  const [docLoading, setDocLoading] = useState<boolean>(false)
  const { ...userPermissionsData } = useContext(UserPermissionsContext)
  const userPermissions = userPermissionsData?.data
  const [containersList, setContainersList, { refetch }] = useContext(ContainerListContext)
  const { assigneesList } = useAssigneeList();
  const [stageList, { ...stagesData }] = useContext(StageListContext)
  const [containerTypes, setContainerTypes] = useState<any>()
  const [stagesList, setStagesList] = useState<any>()
  const [containerTypeId, setContainerTypeId] = useState<any>()
  const [containerType, setContainerType] = useState<any>()
  const [isVideo, setIsVideo] = useState(false)
  const [showItemTypesPopup, setShowItemTypesPopup] = useState<boolean>(false)
  const [selectedItemType, setSelectedItemType] = useState<any>(null)
  const [stageName, setStageName] = useState<any>()
  const [stageId, setStageId] = useState<any>()
  const [docHoverId, setDocHoverId] = useState<any>(null)
  const [booleanTypes, setBooleanTypes] = useState<any>([
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ])

  const getBooleanVal = (val: any) => {
    if (val === true || val === 'true' || val === '1') {
      return 'Yes'
    } else if (val === false || val === 'false' || val === '0') {
      return 'No'
    } else {
      return ''
    }
  }

  const onEditClick = () => {
    setAllowEdit(true)
  }

  const onAddDocumentClick = (event: ChangeEvent<HTMLInputElement>) => {
    const orgId = userPermissions && userPermissions.data.org_uuid
    const documentsListCopy: any = [...data.documents]
    const tempArr = [...newDocumentsList]
    const documentsIdListCopy = [...documentsIdList]
    const numberOfExistingFiles = documentsListCopy.length
    event.target.files &&
      Array.from(event.target.files)
        .slice(0, 5 - numberOfExistingFiles)
        .forEach(async (file: any) => {
          if (file) {
            setDocLoading(true)
            const res: any = await uploadFile(file)
            const docURL = res.file_path || ''
            let tempId = 1
            let flag = true
            while (flag) {
              if (!documentsIdListCopy.includes(tempId)) {
                const newItem = { id: tempId, url: docURL, type: 'DOCUMENT' }
                documentsListCopy.push(newItem)
                tempArr.push(docURL)
                documentsIdListCopy.push(tempId)
                flag = false
              } else {
                tempId += 1
              }
            }
            setData({ ...data, documents: documentsListCopy })
            setNewDocumentsList(tempArr)
            setDocumentsIdList(documentsIdListCopy)
            setDocLoading(false)
            userPermissionsData.refetch()
          }
        })
  }

  const onAddImgClick = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files.length > 0) {
      setImgLoading(true)
      const tempArr = [...newPhotosList]
      const photosListCopy = [...data.photos]
      const newPhotoIdList = [...photosIdList]
      let tasks: Promise<any>[] = []
      for (const file of event.target.files) {
        tasks = tasks.concat(compressAndUploadImage(file))
      }
      const resArr: any[] = await Promise.all(tasks)
      for (const res of resArr) {
        const imgUrl = res.file_path || ''
        let tempId = 1
        let flag = true
        while (flag) {
          if (!newPhotoIdList.includes(tempId)) {
            const newItem = { id: tempId, url: imgUrl, type: 'PHOTO' }
            photosListCopy.push(newItem)
            tempArr.push({url:imgUrl, name: res.file_name})
            newPhotoIdList.push(tempId)
            flag = false
          } else {
            tempId += 1
          }
        }
      }
      setNewPhotosList(tempArr)
      setData({ ...data, photos: photosListCopy })
      setPhotosIdList(newPhotoIdList)
      setImgLoading(false)
    }
  }

  const onAddVideoClick = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setVidLoading(true)
      const tempArr = [...newVideosList]
      const uploadedVideosCopy = [...data.videos]
      const newVideosIdList = [...videosIdList]
      for (const file of event.target.files) {
        const res: any = await uploadFile(file)
        const videoURL = res.file_path || ''
        let tempId = 1
        let flag = true
        while (flag) {
          if (!newVideosIdList.includes(tempId)) {
            const newItem = { id: tempId, url: videoURL, type: 'VIDEO' }
            uploadedVideosCopy.push(newItem)
            tempArr.push(videoURL)
            newVideosIdList.push(tempId)
            flag = false
          } else {
            tempId += 1
          }
        }
      }
      setNewVideosList(tempArr)
      setData({ ...data, videos: uploadedVideosCopy })
      setVideosIdList(newVideosIdList)
      setVidLoading(false)
    }
  }

  const onDeleteUploadedDocuments = (id: any, url: any) => {
    const tempArr = [...deletedDocumentsList]
    tempArr.push(id)
    setDeletedDocumentsList(tempArr)

    const documentsListCopy: any = [...data.documents]
    data.documents.find((item: any, ind: any) => {
      if (item.id === id) {
        URL.revokeObjectURL(item.url)
        documentsListCopy.splice(ind, 1)
        setData({ ...data, documents: documentsListCopy })
      }
    })

    const documentsIdListCopy: any = [...documentsIdList]
    documentsIdList.find((item: any, ind: any) => {
      if (item.id === id) {
        documentsIdListCopy.splice(ind, 1)
        setDocumentsIdList(documentsIdListCopy)
      }
    })

    const newDocumentsListCopy: any = newDocumentsList
    if (newDocumentsListCopy.includes(url)) {
      newDocumentsListCopy.find((item: any, ind: any) => {
        if (item === url) {
          newDocumentsListCopy.splice(ind, 1)
          setNewDocumentsList(newDocumentsListCopy)
        }
      })
    }
  }

  const onDeleteUploadedPhoto = (id: any, url: any) => {
    const tempArr = [...deletedPhotosList]
    tempArr.push(id)
    setDeletedPhotosList(tempArr)

    const photosListCopy: any = [...data.photos]
    data.photos.find((item: any, ind: any) => {
      if (item.id === id) {
        URL.revokeObjectURL(item.url)
        photosListCopy.splice(ind, 1)
        setData({ ...data, photos: photosListCopy })
      }
    })

    const photosIdListCopy: any = [...photosIdList]
    photosIdList.find((item: any, ind: any) => {
      if (item.id === id) {
        photosIdListCopy.splice(ind, 1)
        setPhotosIdList(photosIdListCopy)
      }
    })

    const newPhotosListCopy: any = newPhotosList
    if (newPhotosListCopy.includes(url)) {
      newPhotosListCopy.find((item: any, ind: any) => {
        if (item === url) {
          newPhotosListCopy.splice(ind, 1)
          setNewPhotosList(newPhotosListCopy)
        }
      })
    }
  }

  const onDeleteUploadedVideo = (id: any, url: any) => {
    const tempArr = [...deletedVideosList]
    tempArr.push(id)
    setDeletedVideosList(tempArr)

    const videosListCopy: any = [...data.videos]
    data.videos.find((item: any, ind: any) => {
      if (item.id === id) {
        URL.revokeObjectURL(item.url)
        videosListCopy.splice(ind, 1)
        setData({ ...data, videos: videosListCopy })
      }
    })

    const videosIdListCopy: any = [...videosIdList]
    videosIdList.find((item: any, ind: any) => {
      if (item.id === id) {
        videosIdListCopy.splice(ind, 1)
        setVideosIdList(videosIdListCopy)
      }
    })

    const newVideosListCopy: any = newVideosList
    if (newVideosListCopy.includes(url)) {
      newVideosListCopy.find((item: any, ind: any) => {
        if (item === url) {
          newVideosListCopy.splice(ind, 1)
          setNewVideosList(newVideosListCopy)
        }
      })
    }
  }

  const onStandardFieldChange = (id: any, dataType: any, value: any) => {
    if (dataType.toLowerCase() === 'number') {
      if (numberInputValidation(value)) {
        const standardItemsListCopy: any = [...data.standard_fields]
        data.standard_fields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value
            standardItemsListCopy[ind] = tempElement
            setData({ ...data, standard_fields: standardItemsListCopy })
          }
        })
        if (updatedStandardFieldIds.includes(id)) {
          const updatedStandardFieldsCopy: any = [...updatedStandardFields]
          updatedStandardFields.find((item: any, ind: any) => {
            if (item.id === id) {
              const tempElement = { ...item }
              tempElement.value = value
              updatedStandardFieldsCopy[ind] = tempElement
              setUpdatedStandardFields(updatedStandardFieldsCopy)
            }
          })
        } else {
          const tempArr = [...updatedStandardFields]
          tempArr.push({
            id: id,
            value: value,
          })
          setUpdatedStandardFields(tempArr)
          const tempIdArr = [...updatedStandardFieldIds]
          tempIdArr.push(id)
          setUpdatedStandardFieldIds(tempIdArr)
        }
      }
    } else if (dataType.toLowerCase() === 'date') {
      const standardItemsListCopy: any = [...data.standard_fields]
      data.standard_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          value.setHours(12)
          tempElement.value = value.getTime()
          standardItemsListCopy[ind] = tempElement
          setData({ ...data, standard_fields: standardItemsListCopy })
        }
      })
      if (updatedStandardFieldIds.includes(id)) {
        const updatedStandardFieldsCopy: any = [...updatedStandardFields]
        updatedStandardFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            value.setHours(12)
            tempElement.value = value.getTime()
            updatedStandardFieldsCopy[ind] = tempElement
            setUpdatedStandardFields(updatedStandardFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedStandardFields]
        value.setHours(12)
        tempArr.push({
          id: id,
          value: value.getTime(),
        })
        setUpdatedStandardFields(tempArr)
        const tempIdArr = [...updatedStandardFieldIds]
        tempIdArr.push(id)
        setUpdatedStandardFieldIds(tempIdArr)
      }
      setSelectedDateInput(null)
    } else if (dataType.toLowerCase() === 'boolean') {
      setTempBoolVal(value)
      const standardItemsListCopy: any = [...data.standard_fields]
      data.standard_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = value === 'Yes' ? true : value === 'No' ? false : null
          standardItemsListCopy[ind] = tempElement
          setData({ ...data, standard_fields: standardItemsListCopy })
        }
      })
      if (updatedStandardFieldIds.includes(id)) {
        const updatedStandardFieldsCopy: any = [...updatedStandardFields]
        updatedStandardFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value === 'Yes' ? true : value === 'No' ? false : null
            updatedStandardFieldsCopy[ind] = tempElement
            setUpdatedStandardFields(updatedStandardFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedStandardFields]
        tempArr.push({
          id: id,
          value: value === 'Yes' ? true : value === 'No' ? false : null,
        })
        setUpdatedStandardFields(tempArr)
        const tempIdArr = [...updatedStandardFieldIds]
        tempIdArr.push(id)
        setUpdatedStandardFieldIds(tempIdArr)
      }
    } else {
      const standardItemsListCopy: any = [...data.standard_fields]
      data.standard_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = value
          standardItemsListCopy[ind] = tempElement
          setData({ ...data, standard_fields: standardItemsListCopy })
        }
      })
      if (updatedStandardFieldIds.includes(id)) {
        const updatedStandardFieldsCopy: any = [...updatedStandardFields]
        updatedStandardFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value
            updatedStandardFieldsCopy[ind] = tempElement
            setUpdatedStandardFields(updatedStandardFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedStandardFields]
        tempArr.push({
          id: id,
          value: value,
        })
        setUpdatedStandardFields(tempArr)
        const tempIdArr = [...updatedStandardFieldIds]
        tempIdArr.push(id)
        setUpdatedStandardFieldIds(tempIdArr)
      }
    }
  }

  const onInspectionFieldChange = (id: any, dataType: any, value: any) => {
    if (dataType.toLowerCase() === 'number') {
      if (numberInputValidation(value)) {
        const inspectionItemsListCopy: any = [...data.inspection_fields]
        data.inspection_fields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value
            inspectionItemsListCopy[ind] = tempElement
            setData({ ...data, inspection_fields: inspectionItemsListCopy })
          }
        })
        if (updatedInspectionFieldIds.includes(id)) {
          const updatedInspectionFieldsCopy: any = [...updatedInspectionFields]
          updatedInspectionFields.find((item: any, ind: any) => {
            if (item.id === id) {
              const tempElement = { ...item }
              tempElement.value = value
              updatedInspectionFieldsCopy[ind] = tempElement
              setUpdatedInspectionFields(updatedInspectionFieldsCopy)
            }
          })
        } else {
          const tempArr = [...updatedInspectionFields]
          tempArr.push({
            id: id,
            value: value,
          })
          setUpdatedInspectionFields(tempArr)
          const tempIdArr = [...updatedInspectionFieldIds]
          tempIdArr.push(id)
          setUpdatedInspectionFieldIds(tempIdArr)
        }
      }
    } else if (dataType.toLowerCase() === 'date') {
      const inspectionItemsListCopy: any = [...data.inspection_fields]
      data.inspection_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          value.setHours(12)
          tempElement.value = value.getTime()
          inspectionItemsListCopy[ind] = tempElement
          setData({ ...data, inspection_fields: inspectionItemsListCopy })
        }
      })
      if (updatedInspectionFieldIds.includes(id)) {
        const updatedInspectionFieldsCopy: any = [...updatedInspectionFields]
        updatedInspectionFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            value.setHours(12)
            tempElement.value = value.getTime()
            updatedInspectionFieldsCopy[ind] = tempElement
            setUpdatedInspectionFields(updatedInspectionFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedInspectionFields]
        value.setHours(12)
        tempArr.push({
          id: id,
          value: value.getTime(),
        })
        setUpdatedInspectionFields(tempArr)
        const tempIdArr = [...updatedInspectionFieldIds]
        tempIdArr.push(id)
        setUpdatedInspectionFieldIds(tempIdArr)
      }
      setSelectedDateInput(null)
    } else if (dataType.toLowerCase() === 'boolean') {
      setTempBoolVal(value)
      const inspectionItemsListCopy: any = [...data.inspection_fields]
      data.inspection_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = value === 'Yes' ? true : value === 'No' ? false : null
          inspectionItemsListCopy[ind] = tempElement
          setData({ ...data, inspection_fields: inspectionItemsListCopy })
        }
      })
      if (updatedInspectionFieldIds.includes(id)) {
        const updatedInspectionFieldsCopy: any = [...updatedInspectionFields]
        updatedInspectionFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value === 'Yes' ? true : value === 'No' ? false : null
            updatedInspectionFieldsCopy[ind] = tempElement
            setUpdatedInspectionFields(updatedInspectionFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedInspectionFields]
        tempArr.push({
          id: id,
          value: value === 'Yes' ? true : value === 'No' ? false : null,
        })
        setUpdatedInspectionFields(tempArr)
        const tempIdArr = [...updatedInspectionFieldIds]
        tempIdArr.push(id)
        setUpdatedInspectionFieldIds(tempIdArr)
      }
    } else {
      const inspectionItemsListCopy: any = [...data.inspection_fields]
      data.inspection_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = value
          inspectionItemsListCopy[ind] = tempElement
          setData({ ...data, inspection_fields: inspectionItemsListCopy })
        }
      })
      if (updatedInspectionFieldIds.includes(id)) {
        const updatedInspectionFieldsCopy: any = [...updatedInspectionFields]
        updatedInspectionFields.find((item: any, ind: any) => {
          if (item.id === id) {
            const tempElement = { ...item }
            tempElement.value = value
            updatedInspectionFieldsCopy[ind] = tempElement
            setUpdatedInspectionFields(updatedInspectionFieldsCopy)
          }
        })
      } else {
        const tempArr = [...updatedInspectionFields]
        tempArr.push({
          id: id,
          value: value,
        })
        setUpdatedInspectionFields(tempArr)
        const tempIdArr = [...updatedInspectionFieldIds]
        tempIdArr.push(id)
        setUpdatedInspectionFieldIds(tempIdArr)
      }
    }
  }

  const onItemDetailsChange = (key: any, dataType: any, value: any) => {
    if (dataType.toLowerCase() === 'file') {
      onItemImageChangeHandler(key, value)
    } else if (dataType.toLowerCase() === 'number') {
      if (numberInputValidation(value)) {
        const itemDetailsCopy: any = [...data.basic_fields]
        data.basic_fields.find((item: any, ind: any) => {
          if (item.key === key) {
            const tempElement = { ...item }
            tempElement.value = value
            itemDetailsCopy[ind] = tempElement
            setData({ ...data, basic_fields: itemDetailsCopy })
          }
        })
      }
    } else if (dataType.toLowerCase() === 'string' || dataType.toLowerCase() === 'text') {
      const itemDetailsCopy: any = [...data.basic_fields]
      data.basic_fields.find((item: any, ind: any) => {
        if (item.key === key) {
          const tempElement = { ...item }
          tempElement.value = value
          itemDetailsCopy[ind] = tempElement
          setData({ ...data, basic_fields: itemDetailsCopy })
        }
      })
    } else if (dataType.toLowerCase() === 'date') {
      const itemDetailsCopy: any = [...data.basic_fields]
      data.basic_fields.find((item: any, ind: any) => {
        if (item.key === key) {
          const tempElement = { ...item }
          value.setHours(12)
          tempElement.value = value.getTime()
          itemDetailsCopy[ind] = tempElement
          setData({ ...data, basic_fields: itemDetailsCopy })
        }
      })
      setSelectedDateInput(null)
    }
  }

  const itemId = useParams()?.id


  const onCreateCheckReport = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const token = localStorage.getItem('accessToken')
    showLoader(true)
    const data = {
      requestId: Date.now()+'_'+userPermissions.data.user_id+'_item_'+itemId
    }
    createItemCheckReport(itemId, token, data)
      .then((response: any) => {
        showLoader(false)
        getItemDetailsOnLoad()
        if (response.status === 201) {
          showSuccessToast()
        } else {
          showFailureToast()
        }
      })
      .catch((error: any) => {
        showLoader(false)
        alert('Something went wrong, please try again later!')
      })
  }

  const onSaveClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const token = localStorage.getItem('accessToken')
    const itemDetailsObj: any = {}
    data?.basic_fields.forEach((item: any) => {
      itemDetailsObj[item.key] = item.value
    })
    itemDetailsObj['id'] = itemId
    delete itemDetailsObj.item_type_id
    delete itemDetailsObj.container_db_id
    itemDetailsObj['container_id'] = containerTypeId
    itemDetailsObj['stage_id'] = stageId
    itemDetailsObj['inspection_comments'] = comments
    const newData = {
      deleted_photos: deletedPhotosList,
      deleted_videos: deletedVideosList,
      deleted_docs: deletedDocumentsList,
      docs: newDocumentsList,
      photos: newPhotosList,
      videos: newVideosList,
      markings: [],
      standard: updatedStandardFields,
      inspection: updatedInspectionFields,
      item_details: itemDetailsObj,
    }
    setNewDocumentsList([])
    setNewPhotosList([])
    setNewVideosList([])
    showLoader(true)
    editItemData(newData, token)
      .then(response => {
        showLoader(false)
        setAllowEdit(false)
        if (response.status === 204) {
          getItemDetailsOnLoad()
          showSuccessToast()
        } else {
          showFailureToast()
        }
      })
      .catch(error => {
        showLoader(false)
        console.log(error)
        alert('Something went wrong, please try again later!')
      })
  }

  const onDiscardChanges = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setNewDocumentsList([])
    setNewPhotosList([])
    setNewVideosList([])
    setAllowEdit(false)
    getItemDetailsOnLoad()
  }

  const getItemDetailsOnLoad = () => {
    showLoader(true)
    getItemDetail(itemId)
      .then(data => {
        showLoader(false)
        setData(data)
        setComments(data?.inspection_comments)
        if (data?.basic_fields) {
          data.basic_fields &&
            data.basic_fields.forEach((item: any) => {
              if (item.key === 'container_db_id') {
                setContainerType({value: item.value_label, label: item.value_label})
                setContainerTypeId(item.value)
              }
              if (item.key === 'stage_id') {
                const stage_name = item.value_label
                setStageName({ value: item.value, label: stage_name })
                setStageId(+item.value)
              }
            })
        }
      })
      .catch(error => {
        showLoader(false)
        console.log(error)
      })
  }

  const showCopiedToast = () => {
    toast('Check report link copied to clipboard!', {
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  useEffect(() => {
    refetch()
    stagesData.refetch()
    setStagesList(stageList)
    getItemDetailsOnLoad()
  }, [])

  useEffect(() => {
    if (containersList) {
      const tempArr: any = []
      containersList.forEach((item: any) => {
        const data = {
          value: item.id,
          label: item.title,
        }
        tempArr.push(data)
      })
      setContainerTypes(tempArr)
    }
    if (stageList?.data) {
      const tempStageArr: any = []
      stageList.data.forEach((item: any) => {
        const data = {
          value: item.id,
          label: item.name,
        }
        tempStageArr.push(data)
      })
      setStagesList(tempStageArr)
    }
  }, [containersList, stageList])

  const onMediaClick = (ind: any, type: string) => {
    if (type === 'video') {
      const index = data.photos.length + ind
      setStartingInd(index)
      setIsVideo(true)
    } else {
      setStartingInd(ind)
      setIsVideo(false)
    }
    setMediaData(data.photos.concat(data.videos))
    setMediaType('photosVideos')
    setShowMediaSlider(true)
  }

  const onMarkingClick = (ind: any) => {
    setStartingInd(ind)
    setMediaData(data.markings)
    setMediaType('markings')
    setShowMediaSlider(true)
  }

  const onDateInputFocus = (key: any) => {
    setSelectedDateInput(key)
  }

  const onExpandCheckReportClick = () => {
    if (checkReportAccordionOpen) {
      setCheckReportAccordionOpen(false)
    } else {
      setCheckReportAccordionOpen(true)
    }
  }

  const onItemImageChangeHandler = async (key: any, event: any) => {
    if (event.target.files[0]) {
      const res: any = await compressAndUploadImage(event.target.files[0])
      const imgUrl = res.file_path || ''
      const itemDetailsCopy: any = [...data.basic_fields]
      data.basic_fields.find((item: any, ind: any) => {
        if (item.key === key) {
          const tempElement = { ...item }
          tempElement.value = imgUrl
          itemDetailsCopy[ind] = tempElement
          setData({ ...data, basic_fields: itemDetailsCopy })
        }
      })
    }
  }

  const showSuccessToast = () => {
    toast.success(allowEdit ? 'Item details saved!' : 'Item check report created!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error(allowEdit ? 'An error occurred while saving item details!' : 'An error occurred while creating check report!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const override = css`
    display: block;
    margin: 0 auto;
  `

  const getContainerTypeId = (label: any) => {
    let val = null
    if (containerTypes) {
      containerTypes.forEach((item: any) => {
        if (item.label === label) {
          val = item.value
        }
      })
    }
    return val
  }

  const getStageName = (id: any) => {
    id = parseInt(id)
    let val = null
    if (stagesList) {
      stagesList.forEach((item: any) => {
        if (item.value === id) {
          val = item.label
        }
      })
    }
    return val
  }

  const getStageId = (label: any) => {
    let val = null
    if (stagesList) {
      stagesList.forEach((item: any) => {
        if (item.label === label) {
          val = item.value
        }
      })
    }
    return val
  }

  const onContainerTypeChange = (val: any) => {
    setContainerType(val)
    setTempContainerType(val.label)
    setContainerTypeId(val.value)
  }

  const [tempContainerType, setTempContainerType] = useState<any>('')

  const onStageNameChange = (val: any) => {
    setStageName(val)
    setTempStageName(val.label)
    setStageId(val.value)
  }

  const [tempStageName, setTempStageName] = useState<any>('')

  const [tempBoolVal, setTempBoolVal] = useState<any>(null)
  const onBooleanDropdownFocus = (val: any, id: any, fieldType: any) => {
    setTempBoolVal(val)
    if (fieldType === 'inspection') {
      const inspectionItemsListCopy: any = [...data.inspection_fields]
      data.inspection_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = ''
          inspectionItemsListCopy[ind] = tempElement
          setData({ ...data, inspection_fields: inspectionItemsListCopy })
        }
      })
    } else if (fieldType === 'standard') {
      const standardItemsListCopy: any = [...data.standard_fields]
      data.standard_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = ''
          standardItemsListCopy[ind] = tempElement
          setData({ ...data, standard_fields: standardItemsListCopy })
        }
      })
    }
  }

  const onBooleanDropdownBlur = (id: any, fieldType: any) => {
    if (fieldType === 'inspection') {
      const inspectionItemsListCopy: any = [...data.inspection_fields]
      data.inspection_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = tempBoolVal === 'Yes' ? true : tempBoolVal === 'No' ? false : null
          inspectionItemsListCopy[ind] = tempElement
          setData({ ...data, inspection_fields: inspectionItemsListCopy })
        }
      })
    } else if (fieldType === 'standard') {
      const standardItemsListCopy: any = [...data.standard_fields]
      data.standard_fields.find((item: any, ind: any) => {
        if (item.id === id) {
          const tempElement = { ...item }
          tempElement.value = tempBoolVal === 'Yes' ? true : tempBoolVal === 'No' ? false : null
          standardItemsListCopy[ind] = tempElement
          setData({ ...data, standard_fields: standardItemsListCopy })
        }
      })
    }
  }

  const onEditItemType = (itemType: any) => {
    setSelectedItemType(itemType)
    setShowItemTypesPopup(true)
  }

  const onDocHover = (id: any) => {
    setDocHoverId(id)
  }

  useEffect(() => {
    if (allowEdit) {
      window.scrollTo({
        top: 1,
        behavior: 'smooth',
      })
    }
  }, [allowEdit])

  const toggleArchivedMutation = useMutation(
    () => {
      if (!itemId) return Promise.reject({})

      if (data.is_archived) {
        return unarchiveItem(itemId)
      } else {
        return archiveItem(itemId)
      }
    },
    {
      onSuccess: response => {
        if (response.data.message === 'success') {
          setData({ ...data, is_archived: !data.is_archived })
          getItemDetail(itemId).then(data => {
            setData(data)
          })
        }
      },
    }
  )

  const updateAssignee = (response: any) => {
   assigneesList?.map((assignee: any) => {
        if (assignee.id === JSON.parse(response.config.data).user_id) {
          setData({...data, assignee_id: assignee.id, assignee_name: assignee.full_name });
          return;
        }
    });
  }

  const toggleArchived = async () => {
    toggleArchivedMutation.mutate()
  }

  const { data: { markings } = {} } = useQuery(['markingsList'], () => getMarkings())

  if (!data && !loading) return null

  return (
    <>
      {loading && <Loader />}
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />

      {showItemTypesPopup && (
        <EditItemTypePopup
          dismissHandler={() => setShowItemTypesPopup(false)}
          selectedItemType={selectedItemType}
          itemId={itemId}
          refresh={() => getItemDetailsOnLoad()}
          showLoader={showLoader}
        />
      )}

      {showMediaSlider && (
        <MediaSlider
          dismissHandler={() => setShowMediaSlider(false)}
          mediaData={mediaData}
          startingInd={startingInd}
          mediaType={mediaType}
          isVideo={isVideo}
        />
      )}



      <div className='sub-tab-content-container no_bottom_padding'>
        <div className='display_flex add-member-header-container'>
          <Link className='selected-tab-page-title cursor_pointer' to='..'>
            Items
          </Link>
          <img src={arrowRight} className='arrow-right-icon' alt='' crossOrigin='anonymous' />
          <p className='sub-tab-selected-title'>Item Details</p>
        </div>
        <p className='tabs-page-title-bottom-border' />

        <div className='display_flex add-new-item-outermost-container'>
          <div className='pr-4 add-new-item-left-col-container'>
            <div className='display_flex add-new-item-left-col-title-container'>
              <p className='add-new-item-left-col-title'>ITEM DETAILS</p>

              <Flex>
                {!allowEdit && (
                  <div className={'edit-data-btn'}>
                    <Button
                      btnStyle='textOnlyBtn'
                      textColor={data?.is_archived ? 'var(--colors-rich_moss)' : 'var(--colors-emory_orange)'}
                      textDecoration={'underline'}
                      width='auto'
                      padding='0.5rem 0'
                      onClick={toggleArchived}
                      disabled={toggleArchivedMutation.isLoading}
                    >
                      {!data?.is_archived ? 'Archive' : 'Unarchive'}
                    </Button>
                  </div>
                )}
                {!data?.is_archived && (
                  <div className={!allowEdit ? 'edit-data-btn' : 'edit-data-btn reduced_opacity_4'}>
                    <Button
                      btnStyle='textOnlyBtn'
                      textColor='#000000'
                      width='auto'
                      textDecoration={!allowEdit && 'underline'}
                      padding='0.5rem 0'
                      onClick={onEditClick}
                      className={allowEdit && 'cursor_default'}
                    >
                      {!allowEdit ? t('edit') : t('inEditMode')}
                    </Button>
                  </div>
                )}
              </Flex>
            </div>
            {data?.basic_fields &&
              data.basic_fields.length > 0 &&
              data.basic_fields.map((item: any) => {
                if (item.key === 'container_db_id' && userPermissions && !userPermissions.data.manage_container) {
                  return null
                }
                return (
                  <>
                    {item.key !== 'assignee_signature' && (
                      <div className='display_flex add-new-item-field-container' id={item.key} key={item.key}>
                        <p className='add-new-item-field-name'>{item.field_name}</p>
                        {item.key === 'image' ? (
                          <div
                            className={
                              !allowEdit
                                ? 'add-logo-field-container item-details-image-outer-container'
                                : 'add-logo-field-container item-details-image-outer-container-edit-mode'
                            }
                          >
                            <input
                              type='file'
                              className='logo-upload-input'
                              disabled={!allowEdit}
                              accept={APP_CONSTANTS.ACCEPTED_IMAGE_EXTENSIONS}
                              onChange={e => onItemDetailsChange(item.key, item.data_type_key, e)}
                            />
                            <object
                              className='wide-brand-logo-img cursor_pointer'
                              data={getThumbnail(item.value)}
                              type='image/png'
                            >
                              <img className='wide-brand-logo-img cursor_pointer' loading='lazy' src={item.value} alt=''
                                   crossOrigin='anonymous' />
                            </object>
                          </div>
                        ) : allowEdit && item.key !== 'item_type_id' ? (
                          item.key === 'container_db_id' ? (
                            userPermissions &&
                            userPermissions.data.manage_container && (
                              <div className='row-left-field-container' style={{ width: '272px', minWidth: '180px' }}>
                                <Select
                                  value={containerType}
                                  onChange={onContainerTypeChange}
                                  options={containerTypes}
                                />
                              </div>
                            )
                          ) : item.key === 'stage_id' ? (
                            <div className='row-left-field-container' style={{ width: '272px', minWidth: '180px' }}>
                              <Select
                                value={stageName}
                                onChange={onStageNameChange}
                                options={stagesList}
                              />
                            </div>
                          ) : allowEdit && item.data_type && item.data_type.toLowerCase() === 'date' ? (
                            <DatePicker
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode='select'
                              className='edit-details-date-picker-input width_adjustment'
                              dateFormat='dd MMM yyyy'
                              selected={item.value && new Date(parseInt(item.value))}
                              onChange={(val: any) => onItemDetailsChange(item.key, item.data_type_key, val)}
                              placeholderText='Select Date'
                            />
                          ) : item.key === 'description' ? (
                            <textarea
                              disabled={!allowEdit}
                              value={
                                item.value ?  item.value : ''
                              }
                              className={!allowEdit ? 'aaa add-new-item-field-value' : 'aaa add-new-item-field-value show-input-bg width_adjustment'}
                              onChange={e => onItemDetailsChange(item.key, item.data_type_key, e.target.value)}
                            ></textarea>
                          ) : (
                            <input
                              disabled={!allowEdit}
                              value={
                                item.value
                                  ? item.data_type && item.data_type.toLowerCase() === 'date'
                                    ? formatDate(item.value)
                                    : item.value
                                  : ''
                              }
                              className={!allowEdit ? 'add-new-item-field-value' : 'add-new-item-field-value show-input-bg width_adjustment'}
                              onChange={e => onItemDetailsChange(item.key, item.data_type_key, e.target.value)}
                              onFocus={
                                allowEdit && item.data_type && item.data_type.toLowerCase() === 'date'
                                  ? () => onDateInputFocus(item.key)
                                  : undefined
                              }
                            />
                          )
                        ) : item.key === 'item_type_id' ? (
                          <p className='add-new-item-field-value add-new-item-disabled-field-value'>
                            {item.value_label
                              ? item.value_label
                              : '--'}
                            <span>
                              <img
                                src={editIcon}
                                className='cursor_pointer upload-item-layout-edit-icon item-type-edit-icon'
                                onClick={() => onEditItemType(+item.value)}
                                alt=''
                                crossOrigin='anonymous'
                              />
                            </span>
                          </p>
                        ) : (
                          <p className='enable-new-lines add-new-item-field-value add-new-item-disabled-field-value'>
                            {item.data_type && item.data_type.toLowerCase() === 'date'
                              ? formatDate(item.value)
                              : item.key === 'stage_id'
                              ? (stageName?.label || '')
                              : item.key === 'container_db_id'
                              ? (containerType?.label || '')
                              : item.value ? item.value
                              : ''
                            }
                          </p>
                        )}
                      </div>
                    )}
                  </>
                )
              })}

            {data?.standard_fields &&
              data.standard_fields.length > 0 &&
              data.standard_fields.map((item: any) => {
                return (
                  <>
                    <div className='display_flex add-new-item-field-container' id={item.id} key={item.id}>
                      <p className='add-new-item-field-name'>{item.field_name}</p>
                      {allowEdit ? (
                        item.data_type.toLowerCase() === 'boolean' ? (
                          <div className='row-left-field-container width_280 margin_adjustment'>
                            <DropdownSelect
                              placeholder=''
                              options={[{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]}
                              inputClassName="add-new-item-field-value upload-item-layout-front-view-text show-input-bg"
                              onChange={(val: any) => onStandardFieldChange(item.id, item.data_type_key, val)}
                              value={getBooleanVal(item.value)}
                              defaultValue={getBooleanVal(item.value)}
                              index={item.id}
                            />
                          </div>
                        ) : item.data_type && item.data_type.toLowerCase() === 'date' ? (
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode='select'
                            className='edit-details-date-picker-input width_adjustment'
                            dateFormat='dd MMM yyyy'
                            selected={item.value && new Date(parseInt(item.value))}
                            onChange={(val: any) => onStandardFieldChange(item.id, item.data_type, val)}
                            placeholderText='Select Date'
                          />
                        ) : (
                          <input
                            disabled={!allowEdit}
                            value={
                              item.value
                                ? item.data_type && item.data_type.toLowerCase() === 'date'
                                  ? formatDate(item.value)
                                  : item.value
                                : ''
                            }
                            className={!allowEdit ? 'add-new-item-field-value' : 'add-new-item-field-value show-input-bg width_adjustment'}
                            onChange={e => onStandardFieldChange(item.id, item.data_type, e.target.value)}
                            onFocus={
                              allowEdit && item.data_type && item.data_type.toLowerCase() === 'date'
                                ? () => onDateInputFocus(item.id)
                                : undefined
                            }
                          />
                        )
                      ) : (
                        <p className='add-new-item-field-value add-new-item-disabled-field-value'>
                          {item.value && item.data_type && item.data_type.toLowerCase() === 'date'
                              ? formatDate(item.value)
                              : item.data_type.toLowerCase() === 'boolean'
                              ? getBooleanVal(item.value)
                              : item.value ? item.value
                            : ''}
                        </p>
                      )}
                    </div>
                    {/* {allowEdit && selectedDateInput === item.id && item.data_type && item.data_type.toLowerCase() === 'date' &&
                                        <Calendar
                                        onChange={(val:any) => onStandardFieldChange(item.id, item.data_type, val)}
                                        value={item.value && new Date(parseInt(item.value))}
                                        />
                                    } */}
                  </>
                )
              })}
            {data?.container_id && data.container_id >= 0 && userPermissions && userPermissions.data.manage_container ? (
              <div className='flex flex-col items-start justify-start w-full gap-2'>
                <p className='text-base font-bold uppercase text-rich_moss'>Container</p>
                <ItemCard
                  url={`/dashboard/containers/${data.container_id}`}
                  title={data.container_title}
                  externalId={data.external_container_id}
                  status={data.container_status}
                  image={data.container_image || data.container_default_img}
                />
              </div>
            ) : null}
          </div>

          {!data?.is_archived && (
            <div className='add-new-item-right-col-container'>
              <div className='add-new-item-right-col-content-container'>
                {data?.check_reports && data.check_reports.length > 0 && (<ShareCheckReport object={data} objectType={'item'}/>)}

                {data?.item_status && data.item_status.toLowerCase() === 'submitted for review' && (
                  <div className='inspection-pending-msg-container'>
                    <p className='inspection-pending-msg-title'>INSPECTION PENDING</p>
                    <p className='inspection-pending-msg'>
                      Check Report will be generated after the assigned field agent completes an inspection or submits it for a review.
                    </p>
                  </div>
                )}

                <p className='add-new-item-left-col-title'>ASSIGNED TO</p>
                <div className='display_flex assigned-to-container'>
                  <p className='assigned-to-agent-title'>Assign To Field Agent</p>
                  <div className='display_flex'>
                    {data && data.assignee_name && (
                      <img src={assignedToIcon} className='assigned-to-person-icon' crossOrigin='anonymous' alt='' />
                    )}
                    <p className='assigned-to-person-name'>{data?.assignee_name}</p>
                  </div>


                  <AssignItemsPopup
                    idList={[data?.id]}
                    onAssign = {updateAssignee}
                    >
                      <p className='reassign-btn-text'>
                        {data && data.assignee_name ? 'Re-assign' : 'Assign'}
                      </p>
                  </AssignItemsPopup>
                </div>

                <div className='display_flex add-new-item-left-col-title-container'>
                  <p className='add-new-item-left-col-title'>REVIEW INSPECTION DETAILS</p>
                  {!data?.is_archived && (
                    <div className={!allowEdit ? 'edit-data-btn' : 'edit-data-btn reduced_opacity_4'}>
                      <Button
                        btnStyle='textOnlyBtn'
                        textColor='#000000'
                        width='auto'
                        textDecoration={!allowEdit && 'underline'}
                        padding='0.5rem 0'
                        onClick={onEditClick}
                        className={allowEdit && 'cursor_default'}
                      >
                        {!allowEdit ? t('edit') : t('inEditMode')}
                      </Button>
                    </div>
                  )}
                </div>

                {data?.inspection_fields &&
                  data.inspection_fields.length > 0 &&
                  data.inspection_fields.map((item: any) => {
                    return (
                      <>
                        <div className='display_flex add-new-item-field-container' id={item.id} key={item.id}>
                          <p className='add-new-item-field-name'>{item.field_name}</p>
                          {allowEdit ? (
                            item.data_type.toLowerCase() === 'boolean' ? (
                              <div className='row-left-field-container width_280 margin_adjustment'>
                                <DropdownSelect
                                  placeholder=''
                                  options={[{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]}
                                  inputClassName="add-new-item-field-value upload-item-layout-front-view-text show-input-bg"
                                  onChange={(val: any) => onInspectionFieldChange(item.id, item.data_type_key, val)}
                                  value={getBooleanVal(item.value)}
                                  defaultValue={getBooleanVal(item.value)}
                                  index={item.id}
                                />
                              </div>
                            ) : item.data_type && item.data_type.toLowerCase() === 'date' ? (
                              <DatePicker
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode='select'
                                className='edit-details-date-picker-input width_adjustment'
                                dateFormat='dd MMM yyyy'
                                selected={
                                  item.value && new Date(parseInt(item.value))
                                }
                                onChange={(val: any) => onInspectionFieldChange(item.id, item.data_type, val)}
                                placeholderText='Select Date'
                              />
                            ) : (
                              <input
                                disabled={!allowEdit}
                                value={
                                  item.value
                                    ? item.data_type && item.data_type.toLowerCase() === 'date'
                                      ? formatDate(item.value)
                                      : item.value
                                    : ''
                                }
                                className={!allowEdit ? 'add-new-item-field-value' : 'add-new-item-field-value show-input-bg width_adjustment'}
                                onChange={e => onInspectionFieldChange(item.id, item.data_type, e.target.value)}
                                onFocus={
                                  allowEdit && item.data_type && item.data_type.toLowerCase() === 'date'
                                    ? () => onDateInputFocus(item.id)
                                    : undefined
                                }
                              />
                            )
                          ) : (
                            <p className='add-new-item-field-value add-new-item-disabled-field-value'>
                              {item.value && item.data_type && item.data_type.toLowerCase() === 'date'
                                  ? formatDate(item.value)
                                  : item.data_type.toLowerCase() === 'boolean'
                                  ? getBooleanVal(item.value)
                                  : item.value ? item.value
                                : ''}
                            </p>
                          )}
                        </div>
                        {/* {allowEdit && selectedDateInput === item.id && item.data_type && item.data_type.toLowerCase() === 'date' &&
                                        <Calendar
                                        onChange={(val:any) => onInspectionFieldChange(item.id, item.data_type, val)}
                                        value={item.value && new Date(parseInt(item.value))}
                                        />
                                    } */}
                      </>
                    )
                  })}

                {/*  ---- MARKINGS ----- */}
                {!data?.is_archived && data?.markings && data.markings.length > 0 && (
                  <>
                    <div className='display_flex add-new-item-left-col-title-container'>
                      <p className='add-new-item-left-col-title'>MARKINGS</p>
                      <div className={!allowEdit ? 'edit-data-btn' : 'edit-data-btn reduced_opacity_4'}>
                        <Button
                          btnStyle='textOnlyBtn'
                          textColor='#000000'
                          width='auto'
                          textDecoration={!allowEdit && 'underline'}
                          padding='0.5rem 0'
                          onClick={onEditClick}
                          className={allowEdit && 'cursor_default'}
                        >
                          {!allowEdit ? t('edit') : t('inEditMode')}
                        </Button>
                      </div>
                    </div>
                    <Flex justify='center' align='center' wrap='wrap' css={{ gap: 16, py: 16 }}>
                      {markings?.map((marking: any) => (
                        <div key={marking.id} className='display_flex legend-item-container'>
                          <div className='marking-legend-color-container' style={{ backgroundColor: `#${marking.color}` }}></div>
                          <p className='marking-legend-text'>
                            {JSON.parse(marking.text_i18n)[i18n.language] || marking.default_text}
                          </p>
                        </div>
                      ))}
                    </Flex>

                    <Swiper spaceBetween={10} slidesPerView={1} modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }}>
                      {data.markings.map((marking: any, ind: any) => {
                        return (
                          <SwiperSlide key={marking.id} className='markings-swiper-slide'>
                            <div className='item-markimgs-img-container'>
                              <p className='markings-views-text'>{marking.name}</p>
                              <object
                                data={marking.marked_image ? marking.marked_image : marking.image}
                                className='add-new-item-markings-img cursor_pointer'
                                onClick={() => onMarkingClick(ind)}
                                type='image/png'
                              >
                                <img className='add-new-item-markings-img cursor_pointer' loading='lazy' src={marking.marked_image ?
                                  marking.marked_image : marking.image} alt='' crossOrigin='anonymous' />
                              </object>
                            </div>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </>
                )}

                {/* ---- custom markings -----  */}
                {data?.custom_markings && data?.custom_markings.length > 0 && (
                  <>
                    <p className='add-new-item-left-col-title'>{t('customMarkings')}</p>
                    <ul className='add-new-item-field-value add-new-item-disabled-field-value'>
                      {data?.custom_markings?.map((marking: string) => (
                        <li>{marking}</li>
                      ))}
                    </ul>
                  </>
                )}

                <p className='add-new-item-left-col-title'>UPLOADED DOCUMENTS (max 5)</p>
                <div className='display_flex uploaded-data-container'>
                  {data?.documents &&
                    data.documents.length > 0 &&
                    data.documents.map((item: any, index: number) => {
                      const fileExtension = item.url.split('.').pop().toLowerCase();
                      const isImage = ['jpg', 'jpeg', 'png'].includes(fileExtension);
                      const isVideo = ['mp4', 'mov', 'MOV'].includes(fileExtension);
                      return (
                        <div className='add-new-item-upload-photos-single-photo-container' key={item.id}>
                          <div
                            className='add-logo-field-container add-new-item-type-img-upload-field-container'
                            onMouseOver={() => onDocHover(item.id)}
                            onPointerLeave={() => setDocHoverId(null)}
                          >
                            {item.url && (
                              <>
                                <div className='item-index'>{index + 1}</div>
                                <input type='file' className='logo-upload-input' disabled={true} title={item.url} />
                                <div className='uploaded-data-img-container' style={{ position: 'inherit' }}>
                                {isImage ? (
                                    <object
                                      className='item-details-img-style cursor_pointer position_relative'
                                      onClick={() => onMediaClick(index, 'photo')}
                                      data={getThumbnail(item.url)}
                                      type='image/png'
                                    >
                                      <img className='item-details-img-style' loading='lazy' src={item.url} alt='' crossOrigin='anonymous' />
                                    </object>
                                  ) : isVideo ? (
                                    <div className='uploaded-data-img-container uploaded-video-container' style={{ position: 'relative' }}>
                                      <video
                                        width='220'
                                        height='240'
                                        className='cursor_pointer position_relative'
                                        src={item.url}
                                        onClick={() => onMediaClick(index, 'video')}
                                      />
                                      {/* Overlay for video */}
                                      <div
                                        className='video-overlay'
                                        style={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          width: '100%',
                                          height: '100%',
                                          backgroundColor: 'rgba(0, 0, 0, 0.4)', // semi-transparent dark overlay
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          pointerEvents: 'none', // so it doesn't block clicks
                                        }}
                                      >
                                        <FaPlayCircle
                                          style={{
                                            fontSize: '3rem',
                                            color: 'white',
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : item.url && item.url.slice(-4).toLowerCase() === '.csv' ? (
                                    <FaFileCsv style={{ fontSize: '3.5rem' }} />
                                  ) : item.url.slice(-5).toLowerCase() === '.json' ? (
                                    <BsFiletypeJson style={{ fontSize: '3.5rem' }} />
                                  ) : item.url.slice(-4).toLowerCase() === '.pdf' ? (
                                    <BsFilePdf className='h-14 w-14 text-strong_graphite' />
                                  ) : 
                                    <FaFileAlt style={{ fontSize: '3.5rem' }} />
                                  }
                                </div>

                                {docHoverId === item.id && (
                                  <div className='open-doc-link-container'>
                                    <p
                                      style={{
                                        fontSize: '12px',
                                        margin: 'auto',
                                        overflowWrap: 'break-word',
                                      }}
                                    >
                                      <a href={item.url} target='_blank' style={{ color: 'white' }} rel='noreferrer'>
                                        Open in new tab
                                      </a>
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <p
                            style={{
                              position: 'inherit',
                              fontSize: '12px',
                              margin: 'auto',
                              overflowWrap: 'break-word',
                              textAlign: 'center',
                            }}
                          >
                            ...{(item.file_display_name || item.url).substr((item.file_display_name || item.url).length - 10)}
                          </p>

                          {allowEdit && (
                            <div className='uploaded-data-delete-icon-container'>
                              <img
                                src={deleteIcon}
                                className='uploaded-data-delete-icon'
                                onClick={() => onDeleteUploadedDocuments(item.id, item.url)}
                                crossOrigin='anonymous'
                                alt=''
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  {allowEdit && data?.documents.length <= 4 && (
                    <div className='add-new-item-upload-photos-single-photo-container'>
                      <div className='add-logo-field-container add-new-item-type-img-upload-field-container'>
                        <input
                          type='file'
                          className='logo-upload-input'
                          onChange={e => onAddDocumentClick(e)}
                          onClick={(e: any) => {
                            e.target.value = null
                          }}
                          disabled={!allowEdit}
                          multiple
                          accept='.csv, .pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain'
                        />
                        <div className='upload-item-img-camera-icon-container'>
                          {docLoading ? (
                            <ClipLoader color={variables.digitalMint} loading={docLoading} css={override} size={18} />
                          ) : (
                            <img src={uploadIcon} className='' crossOrigin='anonymous' alt='' />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!allowEdit && (!data?.documents || data.documents.length === 0) && (
                    <p className='no-docs-txt'>No Documents found. Please click on Edit to add Documents</p>
                  )}
                </div>

                <p className='add-new-item-left-col-title'>UPLOADED PHOTOS</p>
                <div className='display_flex uploaded-data-container'>
                  {data?.photos &&
                    data.photos.length > 0 &&
                    data.photos.map((item: any, ind: any) => {
                      return (
                        <div className='add-new-item-upload-photos-single-photo-container' key={item.id}>
                          <div className='add-logo-field-container add-new-item-type-img-upload-field-container'>
                            {item.url && (
                              <>
                                <div className='item-index'>{ind + 1}</div>
                                <input
                                  type='file'
                                  className='logo-upload-input'
                                  disabled={true}
                                  accept={APP_CONSTANTS.ACCEPTED_IMAGE_EXTENSIONS}
                                  title={item.url}
                                />
                                <div className='uploaded-data-img-container'>
                                  <object
                                    className='item-details-img-style cursor_pointer position_relative'
                                    onClick={() => onMediaClick(ind, 'photo')}
                                    data={getThumbnail(item.url)}
                                    type='image/png'
                                  >
                                    <img className='item-details-img-style' loading='lazy' src={item.url} alt='' crossOrigin='anonymous' />
                                  </object>
                                </div>
                              </>
                            )}
                          </div>
                          {allowEdit && (
                            <div className='uploaded-data-delete-icon-container'>
                              <img
                                src={deleteIcon}
                                className='uploaded-data-delete-icon'
                                onClick={() => onDeleteUploadedPhoto(item.id, item.url)}
                                crossOrigin='anonymous'
                                alt=''
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  {allowEdit && (
                    <div className='add-new-item-upload-photos-single-photo-container'>
                      <div className='add-logo-field-container add-new-item-type-img-upload-field-container'>
                        <input
                          type='file'
                          className='logo-upload-input'
                          onChange={onAddImgClick}
                          disabled={!allowEdit}
                          accept={APP_CONSTANTS.ACCEPTED_IMAGE_EXTENSIONS}
                          onClick={(e: any) => {
                            e.target.value = null
                          }}
                          multiple
                        />
                        <div className='upload-item-img-camera-icon-container'>
                          {imgLoading ? (
                            <ClipLoader color={variables.digitalMint} loading={imgLoading} css={override} size={18} />
                          ) : (
                            <img src={uploadIcon} className='' crossOrigin='anonymous' alt='' />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!allowEdit && (!data?.photos || data.photos.length === 0) && (
                    <p className='no-docs-txt'>No Photos found. Please click on Edit to add Photos</p>
                  )}
                </div>

                <p className='add-new-item-left-col-title'>UPLOADED VIDEO</p>
                <div className='display_flex uploaded-data-container'>
                  {data?.videos &&
                    data.videos.length > 0 &&
                    data.videos.map((item: any, ind: any) => {
                      return (
                        <div className='add-new-item-upload-photos-single-photo-container' key={item.id}>
                          <div className='add-logo-field-container add-new-item-type-img-upload-field-container'>
                            {item.url && (
                              <>
                                <div className='item-index'>{ind + 1}</div>
                                <input type='file' className='logo-upload-input' disabled={true} title={item.url} accept='video/*' />
                                <div className='uploaded-data-img-container uploaded-video-container'>
                                  <video
                                    width='220'
                                    height='240'
                                    className='cursor_pointer position_relative'
                                    src={item.url}
                                    onClick={() => onMediaClick(ind, 'video')}
                                  />
                                  {/* <p className="uploaded-doc-filename-text">{item.url}</p> */}
                                </div>
                              </>
                            )}
                          </div>
                          {allowEdit && (
                            <div className='uploaded-data-delete-icon-container'>
                              <img
                                src={deleteIcon}
                                className='uploaded-data-delete-icon'
                                onClick={() => onDeleteUploadedVideo(item.id, item.url)}
                                alt=''
                                crossOrigin='anonymous'
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  {allowEdit && (
                    <div className='add-new-item-upload-photos-single-photo-container'>
                      <div className='add-logo-field-container add-new-item-type-img-upload-field-container'>
                        <input
                          type='file'
                          className='logo-upload-input'
                          onChange={onAddVideoClick}
                          disabled={!allowEdit}
                          accept='video/*'
                          multiple
                          onClick={(e: any) => {
                            e.target.value = null
                          }}
                        />
                        <div className='upload-item-img-camera-icon-container'>
                          {vidLoading ? (
                            <ClipLoader color={variables.digitalMint} loading={vidLoading} css={override} size={18} />
                          ) : (
                            <img src={uploadIcon} className='' crossOrigin='anonymous' alt='' />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!allowEdit && (!data?.videos || data.videos.length === 0) && (
                    <p className='no-docs-txt'>No Videos found. Please click on Edit to add Videos</p>
                  )}
                </div>

                <p className='add-new-item-left-col-title'>COMMENTS</p>
                <p className='add-new-item-comments-text'>
                  {allowEdit ? (
                    <InputField
                      value={comments ? comments : ''}
                      error={false}
                      onChange={(e: any) => setComments(e)}
                      textarea={true}
                      disabled={!allowEdit}
                      autoSize
                      className='member-address-textarea'
                    />
                  ) : (
                    <InputField
                      value={comments ? comments : ''}
                      autoSize={true}
                      disabled
                      textarea={true}
                      className='member-address-textarea'
                    />
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
        {!data?.is_archived && (
          <div className='display_flex company-details-bottom-btn-inner-container add-new-item-check-report-bottom-btn-container company-details-bottom-btn-container'>
            {allowEdit ? (
              <Button
                backgroundColor='#EEEEEE'
                textColor='#333333'
                padding='0.75rem 2rem'
                fontSize='12px'
                height='fit-content'
                width='auto'
                onClick={onDiscardChanges}
              >
                {allowEdit ? 'Cancel' : 'Discard Submission'}
              </Button>
            ) : null}
            <Button
              padding='0.75rem 2rem'
              fontSize='12px'
              height='fit-content'
              width='auto'
              leftMargin='1.25rem'
              onClick={allowEdit ? onSaveClick : onCreateCheckReport}
            >
              {allowEdit
                ? 'Save Changes'
                : data?.check_reports && data.check_reports.length > 0
                ? 'Create New Version of this Check Report'
                : 'Create Check Report'}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default EditItem
