import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getItemTypes } from '../../../../api/apiCalls'
import { DataTable, TableBody, TableFooter, TableHead, Td, Th, Toolbar, Tr, useDataTable } from '../../../../components/dataTable/DataTable'
import Flex from '../../../../components/flex/Flex'
import Loader from '../../../../components/Loader/loader'
import { SearchBar, SearchContainer } from '../../../../components/searchbar/SearchBar'
import Button from '../../../../components/Button/button'
import { useBreadCrumbs } from '../../../../contexts/breadcrumbs'

function ItemTypesList() {
  const { search, offset, itemsPerPage, sortBy, order, setUrlParam } = useDataTable()

  const { isLoading, data } = useQuery(['itemTypes', search, 'show_inactive'], () => getItemTypes('', search, true), {
    enabled: localStorage.getItem('loggedIn') === 'true' ? true : false,
  })

  const itemTypes = useMemo(() => {
    if (sortBy) {
      return (
        data?.item_types
          .slice()
          .sort((a: any, b: any) => (order === 'asc' ? -1 : 1) * (a[sortBy] < b[sortBy] ? 1 : -1))
          .slice(offset, offset + itemsPerPage) || []
      )
    }

    return data?.item_types.slice(offset, offset + itemsPerPage) || []
  }, [data, offset, itemsPerPage, sortBy, order])

  const handlePageChange = useCallback(
    (selectedItem: { selected: number }) => {
      const newOffset = (selectedItem.selected * itemsPerPage) % data?.item_types.length
      setUrlParam('offset', newOffset)
    },
    [itemsPerPage, data?.item_types.length, setUrlParam]
  )

  const handleItemsPerPageChange = useCallback(e => setUrlParam('show', e.value), [setUrlParam])

  const { t } = useTranslation()
  useBreadCrumbs(t('itemType'), null)

  return (
    <>
      {isLoading && <Loader />}
      <Toolbar>
        <SearchContainer>
          <SearchBar
            defaultValue={search}
            onChange={e => setUrlParam('search', e.target.value?.toLocaleLowerCase())}
            placeholder={t('searchItemTypes')!}
            type='search'
          />
        </SearchContainer>
        <Link to='/dashboard/item-types/new' className='outline-none'>
          <Button padding='10px 20px'>+ {t('addNewItemType')}</Button>
        </Link>
      </Toolbar>
      <Flex css={{ overflow: 'auto' }}>
        <DataTable>
          <TableHead>
            <Tr>
              <Th>{t('itemTypeID')}</Th>
              <Th>{t('itemTypeName')}</Th>
              <Th>{t('status')}</Th>
              <Th>{t('actions')}</Th>
            </Tr>
          </TableHead>
          <TableBody>
            {itemTypes.map((item: any) => {
              return (
                <Tr css={{ height: 60 }} key={item.id}>
                  <Td>{item.id}</Td>
                  <Td>{item.name}</Td>
                  <Td>
                    <span className={item.status === 1 ? 'item-type-active-status-text' : 'item-type-suspentend-status-text'}>
                      {item.status === 1 ? t('active').toLocaleUpperCase() : t('suspended').toLocaleUpperCase()}
                    </span>
                  </Td>
                  <Td>
                    <Link className='item-type-action-text no-padding' to={`/dashboard/item-types/${item.id}`}>
                      {t('editItemType', { defaultValue: 'Edit item type' })}
                    </Link>
                  </Td>
                </Tr>
              )
            })}
          </TableBody>
        </DataTable>
      </Flex>
      <TableFooter
        offset={offset}
        itemsPerPage={itemsPerPage}
        data={data?.item_types}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  )
}

export default ItemTypesList
