import React from 'react'
import { useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import '../../pages/userAccount/userAccount.scss'
import { batchUploadContainers, batchUploadItems, batchUploadUsers, getContainerList, getItemTypes } from '../../api/apiCalls'
import './batchUploadPopup.scss'
import downloadIcon from '../../assets/images/download_file_dark_icon.svg'
import successIcon from '../../assets/images/success_icon.svg'
import errorIcon from '../../assets/images/error_icon.svg'
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate'
import {useBasicFieldName} from "../../hooks/useBasicFieldName";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'none',
    border: 'none',
    position: 'fixed',
    zIndex: 20,
  },
  overlay: {
    background: 'rgba(174,174,174,0.5)',
    backdropFilter: 'blur(15px)',
    position: 'fixed',
    inset: 0,
    zIndex: 10,
  },
} as const

const BatchUploadPopup = (props: any) => {
  const [uploading, setUploading] = useState<boolean>(false)
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
  const [uploadAttempt, setUploadAttempt] = useState<boolean>(false)
  const [errorLogs, setErrorLogs] = useState<any>([])
  const [preparingSample, setPreparingSample] = useState(false)
  const [showAllLogs, setShowAllLogs] = useState<boolean>(false)
  const basicFieldName = useBasicFieldName();
  const reset = () => {
    setUploading(false)
    setUploadSuccess(false)
    setUploadAttempt(false)
    setErrorLogs(false)
    setPreparingSample(false)
  }

  const onBatchUploadClick = async (event: any) => {
    if (event.target.files[0]) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      const token = localStorage.getItem('accessToken')
      if (props.type === 'items') {
        setUploadAttempt(true)
        setUploading(true)
        batchUploadItems(formData, token)
          .then((response) => {
            setUploading(false)
            if (response.status === 200) {
              setUploadSuccess(true)
            }
          })
          .catch((error) => {
            console.log(error)
            setUploading(false)
            setErrorLogs(error.response.data.message)
            setUploadSuccess(false)
          })
      } else if (props.type === 'containers') {
        setUploadAttempt(true)
        setUploading(true)
        batchUploadContainers(formData, token)
          .then((response) => {
            setUploading(false)
            if (response.status === 200) {
              setUploadSuccess(true)
            }
          })
          .catch((error) => {
            console.log(error)
            setUploading(false)
            setErrorLogs(error.response.data.message)
            setUploadSuccess(false)
          })
      } else if (props.type === 'members') {
        setUploadAttempt(true)
        setUploading(true)
        batchUploadUsers(formData, token)
          .then((response) => {
            setUploading(false)
            if (response.status === 200) {
              setUploadSuccess(true)
            }
          })
          .catch((error) => {
            console.log(error)
            setUploading(false)
            setErrorLogs(error.response.data.message)
            setUploadSuccess(false)
          })
      }
    }
  }

  const onDownloadLogsClick = () => {
    const element = document.createElement('a')
    const content: any = document.getElementById('error-logs')?.innerText
    const file = new Blob([content], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = 'batch_upload_error_logs.txt'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  const handleDownload = async (type: string) => {
    setPreparingSample(true)
    const createDownLoadData = (url: any) => {
      const downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute('href', url)
      downloadAnchorNode.setAttribute('download', 'Container+Item.xlsx')
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
      setPreparingSample(false)
    }

    switch (type) {
      case 'items':
        let itemTypes: any = ''
        const fetchItemTypes = async () => {
          const token = localStorage.getItem('accessToken')
          try {
            const res = await getItemTypes(token, '')
            if (res.item_types) {
              itemTypes = res.item_types
            }
          } catch (err: any) {
            alert(err.message)
          }
        }

        let containers: any = ''
        const fetchContainersList = async () => {
          const token = localStorage.getItem('accessToken')
          try {
            const res = await getContainerList(token, '')
            if (res.containers) {
              containers = res.containers
            }
          } catch (err: any) {
            alert(err.message)
          }
        }
        await fetchItemTypes()
        await fetchContainersList()

        const url = 'https://emory-files.s3.us-west-2.amazonaws.com/static+files/Container+Item.xlsx'
        const f = await (await fetch(url)).arrayBuffer()
        const wb = await XlsxPopulate.fromDataAsync(f)
        wb.sheet(0).cell('A2').value(basicFieldName('Title', 'item')+'*')
        wb.sheet(0).cell('B2').value(basicFieldName('Description', 'item'))
        wb.sheet(0).cell('C2').value(basicFieldName('Customer Email', 'item'))
        wb.sheet(0).cell('D2').value(basicFieldName('Mark', 'item')+'*')
        wb.sheet(0).cell('E2').value(basicFieldName('Item Type', 'item')+'*')
        wb.sheet(0).cell('F2').value(basicFieldName('Arrival Date', 'item'))
        wb.sheet(0).cell('G2').value(basicFieldName('Location', 'item'))
        wb.sheet(0).cell('H2').value(basicFieldName('Container ID', 'container'))
        wb.sheet(0).cell('I2').value(basicFieldName('Title', 'container'))
        wb.sheet(0).cell('J2').value(basicFieldName('Description', 'container'))
        wb.sheet(0).cell('K2').value(basicFieldName('Source', 'container'))
        wb.sheet(0).cell('L2').value(basicFieldName('Destination', 'container'))
        wb.sheet(0).cell('M2').value(basicFieldName('Yard Location', 'container'))
        wb.sheet(0).cell('N2').value(basicFieldName('Departure Date', 'container'))
        wb.sheet(0).cell('O2').value(basicFieldName('Arrival Date', 'container'))
        if (itemTypes.length > 0) {
          wb.sheet(1)
            .cell('A2')
            .value(
              itemTypes.map((item: any) => {
                return [item.name, item.id]
              })
            )
        }
        if (containers.length > 0) {
          wb.sheet(1)
            .cell('E2')
            .value(
              containers.map((container: any) => {
                return [container.external_container_id, container.title]
              })
            )
        }
        const ff = await wb.outputAsync()
        createDownLoadData(URL.createObjectURL(ff))
        break
      case 'containers':
        const url2 = 'https://emory-files.s3.us-west-2.amazonaws.com/static+files/Container+Bulk+Upload.xlsx'
        const f2 = await (await fetch(url2)).arrayBuffer()
        const wb2 = await XlsxPopulate.fromDataAsync(f2)
        wb2.sheet(0).cell('A1').value(basicFieldName('Container ID', 'container')+'*')
        wb2.sheet(0).cell('B1').value(basicFieldName('Title', 'container')+'*')
        wb2.sheet(0).cell('C1').value(basicFieldName('Description', 'container'))
        wb2.sheet(0).cell('D1').value(basicFieldName('Source', 'container'))
        wb2.sheet(0).cell('E1').value(basicFieldName('Destination', 'container'))
        wb2.sheet(0).cell('F1').value(basicFieldName('Yard Location', 'container'))
        wb2.sheet(0).cell('G1').value(basicFieldName('Departure Date', 'container'))
        wb2.sheet(0).cell('H1').value(basicFieldName('Arrival Date', 'container'))
        const ff2 = await wb2.outputAsync()
        createDownLoadData(URL.createObjectURL(ff2))
        break
      default:
        break
    }
  }

  return (
    <div>
      <Dialog.Root>
        <Dialog.Trigger asChild>{props.children}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay style={customStyles.overlay} />
          <Dialog.Content style={customStyles.content}>
            <div className='media-slider-outer-container user-profile-outer-container'>
              <div className='media-slider-close-icon-container user-profile-close-icon'>
                <Dialog.Close asChild>
                  <p className='cross-icon cursor_pointer media-slider-cross-icon'>x</p>
                </Dialog.Close>
              </div>
              <p className='batch-upload-title'>
                BATCH UPLOAD {props.type === 'items' ? 'ITEMS' : props.type === 'containers' ? 'CONTAINERS' : 'MEMBERS'}
              </p>
              {uploadAttempt && !uploading && uploadSuccess ? (
                <div className='upload-success-container'>
                  <img src={successIcon} className='upload-status-icon upload-success-icon' />
                  <p className='upload-status-text'>Data uploaded successfully!</p>
                </div>
              ) : uploadAttempt && !uploading && !uploadSuccess ? (
                <div className='upload-error-container'>
                  <img src={errorIcon} className='upload-status-icon' />
                  <p className='upload-status-text'>
                    We faced some errors while uploading your data. Please refer to the error log below and try again.
                  </p>
                </div>
              ) : null}
              <div className='user-profile-content-outer-container batch-upload-popup-content-outer-container'>
                <div
                  className={
                    uploadSuccess
                      ? 'add-logo-field-container batch-upload-popup-btn-container cursor_pointer'
                      : 'add-logo-field-container batch-upload-popup-btn-container'
                  }
                  onClick={
                    uploadSuccess
                      ? () => {
                          props.onSuccess?.()
                          reset()
                        }
                      : undefined
                  }
                >
                  {uploadSuccess ? (
                    <Dialog.Close asChild>
                      <p className='batch-upload-popup-btn-text'>See Uploaded Items</p>
                    </Dialog.Close>
                  ) : (
                    <p className='batch-upload-popup-btn-text'>
                      {uploadAttempt && !uploadSuccess && !uploading ? 'Try Uploading again' : uploading ? 'Uploading...' : 'Upload Data'}
                    </p>
                  )}
                  {!uploadSuccess && (
                    <input
                      type='file'
                      onClick={(e: any)=> e.target.value = ''}
                      onTouchStart={(e: any)=> e.target.value = ''}
                      onChange={(e) => onBatchUploadClick(e)}
                      accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      className='logo-upload-input'
                    />
                  )}
                </div>
                <p className='upload-type-constraint-text'>Only “.xlsx” formats supported.</p>

                <div className='display_flex sample-file-download-container'>
                  <img src={downloadIcon} className='sample-file-donwload-icon' />
                  {props.type === 'users' ? (
                    <a
                      href={'https://emory-files.s3.us-west-2.amazonaws.com/static+files/User+Bulk+Upload.xlsx'}
                      download
                      className='sample-file-download-text'
                    >
                      Download Sample Sheet For Reference (<u><i><b>NEW</b></i></u>)
                    </a>
                  ) : (
                    <button
                      onClick={() => handleDownload(props.type)}
                      className={`cursor-pointer sample-file-download-text outline-none ring-0 bg-transparent border-none ${
                        preparingSample ? 'animate-pulse' : ''
                      } disabled:cursor-not-allowed`}
                      disabled={preparingSample}
                    >
                      {preparingSample ? 'Please wait! Processing...' : 'Download Sample Sheet For Reference (NEW)'}
                    </button>
                  )}
                </div>

                {!uploading && uploadAttempt && !uploadSuccess && (
                  <div className='upload-error-logs-container'>
                    <div
                      className='display_flex download-error-logs-icon download-error-logs-container cursor_pointer'
                      onClick={onDownloadLogsClick}
                    >
                      <img src={downloadIcon} className='sample-file-donwload-icon cursor_pointer' />
                      <p className='download-logs-title'>Download logs</p>
                    </div>
                    <div id='error-logs'>
                      <p className='error-logs-title'>ERROR LOG</p>
                      {errorLogs &&
                        errorLogs.length > 0 &&
                        errorLogs.map((error: any, ind: any) => {
                          return <p className='upload-single-error-text'>• {error}</p>
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  )
}

export default BatchUploadPopup
