import React, { useEffect } from 'react'
import { useState } from 'react'
import Button from '../../../components/Button/button'
import InputField from '../../../components/InputField/inputField'
import '../../loginPage/loginPage.scss'
import './superadminLoginPage.scss'
import { useNavigate } from 'react-router-dom'
import { getEmailErrorMsg, setPasswordValidation } from '../../../helpers/validations'
import { superAdminLogin } from '../../../api/apiCalls'
import Loader from '../../../components/Loader/loader'
import emoryLogo from '../../../assets/images/emory_logo.svg'
import variables from '../../../themes.module.scss'
import goBackIcon from '../../../assets/images/go_back_icon.svg'
import viewPassword from '../../../assets/images/view_password.png'
import hidePassword from '../../../assets/images/hide_password.png'
import withTitle from '../../../hoc/withTitle'

const SuperadminLoginPage = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')
  const [genericError, setGenericError] = useState<string>('')
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showPasswordStatus, setShowPasswordStatus] = useState<boolean>(false)

  const onLogInClick = async () => {
    if (email && password && !emailError && !passwordError) {
      const data = {
        email: email,
        password: password,
      }

      setShowLoader(true)
      superAdminLogin(data)
        .then((response) => {
          setShowLoader(false)
          // console.log(response)
          if (response.status === 201) {
            // setGenericError('')
            // setEmailError('')
            // setPasswordError('')
            localStorage.removeItem('loggedIn')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('loggedInUser')
            localStorage.removeItem('orgId')
            localStorage.removeItem('currentBaseTab')
            localStorage.setItem('adminLoggedIn', 'true')
            localStorage.setItem('adminAccessToken', response.data.data.token)
            localStorage.setItem('adminRefreshToken', response.data.data.refreshToken)
            navigate('/superadmin/dashboard', { replace: true })
          } else {
            if (response.data.errors.message === 'Invalid Email') {
              setEmailError('Invalid Email')
            } else if (response.data.errors.message === 'Invalid Password') {
              setPasswordError('Invalid Password')
            } else {
              setGenericError(response.data.errors.message)
            }
          }
        })
        .catch((error) => {
          setShowLoader(false)
          console.log(error)
          alert('Something went wrong, please try again later!')
        })
    }
  }

  const onEmailChange = (value: any) => {
    setGenericError('')
    setEmail(value)
    setEmailError(getEmailErrorMsg(value))
  }

  const onPasswordChange = (value: any) => {
    setGenericError('')
    if (!value) {
      setPasswordError('Please enter your password')
    } else {
      setPasswordError('')
    }
    setPassword(value)
  }

  const onPasswordShowHideClick = () => {
    setShowPasswordStatus(!showPasswordStatus)
  }

  useEffect(() => {
    localStorage.removeItem('loggedIn')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('orgId')
    localStorage.removeItem('loggedInUser')
  }, [])

  return (
    <div className='superadmin-login-page-container'>
      {showLoader && <Loader />}
      <div className='login-card-container'>
        <div className='login-page-emory-logo-container'>
          <img src={emoryLogo} className='login-page-emory-logo' />
        </div>
        <div className='login-details-outer-container'>
          <div className='login-page-input-field-container'>
            <InputField
              label='Email'
              value={email}
              placeholder='Enter Email'
              onChange={onEmailChange}
              type='email'
              error={emailError}
              className='login-inputs-style super-admin-input-field'
            />
          </div>
          <div className='login-page-input-field-container login-page-password-field-container'>
            <InputField
              label='Password'
              value={password}
              placeholder='Enter Password'
              onChange={onPasswordChange}
              type={showPasswordStatus ? 'text' : 'password'}
              error={passwordError}
              className='login-inputs-style super-admin-input-field'
            />
            <img
              src={showPasswordStatus ? viewPassword : hidePassword}
              className='view-hide-password-icon cursor_pointer'
              onClick={onPasswordShowHideClick}
            />
          </div>

          {genericError
            ? genericError.length > 0 && (
                <div className='input-field-container'>
                  <p className='error-msg-style'>{genericError}</p>
                </div>
              )
            : null}
        </div>

        <div className='login-btn-container'>
          <Button onClick={onLogInClick} disabled={emailError || passwordError || !email || !password} backgroundColor='#212e4a'>
            Sign In
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withTitle(SuperadminLoginPage, 'Emory')
