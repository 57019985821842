import { HiCheck } from 'react-icons/hi2';
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { getContainerItems, getContainerList } from '../../../api/apiCalls'
import downArrow from '../../../assets/images/arrow_down.svg'
import upArrow from '../../../assets/images/arrow_up.svg'
import AssignItemsPopup from '../../../components/AssignItemsPopup/assignItemsPopup'
import BatchUploadPopup from '../../../components/BatchUploadPopup/batchUploadPopup'
import { Button } from '../../../components/Button'
import TextButton from '../../../components/Button/button'
import QrCodeIconButton from '../../../components/Button/qrCodeIconButton'
import {
  CheckboxIndicator,
  CheckboxRoot,
  DataTable,
  ISort,
  renderSortIcon,
  TableBody,
  TableFooter,
  TableHead,
  Td,
  Th,
  toggleSortOrder,
  Toolbar,
  Tr,
  useDataTable,
} from '../../../components/dataTable/DataTable'
import Divider from '../../../components/divider/Divider'
import DownloadMultipleQrCodes from '../../../components/DownloadMultipleQrCodes/downloadMultipleQrCodes'
import FilterDropdown from '../../../components/filterDropdown/container'
import Flex from '../../../components/flex/Flex'
import Loader from '../../../components/Loader/loader'
import QrCodePopup from '../../../components/QrCodePopup/qrCodePopup'
import { SearchBar, SearchContainer } from '../../../components/searchbar/SearchBar'
import { renderBreadCrumbs, useBreadCrumbs } from '../../../contexts/breadcrumbs'
import {useBasicFieldName} from "../../../hooks/useBasicFieldName";
import {formatDate} from "../../../utils/helper";
export interface IFilterData {
  arrivalDate: Date
  departureDate: Date
  assigneeList: string[]
  yardLocationList: string[]
  inspectionStatusList: string[]
}

export interface IOptions {
  assigneeList: string[]
  yardLocationList: string[]
  inspectionStatusList: string[]
}

const TableRow = memo(({ rowData, isSelected, onSelect, to }: any) => {
  const [isExpanded, setExpanded] = useState(false)
  const navigate = useNavigate()

  const { isLoading, data } = useQuery(
    ['containersItems', rowData.id],
    () => {
      return getContainerItems(rowData.id)
    },
    {
      enabled: isExpanded,
    }
  )

  return (
    <>
      {isLoading && <Loader />}
      <Tr
        css={{ cursor: 'pointer', opacity: rowData.is_archived ? 0.6 : 1 }}
        onClick={() => {
          navigate(to)
        }}
      >
        <Td
          onClick={e => e.stopPropagation()}
          css={{
            width: 55,
            maxWidth: 55,
            borderLeft: '3px solid',
            borderColor: rowData.is_archived ? '$emory_orange' : 'transparent',
          }}
        >
          <Flex as="span" align={'center'} justify="center">
            <CheckboxRoot
              checked={isSelected}
              onCheckedChange={checked => {
                if (checked) {
                  onSelect((s: number[]) => s.concat(rowData.id))
                } else {
                  onSelect((s: number[]) => s.filter(id => id !== rowData.id))
                }
              }}
            >
              <CheckboxIndicator>
                <HiCheck />
              </CheckboxIndicator>
            </CheckboxRoot>
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.external_container_id}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.title}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.container_status}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.arrival_date ? formatDate(rowData.arrival_date) : ''}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.departure_date ? formatDate(rowData.departure_date) : ''}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.assignee_name}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.yard_location}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.updated_at ? formatDate(rowData.updated_at) : ''}
          </Flex>
        </Td>
        <Td css={{ width: 72, maxWidth: 72 }} onClick={e => e.stopPropagation()}>
          <QrCodePopup id={rowData.external_container_id} data={rowData.qr_code} type="container">
            <QrCodeIconButton />
          </QrCodePopup>
        </Td>
        <Td
          css={{
            textAlign: 'center',
            width: 64,
            maxWidth: 64,
          }}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          {!!rowData.items.length && (
            <Flex as="span" align={'center'}>
              <img
                src={isExpanded ? upArrow : downArrow}
                onClick={() => setExpanded(!isExpanded)}
                className="item-edit-icon"
                alt=""
              />
            </Flex>
          )}
        </Td>
      </Tr>
      {isExpanded && <ItemsTable items={data} />}
    </>
  )
})

const TableHeader = memo(({ checked, onCheckAll, sort, setSort }: any) => {

  const basicFieldName = useBasicFieldName();
  const sortBy = (column: string) => {
    if (sort.by === column) {
      setSort(toggleSortOrder(sort))
    } else {
      setSort({
        by: column,
        order: 'asc',
      })
    }
  }

  const { t } = useTranslation()

  return (
    <TableHead>
      <Tr>
        <Th css={{ width: 60, maxWidth: 60 }}>
          <Flex as="span" align="center" justify="center">
            <CheckboxRoot checked={checked} onCheckedChange={onCheckAll}>
              <CheckboxIndicator>
                <HiCheck />
              </CheckboxIndicator>
            </CheckboxRoot>
          </Flex>
        </Th>
        <Th>{basicFieldName('Container ID', 'container')}</Th>
        <Th>{basicFieldName('Title', 'container')}</Th>
        <Th>{t('inspectionStatus')}</Th>
        <Th css={{ cursor: 'pointer' }} onClick={() => sortBy('arrival_date')}>
          <Flex align={'center'} css={{ gap: 8 }}>
            {basicFieldName('Arrival Date', 'container')}
            {renderSortIcon(sort, 'arrival_date')}
          </Flex>
        </Th>
        <Th css={{ cursor: 'pointer' }} onClick={() => sortBy('departure_date')}>
          <Flex align={'center'} css={{ gap: 8 }}>
            {basicFieldName('Departure Date', 'container')}
            {renderSortIcon(sort, 'departure_date')}
          </Flex>
        </Th>
        <Th>{t('assignee', { defaultValue: 'Assignee' })}</Th>
        <Th>{basicFieldName('Yard Location', 'container')}</Th>
        <Th css={{ cursor: 'pointer' }} onClick={() => sortBy('updated_at')}>
          <Flex align={'center'} css={{ gap: 8 }}>
            {t('updatedAt', { defaultValue: 'Updated At' })}
            {renderSortIcon(sort, 'updated_at')}
          </Flex>
        </Th>
      </Tr>
    </TableHead>
  )
})

const ItemsTable = ({ items }: any) => {
  return (
    <Tr className="container-items-outer-container">
      <Td />
      <Td colSpan={100} css={{ p: 0 }} className="container-items-inner-container">
        <div className="display_flex container-items-headers-container">
          <p className="container-items-header">Mark No.</p>
          <p className="container-items-header">Title</p>
          <p className="container-items-header">Item Type</p>
          <p className="container-items-header">Assigned To</p>
          <p className="container-items-header">Item Status</p>
          <p className="container-items-header">Stage</p>
        </div>
        <div className="container-items-wrapper-container">
          {items &&
            items.length > 0 &&
            items.map((item: any) => {
              return (
                <Flex
                  as={Link}
                  key={item.id}
                  css={{
                    px: '1.5rem',
                    '&:hover': {
                      opacity: 0.5,
                    },
                  }}
                  to={`/dashboard/items/${item.id}`}
                >
                  <p className="container-items-single-item-text wrap-container-item-text">{item.mark}</p>
                  <p className="container-items-single-item-text wrap-container-item-text">{item.title}</p>
                  <p className="container-items-single-item-text wrap-container-item-text">{item.item_type}</p>
                  <p className="container-items-single-item-text wrap-container-item-text">{item.assignee_name}</p>
                  <p className="container-items-single-item-text wrap-container-item-text">{item.item_status}</p>
                  <p className="container-items-single-item-text">{item.stage_name}</p>
                </Flex>
              )
            })}
        </div>
      </Td>
    </Tr>
  )
}

function ContainersList() {
  const { t } = useTranslation()
  const breadcrumbs = useBreadCrumbs(t('containers'))
  const {
    search,
    filters,
    offset,
    showArchived,
    itemsPerPage,
    sortBy,
    order,
    setUrlParam,
    getFilterData,
    selected,
    setSelected,
  } = useDataTable({
    cacheKey: 'container',
    initialFilterData: {
      arrivalDate: '',
      departureDate: '',
      assigneeList: [],
      yardLocationList: [],
      inspectionStatusList: [],
    },
  })

  const { isLoading, data, refetch } = useQuery(
    ['containersList', search.toLowerCase(), filters, showArchived],
    () => {
      return getContainerList('', search.toLowerCase(), filters, !!showArchived)
    },
    {
      enabled: localStorage.getItem('loggedIn') === 'true',
    }
  )

  const containers = useMemo(() => {
    if (sortBy) {
      return (
        data?.containers
          ?.slice()
          .sort((a: any, b: any) => (order === 'asc' ? -1 : 1) * (a[sortBy] < b[sortBy] ? 1 : -1))
          .slice(offset, offset + itemsPerPage) || []
      )
    }

    return data?.containers?.slice(offset, offset + itemsPerPage) || []
  }, [data, offset, itemsPerPage, sortBy, order])

  const onClearAllFilters = () => setUrlParam('filter')
  const onApplyFilter = (modifiedFilters: IFilterData) => {
    setUrlParam('filter', JSON.stringify(modifiedFilters))
  }

  const handlePageChange = useCallback(
    (selectedItem: { selected: number }) => {
      setSelected([])
      const newOffset = (selectedItem.selected * itemsPerPage) % data?.containers?.length
      setUrlParam('offset', newOffset)
    },
    [itemsPerPage, data?.containers?.length, setUrlParam]
  )

  const handleItemsPerPageChange = useCallback(e => setUrlParam('show', e.value), [setUrlParam])

  const handleCheckAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelected(containers.map((c: any) => c.id))
      } else {
        setSelected([])
      }
    },
    [containers]
  )

  return (
    <>
      {isLoading && <Loader />}
      {renderBreadCrumbs(breadcrumbs)}
      <Divider />
      <Toolbar>
        <SearchContainer>
          <SearchBar
            defaultValue={search}
            onChange={e => setUrlParam('search', e.target.value?.toLocaleLowerCase())}
            placeholder={t('searchContainers')!}
            type="search"
          />
        </SearchContainer>
        <FilterDropdown
          filterData={getFilterData as unknown as IFilterData}
          applyFilter={onApplyFilter}
          onClearAll={onClearAllFilters}
        />
        <Link to="new" className="outline-none">
          <Button>+ {t('addNewContainer', { defaultValue: 'Add New Container' })}</Button>
        </Link>
        <Button
          onClick={() => setUrlParam('show_archived', showArchived ? undefined : 1)}
          intent={showArchived ? 'secondary' : 'primary'}
        >
          {showArchived ? t('hideArchived') : t('showArchived')}
        </Button>
        <BatchUploadPopup onSuccess={refetch} type="containers">
          <Button intent="primary">{t('batchUploadContainers')}</Button>
        </BatchUploadPopup>
        <AssignItemsPopup idList={selected} assignContainer onAssign={refetch}>
          {!!selected.length && (
            <TextButton
              btnStyle="textOnlyBtn"
              textColor="var(--colors-text)"
              width="auto"
              textDecoration="underline"
              padding="0.5rem 0"
            >
              {t('assignContainersInBulk')}
            </TextButton>
          )}
        </AssignItemsPopup>
        <DownloadMultipleQrCodes
          type="container"
          containersList={containers.filter((item: any) => selected.includes(item.id))}
        >
          {!!selected.length && (
            <TextButton
              btnStyle="textOnlyBtn"
              textColor="var(--colors-text)"
              width="auto"
              textDecoration="underline"
              padding="0.5rem 0"
            >
              {t('downloadQRCodes')}
            </TextButton>
          )}
        </DownloadMultipleQrCodes>
      </Toolbar>
      <Flex css={{ overflow: 'auto' }}>
        <DataTable>
          <TableHeader
            checked={selected.length === containers.length}
            onCheckAll={handleCheckAll}
            sort={{
              by: sortBy,
              order: order,
            }}
            setSort={(sort: ISort) => {
              setUrlParam('order', sort.order)
              setUrlParam('sortBy', sort.by)
            }}
          />
          <TableBody>
            {containers.length ? (
              containers.map((container: any, index: number) => {
                const isSelected = selected.some(id => container.id === id)
                return (
                  <TableRow
                    key={container.id}
                    index={index}
                    rowData={container}
                    isSelected={isSelected}
                    onSelect={setSelected}
                    to={`/dashboard/containers/${container.id}`}
                  />
                )
              })
            ) : (
              <Tr>
                <Td colSpan={100} css={{ color: '$calm_platinum', fontSize: 18, fontFamily: 500 }}>
                  <Flex align={'center'} justify="center">
                    {t('noDataAvailable')}!
                  </Flex>
                </Td>
              </Tr>
            )}
          </TableBody>
        </DataTable>
      </Flex>
      <TableFooter
        offset={offset}
        itemsPerPage={itemsPerPage}
        data={data?.containers}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  )
}

export default ContainersList
