import React from 'react'
import { useState } from 'react'
import InputField from '../../../components/InputField/inputField'
import '../../organisationSettings/tabs/companyDetails/companyDetails.scss'
import Button from '../../../components/Button/button'
import { addNewContainer } from '../../../api/apiCalls'
import arrowRight from '../../../assets/images/arrow_right_icon.svg'
import 'react-calendar/dist/Calendar.css'
import CustomMessagePopup from '../../../components/CustomMessagePopup/customMessagePopup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../components/InputField/inputField.scss'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../../components/Loader/loader'
import {t} from "i18next";
import {useBasicFieldName} from "../../../hooks/useBasicFieldName";

const AddNewContainer = () => {
  const [loading, showLoader] = useState(false)
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [locationStatus, setLocationStatus] = useState<string>('')
  const [arrival, setArrival] = useState<any>()
  const [departure, setDeparture] = useState<any>()
  const [destination, setDestination] = useState<string>('')
  const [source, setSource] = useState<string>('')
  const [externalContainerId, setExternalContainerId] = useState<string>('')
  const [arrivalValue, setArrivalValue] = useState<any>(null)
  const [departureValue, setDepartureValue] = useState<any>(null)
  const [showContainerCreatedPopup, setShowContainerCreatedPopup] = useState<boolean>(false)
  const [containerId, setContainerId] = useState<string>('')
  const basicFieldName = useBasicFieldName();

  const onArrivalDateChange = (val: any) => {
    val.setHours(12) // this sets the time to noon of the local datetime(zone), reduces confusion in UTC
    setArrivalValue(val)
    setArrival(val.getTime())
  }

  const onDepartureDateChange = (val: any) => {
    val.setHours(12) // this sets the time to noon of the local datetime(zone), reduces confusion in UTC
    setDepartureValue(val)
    setDeparture(val.getTime())
  }

  const navigate = useNavigate()
  const onSaveChangesClick = () => {
    if (!title || !externalContainerId) {
      if (!title && !externalContainerId) {
        alert(`Please enter ${basicFieldName('Title', 'container')} and ${basicFieldName('Container ID', 'container')}`)
      } else if (!title) {
        alert(`Please enter ${basicFieldName('Title', 'container')}`)
      } else if (!externalContainerId) {
        alert(`Please enter ${basicFieldName('Container ID', 'container')}`)
      }
    } else {
      const token = localStorage.getItem('accessToken')
      const data = {
        title: title,
        external_container_id: externalContainerId,
        description: description,
        arrival_date: arrival,
        departure_date: departure,
        destination: destination,
        yard_location: locationStatus,
        source: source,
      }
      showLoader(true)
      addNewContainer(data, token)
        .then((response) => {
          showLoader(false)
          if (response.status === 201) {
            setContainerId(response.data.id)
            navigate('/dashboard/containers/' + response.data.id)
          } else {
            alert(t('genericError'))
          }
        })
        .catch((error) => {
          showLoader(false)
          console.log(error)
          alert(t('genericError'))
        })
    }
  }

  const onCustomPopupDismiss = () => {
    setShowContainerCreatedPopup(false)
    navigate('/dashboard/containers/' + containerId)
  }

  return (
    <>
      {loading && <Loader />}
      {showContainerCreatedPopup && (
        <CustomMessagePopup dismissHandler={onCustomPopupDismiss} message={t('containerCreatedSuccessfully') +': '+ containerId}/>
      )}
      <div className='sub-tab-content-container'>
        <div className='display_flex add-member-header-container'>
          <Link className='selected-tab-page-title cursor_pointer' to='..'>
            Containers
          </Link>
          <img src={arrowRight} className='arrow-right-icon' alt={'seperator'} />
          <p className='sub-tab-selected-title'>Create New Container</p>
        </div>
        <p className='tabs-page-title-bottom-border' />
        <div className='display_flex flex-row-input-fields-container row-input-fields-container-margin-top'>
          <div className='row-left-field-container width_50'>
            <InputField
              label={`${basicFieldName('Title', 'container')}*`}
              value={title}
              placeholder={`${basicFieldName('Title', 'container')}`}
              onChange={(val: string) => setTitle(val)}
              type='text'
              error={false}
            />
          </div>

          <div className='row-right-field-container width_50'>
            <InputField
              label={`${basicFieldName('Description', 'container')}`}
              value={description}
              placeholder={`${basicFieldName('Description', 'container')}`}
              onChange={(val: any) => setDescription(val)}
              type='text'
              error={false}
            />
          </div>
        </div>

        <div className='display_flex flex-row-input-fields-container row-input-fields-container-margin-top'>
          <div className='row-left-field-container width_50'>
            <InputField
              label={`${basicFieldName('Container ID', 'container')}*`}
              value={externalContainerId}
              placeholder={`${basicFieldName('Container ID', 'container')}`}
              onChange={(val: any) => setExternalContainerId(val)}
              type='text'
              error={false}
            />
          </div>

          <div className='row-right-field-container width_50'>
            <InputField
              label={`${basicFieldName('Source', 'container')}`}
              value={source}
              placeholder={`${basicFieldName('Source', 'container')}`}
              onChange={(val: string) => setSource(val)}
              type='text'
              error={false}
            />
          </div>
        </div>

        <div className='display_flex flex-row-input-fields-container row-input-fields-container-margin-top'>
          <div className='row-left-field-container width_50'>
            <InputField
              label={`${basicFieldName('Destination', 'container')}`}
              value={destination}
              placeholder={`${basicFieldName('Destination', 'container')}`}
              onChange={(val: string) => setDestination(val)}
              type='text'
              error={false}
            />
          </div>

          <div className='row-right-field-container width_50'>
            <InputField
              label={`${basicFieldName('Yard Location', 'container')}`}
              value={locationStatus}
              placeholder={`${basicFieldName('Yard Location', 'container')}`}
              onChange={(val: string) => setLocationStatus(val)}
              type='text'
              error={false}
            />
          </div>
        </div>

        <div className='display_flex flex-row-input-fields-container row-input-fields-container-margin-top'>
          <div className='row-left-field-container width_50'>
            <p className='input-field-label'>{`${basicFieldName('Departure Date', 'container')}`}</p>
            <div style={{ display: 'grid' }}>
              <DatePicker
                showYearDropdown
                showMonthDropdown
                dropdownMode='select'
                className='input-field'
                dateFormat='dd MMM yyyy'
                selected={departureValue}
                onChange={(date: Date) => onDepartureDateChange(date)}
                placeholderText={`${basicFieldName('Departure Date', 'container')}`}
              />
            </div>
          </div>
          <div className='row-right-field-container width_50'>
            <p className='input-field-label'>{`${basicFieldName('Arrival Date', 'container')}`}</p>
            <div style={{ display: 'grid' }}>
              <DatePicker
                dateFormat='dd MMM yyyy'
                showYearDropdown
                showMonthDropdown
                dropdownMode='select'
                className='input-field'
                selected={arrivalValue}
                onChange={(date: Date) => onArrivalDateChange(date)}
                placeholderText={`${basicFieldName('Arrival Date', 'container')}`}
              />
            </div>
          </div>
        </div>

        <div className='company-details-bottom-btn-container'>
          <div className='display_flex company-details-bottom-btn-inner-container'>
            <Link to='../'>
              <Button backgroundColor='#EEEEEE' textColor='#333333' padding='0.75rem 2rem' fontSize='12px' height='fit-content' width='auto'>
                {t('cancel')}
              </Button>
            </Link>
            <Button padding='0.75rem 2rem' fontSize='12px' height='fit-content' width='auto' leftMargin='1.25rem' onClick={onSaveChangesClick}>
              {t('createContainer')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewContainer
