import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import BreadCrumbsProvider from '../contexts/breadcrumbs'
import { ContainerListProvider } from '../contexts/containerListContext'
import { AssigneeListProvider } from '../contexts/assigneeListContext'
import { StageListProvider } from '../contexts/stageListContext'
import CheckReports from '../pages/checkReports/checkReports'
import AddNewContainer from '../pages/containers/addNewContainer/addNewContainer'
import Containers from '../pages/containers/containers'
import ContainersList from '../pages/containers/containersList/containersList'
import EditContainerDetails from '../pages/containers/editContainerDetails/editContainerDetails'
import Dashboard from '../pages/dashboard/dashboard'
import AddNewItem from '../pages/items/addNewItem/addNewItem'
import EditItem from '../pages/items/EditItem/editItem'
import ItemList from '../pages/items/itemList/ItemsList'
import Items from '../pages/items/items'
import LoginPage from '../pages/loginPage/loginPage'
import ResetPassword from '../pages/loginPage/resetPassword/resetPassword'
import AddNewMember from '../pages/Members/addNewMember/addNewMember'
import Members from '../pages/Members/members'
import MembersList from '../pages/Members/membersList/MembersListV2'
import Markings from '../pages/organisationSettings/markings/Markings'
import OrganisationSettings from '../pages/organisationSettings/organisationSettings'
import AccessFlags from '../pages/organisationSettings/tabs/accessFlags/accessFlags'
import CompanyDetails from '../pages/organisationSettings/tabs/companyDetails/companyDetails'
import EditContainerSettings from '../pages/organisationSettings/tabs/container/EditContainer'
import EditItemSettings from '../pages/organisationSettings/tabs/item/EditItem'
import EditItemType from '../pages/organisationSettings/tabs/itemType/EditItemType'
import ItemTypesList from '../pages/organisationSettings/tabs/itemType/ItemTypesList'
import PrimaryContactDetails from '../pages/organisationSettings/tabs/primaryContactDetails/primaryContactDetails'
import Stage from '../pages/organisationSettings/tabs/stage/stage'
import PageNotFound from '../pages/pageNotFound/pageNotFound'
import Stats from '../pages/stats/StatsV2'
import SuperadminDashboard from '../pages/superadmin/superadminDashboard/superadminDashboard'
import SuperadminLoginPage from '../pages/superadmin/superadminLoginPage/superadminLoginPage'
import { ProtectedAdminRoute } from './protectedAdminRoute'
import { ProtectedRoute } from './protectedRoute'
import { ProtectedUserRoute } from './protectedUserRoute'
import { SuperadminProtectedRoute } from './superadminProtectedRoutes'
import SignupPage from "../pages/signupPage/signupPage";
import TermsTrialPage from "../pages/termsTrial/termsTrial";
import Tracking from "../pages/tracking/tracking";

const RoutesDashboard = () => {
  return (
    <Router>
      <div className='app-container'>
        <Routes>
          <Route path='/' element={<Navigate replace to='/login' />} />
          <Route
            path='/superadmin'
            element={
              <ProtectedAdminRoute>
                <SuperadminLoginPage />
              </ProtectedAdminRoute>
            }
          />
          <Route path='/login' element={ <LoginPage /> } ></Route>
          <Route path='/signup' element={<SignupPage />}></Route>
          <Route path='/terms-trial' element={<TermsTrialPage />}></Route>
          <Route path='/reset-password/*' element={<ResetPassword />}></Route>
          <Route path='/cr/:identifier' element={<CheckReports />}></Route>
          <Route path='/tracking' element={<Tracking />}></Route>
          <Route
            path='/superadmin/dashboard'
            element={
              <SuperadminProtectedRoute>
                <SuperadminDashboard />
              </SuperadminProtectedRoute>
            }
          />
          <Route
            path='/dashboard'
            element={
              <ProtectedRoute>
                <BreadCrumbsProvider>
                  <Dashboard />
                </BreadCrumbsProvider>
              </ProtectedRoute>
            }
          >
            <Route index element={<Stats />} />
            <Route
              path='org'
              element={
                <StageListProvider>
                  <OrganisationSettings />
                </StageListProvider>
              }
            >
              <Route index element={<Navigate replace to='company-details' />} />
              <Route path='company-details' element={<CompanyDetails />} />
              <Route path='primary-contact-details' element={<PrimaryContactDetails />} />
              <Route path='markings' element={<Markings />} />
              <Route path='item-types' element={<ItemTypesList />} />
              <Route path='access-flags' element={<AccessFlags />} />
              <Route path='stage' element={<Stage />} />
              <Route path='container' element={<EditContainerSettings />} />
              <Route path='item' element={<EditItemSettings />} />
            </Route>
            <Route path='item-types/new' element={<EditItemType />} />
            <Route path='item-types/:id' element={<EditItemType />} />
            <Route path='members' element={<Members />}>
              <Route index element={<MembersList />} />
              <Route path='new' element={<AddNewMember />} />
              <Route path=':id' element={<AddNewMember />} />
            </Route>
            <Route path='containers' element={
              <AssigneeListProvider>
                <Containers />
              </AssigneeListProvider>
            }>
              <Route index element={<ContainersList />} />
              <Route path='new' element={<AddNewContainer />} />
              <Route path=':id' element={<EditContainerDetails />} />
            </Route>
            <Route path='items' element={<Items />}>
              <Route index element={
                <AssigneeListProvider>
                  <ItemList />
                </AssigneeListProvider>
               }/>
              <Route
                path='new'
                element={
                  <StageListProvider>
                    <ContainerListProvider>
                      <AddNewItem />
                    </ContainerListProvider>
                  </StageListProvider>
                }
              />
              <Route
                path=':id'
                element={
                  <StageListProvider>
                    <ContainerListProvider>
                      <AssigneeListProvider>
                        <EditItem />
                      </AssigneeListProvider>
                    </ContainerListProvider>
                  </StageListProvider>
                }
              />
            </Route>
            <Route path='*' element={<PageNotFound loggedIn />} />
          </Route>
          <Route
            path='*'
            element={
              localStorage.getItem('adminLoggedIn') ? (
                <SuperadminProtectedRoute pageNotFound>
                  <SuperadminDashboard pageNotFound={true} />
                </SuperadminProtectedRoute>
              ) : (
                <ProtectedRoute pageNotFound={true}>
                  <Dashboard pageNotFound={true} />
                </ProtectedRoute>
              )
            }
          />
        </Routes>
      </div>
    </Router>
  )
}

export default RoutesDashboard
