import i18n, {t} from "i18next";

const tokenAndRedirectHelper = (code: number) => {
  if (code === 401) {
    alert('Error: User not logged in')
    localStorage.clear();
    window.location.href = '/login';
  }
}

const slugify = (text: string) => {
  if (text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }
  return "";
};

const langToLocale = {
  'en': 'en-US',
  'de': 'de-DE',
  'es': 'es-ES'
}

const formatDate = (val: any, includeTime: boolean = false) => {
  try {
    if (!val) {
      return ''
    }
    // input date can be a number, a number as a string, or date as string
    let tempDate: Date
    if ((typeof val === 'string') && val.search('[^0-9]') != -1) {
      // string containing chars other than just numbers
      tempDate = new Date(val)
    } else {
      tempDate = new Date(parseInt(val))
    }
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const locale: string = langToLocale[i18n.language as keyof typeof langToLocale]
    const dateOptions: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: tz,
    }
    if (includeTime) {
      dateOptions.hour = 'numeric';
      dateOptions.minute = 'numeric';
      dateOptions.second = 'numeric';
      dateOptions.hour12 = false;
    }
    const dateFormatter = new Intl.DateTimeFormat(locale, dateOptions)
    return dateFormatter.format(tempDate)
  } catch (e) {
    console.log(e);
    return ''
  }
}

export { tokenAndRedirectHelper, slugify, formatDate };
