import React, {useContext, useEffect} from 'react'
import { useState } from 'react'
import Button from '../../components/Button/button'
import InputField from '../../components/InputField/inputField'
import './loginPage.scss'
import { useNavigate } from 'react-router-dom'
import { getEmailErrorMsg, setPasswordValidation } from '../../helpers/validations'
import { sendResetLink, signInUser } from '../../api/apiCalls'
import Loader from '../../components/Loader/loader'
import emoryLogo from '../../assets/images/emory_logo.svg'
import variables from '../../themes.module.scss'
import goBackIcon from '../../assets/images/go_back_icon.svg'
import viewPassword from '../../assets/images/view_password.png'
import hidePassword from '../../assets/images/hide_password.png'
import withTitle from '../../hoc/withTitle'
import i18n from "i18next";

const LoginPage = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')
  const [genericError, setGenericError] = useState<string>('')
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [forgotPasswordFlow, setForgotPasswordFlow] = useState<boolean>(false)
  const [forgotPassEmail, setForgotPassEmail] = useState<string>('')
  const [forgotPassEmailError, setForgotPassEmailError] = useState<string>('')
  const [resetPasswordState, setResetPasswordState] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordError, setNewPasswordError] = useState<string>('')
  const [showPasswordStatus, setShowPasswordStatus] = useState<boolean>(false)
  const [forgotPassEmailSent, setForgotPassEmailSent] = useState<boolean>(false)

  const onLogInClick = async () => {
    if (email && password && !emailError && !passwordError) {
      const data = {
        email: email,
        password: password,
      }

      setShowLoader(true)
      signInUser(data)
        .then((response) => {
          setShowLoader(false)
          // console.log(response)
          if (response.status === 201) {
            setGenericError('')
            setEmailError('')
            setPasswordError('')
            localStorage.removeItem('adminLoggedIn')
            localStorage.removeItem('adminAccessToken')
            localStorage.removeItem('adminRefreshToken')
            localStorage.removeItem('currentBaseTab')
            localStorage.setItem('loggedIn', 'true')
            localStorage.setItem('accessToken', response.data.data.token)
            localStorage.setItem('refreshToken', response.data.data.refreshToken)
            // localStorage.setItem("orgId", response.data.data.organization_id)
            // localStorage.setItem("loggedInUser", response.data.data.user_name)
            navigate('/dashboard', { replace: true })
          } else {
            if (response.data.errors.message === 'Invalid Email') {
              setEmailError('Invalid Email')
            } else if (response.data.errors.message === 'Invalid Password') {
              setPasswordError('Invalid Password')
            } else {
              setGenericError(response.data.errors.message)
            }
          }
        })
        .catch((error) => {
          setShowLoader(false)
          console.log(error)
          alert(error.response.data?.errors?.message || i18n.t('genericError'))
        })
    }
  }

  const onForgotPasswordClick = () => {
    setForgotPasswordFlow(true)
  }

  const onEmailChange = (value: any) => {
    setGenericError('')
    setEmail(value)
    setEmailError(getEmailErrorMsg(value))
  }

  const onPasswordChange = (value: any) => {
    setGenericError('')
    if (!value) {
      setPasswordError('Please enter your password')
    } else {
      setPasswordError('')
    }
    setPassword(value)
  }

  const onForgotPassEmailChange = (value: any) => {
    setForgotPassEmail(value)
    setForgotPassEmailError(getEmailErrorMsg(value))
  }

  const onGoBackClick = () => {
    setForgotPasswordFlow(false)
    setForgotPassEmail('')
    setForgotPassEmailError('')
    setForgotPassEmailSent(false)
  }

  const onSendLinkClick = () => {
    const data = {
      email: forgotPassEmail,
    }
    const token = localStorage.getItem('accessToken')
    setShowLoader(true)
    sendResetLink(data, token)
      .then((response) => {
        setShowLoader(false)
        console.log(response)
        if (response.status !== 200) {
          setForgotPassEmailError(response.data.errors.message)
        } else {
          // setForgotPasswordFlow(false)
          setForgotPassEmail('')
          setForgotPassEmailError('')
          setForgotPassEmailSent(true)
        }
      })
      .catch((error) => {
        setShowLoader(false)
        alert('Something went wrong, please try again later...')
        console.log(error)
      })
  }

  const onEnterNewPasswordHandler = (value: any) => {
    setNewPassword(value)
    setNewPasswordError(setPasswordValidation(value))
  }

  const onSetNewpasswordClick = () => {
    setResetPasswordState(false)
    setForgotPasswordFlow(false)
    setForgotPassEmail('')
    setForgotPassEmailError('')
    setNewPasswordError('')
    setNewPassword('')
  }

  const onPasswordShowHideClick = () => {
    setShowPasswordStatus(!showPasswordStatus)
  }

  useEffect(() => {
    // check if token exists, redirect to dashboard
    if(localStorage.getItem('loggedIn') === 'true') {
      navigate('/dashboard', { replace: true })
    }
  }, [])

  return (
    <div className='login-page-container'>
      {showLoader && <Loader />}
      <div className='login-card-container'>
        <div className='login-page-emory-logo-container'>
          <img src={emoryLogo} className='login-page-emory-logo' />
        </div>

        {resetPasswordState ? (
          <div className='login-details-outer-container'>
            <div className='login-page-input-field-container'>
              <InputField
                label='New Password'
                value={newPassword}
                placeholder='Enter new password'
                onChange={onEnterNewPasswordHandler}
                type='email'
                error={newPasswordError}
                className='login-inputs-style'
              />
            </div>
          </div>
        ) : forgotPasswordFlow ? (
          !forgotPassEmailSent ? (
            <div className='login-details-outer-container'>
              <div className='login-page-input-field-container'>
                <InputField
                  label='Registered Email'
                  value={forgotPassEmail}
                  placeholder='Please enter your registered email'
                  onChange={onForgotPassEmailChange}
                  type='email'
                  error={forgotPassEmailError}
                  className='login-inputs-style'
                />
              </div>
            </div>
          ) : (
            <div className='reset-link-sent-msg-container'>
              <p className='reset-link-sent-msg'>
                A recovery email will be sent to your account if it exists in our records.
              </p>
            </div>
          )
        ) : (
          <div className='login-details-outer-container'>
            <div className='login-page-input-field-container'>
              <InputField
                label='Email'
                value={email}
                placeholder='Enter Email'
                onChange={onEmailChange}
                type='email'
                error={emailError}
                className='login-inputs-style'
              />
            </div>
            <div className='login-page-input-field-container login-page-password-field-container'>
              <InputField
                label='Password'
                value={password}
                placeholder='Enter Password'
                onChange={onPasswordChange}
                type={showPasswordStatus ? 'text' : 'password'}
                error={passwordError}
                className='login-inputs-style'
              />
              <img
                src={showPasswordStatus ? viewPassword : hidePassword}
                className='view-hide-password-icon cursor_pointer'
                onClick={onPasswordShowHideClick}
              />
            </div>

            {genericError
              ? genericError.length > 0 && (
                  <div className='input-field-container'>
                    <p className='error-msg-style'>{genericError}</p>
                  </div>
                )
              : null}
          </div>
        )}

        {/* {!forgotPassEmailSent} */}
        <div className={forgotPasswordFlow && forgotPassEmailSent ? 'hidden' : 'login-btn-container'}>
          <Button
            onClick={resetPasswordState ? onSetNewpasswordClick : forgotPasswordFlow ? onSendLinkClick : onLogInClick}
            disabled={
              resetPasswordState
                ? !newPassword
                : forgotPasswordFlow
                ? !forgotPassEmail || forgotPassEmailError
                : emailError || passwordError || !email || !password
            }
          >
            {resetPasswordState ? 'Reset Password' : forgotPasswordFlow ? 'Send Password Reset Link' : 'Sign In'}
          </Button>
        </div>

        {!resetPasswordState && (
          // forgotPasswordFlow ?
          <div
            className={
              forgotPasswordFlow
                ? 'display_flex forgot-password-btn-container forgot-password-flow-go-back-btn-container'
                : 'forgot-password-btn-container'
            }
          >
            {forgotPasswordFlow && <img src={goBackIcon} onClick={onGoBackClick} className='forgot-password-go-back-icon' />}
            <Button
              onClick={forgotPasswordFlow ? onGoBackClick : onForgotPasswordClick}
              btnStyle='textOnlyBtn'
              textColor={variables.strongGraphite}
              width='fit-content'
              leftMargin={forgotPasswordFlow && '0.5rem'}
            >
              {forgotPasswordFlow ? 'Go Back' : 'Forgot Password?'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default withTitle(LoginPage, 'Emory - Login')
