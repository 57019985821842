import React from 'react'
import { Navigate } from 'react-router-dom'
import { OrgDetailsProvider } from '../contexts/orgDetailsContext'
import { UserDetailsProvider } from '../contexts/userDetailsContext'
import { UserPermissionsProvider } from '../contexts/userPermissionsContext'
import PageNotFound from '../pages/pageNotFound/pageNotFound'

export const ProtectedRoute = (props: any) => {
  if (localStorage.getItem('loggedIn') !== 'true') {
    if (props.pageNotFound) {
      return <PageNotFound />
    }
    return <Navigate to='/login' replace />
  }

  return (
   <UserPermissionsProvider>
      <UserDetailsProvider>
        <OrgDetailsProvider>
          {props.children}
        </OrgDetailsProvider>
      </UserDetailsProvider>
    </UserPermissionsProvider>
  )
}
