import React, { createContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getUserDetails } from '../api/apiCalls'
import { setDefaultLocale } from 'react-datepicker'

export const UserDetailsContext = createContext<any>({})

const localeMap = {
  English: 'en',
  German: 'de',
  Spanish: 'es',
}

const queryKey = 'userDetails'

const userDetailsInfo = async () => {
  const token = localStorage.getItem('accessToken')
  const res = await getUserDetails(token)
  return res
}

export const UserDetailsProvider: React.FC<any> = props => {
  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery(queryKey, userDetailsInfo, {
    enabled: localStorage.getItem('loggedIn') === 'true',
    refetchOnWindowFocus: false,
  })

  const { i18n } = useTranslation()

  useEffect(() => {
    if (!data) return

    localStorage.setItem('i18nextLng', data.preferred_language)
    i18n.changeLanguage(localeMap[data.preferred_language as keyof typeof localeMap])
    setDefaultLocale(i18n.language)
  }, [data])

  return (
    <UserDetailsContext.Provider value={{ isLoading, isSuccess, isError, data, error, refetch, queryKey }}>
      {props.children}
    </UserDetailsContext.Provider>
  )
}
