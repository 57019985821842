import { t } from 'i18next'
import QueryString from 'qs'
import React, { FormEvent, useContext } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { getDataTypes, updateItem } from '../../../../api/apiCalls'
import Button from '../../../../components/Button/button'
import { DataTable, TableBody, TableHead, Th, Tr } from '../../../../components/dataTable/DataTable'
import Flex from '../../../../components/flex/Flex'
import Loader from '../../../../components/Loader/loader'
import Text from '../../../../components/typography/Text'
import { useBreadCrumbs } from '../../../../contexts/breadcrumbs'
import { UserDetailsContext } from '../../../../contexts/userDetailsContext'
import withTitle from '../../../../hoc/withTitle'
import { styled } from '../../../../stitches.config'
import { localeMap, TableRow } from '../container/EditContainer'

const Title = styled(Text, {
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '21px',
  marginTop: '2rem',
  flex: 1,
  mb: 16,
})

function EditItem() {
  const navigate = useNavigate()

  const { data: userDetails, isLoading, queryKey } = useContext(UserDetailsContext)

  const supported_locales = JSON.parse(userDetails?.supported_locales || '[]')
  useBreadCrumbs(t('item'), null)

  const item = userDetails?.item

  const queryClient = useQueryClient()

  const updateMutation = useMutation(updateItem, {
    onSuccess: (response, variables) => {
      if (response.data.message === 'success') {
        showSuccessToast()

        queryClient.setQueryData(queryKey, (data: any) => {
          return {
            ...data,
            item: {
              ...data?.item,
              ...variables,
              basic_fields: variables?.basic_fields?.map((s: any) => ({
                ...s,
                field_name_i18n: JSON.stringify(s.field_name_i18n || {}),
              })),
            },
          }
        })
      } else {
        showFailureToast()
      }
    },
    onError: () => showFailureToast(),
  })

  const showSuccessToast = () => {
    toast.success('Successfully completed operation!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error('An unknown error occurred. Please retry in sometime.', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const { data: fieldTypesData } = useQuery('dataTypesList', getDataTypes)

  const fieldTypeOptions = fieldTypesData?.data_types.map((item: any) => ({ value: item.id, label: item.name }))

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    const target = event.target as HTMLFormElement
    const formValues = QueryString.parse(QueryString.stringify(Object.fromEntries(new FormData(target).entries())), {
      allowDots: true,
    })
    updateMutation.mutate(formValues)
  }

  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
      {isLoading && <Loader />}
      <Flex as="form" onSubmit={handleSubmit} direction="column" css={{ py: 32, px: 54 }}>
        <Flex align="baseline">
          <Title>
            {t('itemFieldsForBasicDetails', { defaultValue: 'Item Fields for basic details' }).toLocaleUpperCase()}
          </Title>
        </Flex>
        <DataTable>
          <TableHead>
            <Tr>
              <Th>{t('defaultText')}*</Th>
              {supported_locales.map((locale: keyof typeof localeMap) => (
                <Th key={locale}>{localeMap[locale]}</Th>
              ))}
              <Th>{t('fieldType')}*</Th>
              <Th></Th>
            </Tr>
          </TableHead>
          <TableBody>
            {(item?.basic_fields || []).map((item: any, index: number) => {
              return (
                <TableRow
                  supportedLocales={supported_locales}
                  isActiveDefaultValue={item.is_active || 1}
                  item={item}
                  fieldTypeOptions={fieldTypeOptions}
                  prefix="basic_fields"
                  index={index}
                  key={`index-${index}-${item.id}`}
                  hideDelete
                />
              )
            })}
          </TableBody>
        </DataTable>
        <div className="company-details-bottom-btn-container z-10">
          <div className="display_flex company-details-bottom-btn-inner-container">
            <Button
              type="button"
              backgroundColor="#EEEEEE"
              textColor="var(--colors-text)"
              padding="0.75rem 2rem"
              fontSize="12px"
              height="fit-content"
              width="auto"
              onClick={() => navigate('/dashboard/org/item-types')}
              disabled={updateMutation.isLoading}
            >
              {t('discard')}
            </Button>

            <Button
              type="submit"
              padding="0.75rem 2rem"
              fontSize="12px"
              height="fit-content"
              width="auto"
              leftMargin="1.25rem"
              disabled={updateMutation.isLoading}
            >
              {t('saveChanges')}
            </Button>
          </div>
        </div>
      </Flex>
    </div>
  )
}

export default withTitle(EditItem, `${t('item')} | ${t('appName')}`)
